var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"parcelasList",attrs:{"id":"parcelasList","button-size":"sm","no-close-on-backdrop":"","ok-only":"","no-stacking":_vm.stacking,"ok-title":"Ok","size":"lg","no-enforce-focus":true}},[_c('div',{attrs:{"slot":"modal-title"},slot:"modal-title"},[_c('h4',[_vm._v(_vm._s(_vm.tipoParcelamento === 'pgfn' ? ((_vm.parcelamento.identificacao) + " - " + (_vm.parcelamento.razaoSocial)) : 'Parcelas - ' + _vm.parcelamento.razaoSocial))]),_c('fieldset',{staticClass:"font-weight-bold text-muted small"},[_vm._v(" Nesta tela estão as últimas parcelas lidas pelo Monitor, você pode mandar as guias por email ou baixar. ")])]),(_vm.showParc)?_c('h5',{},[_vm._v(" "+_vm._s(_vm.tipoParcelamento === 'relp' ? 'Remanescentes' : 'Histórico')+" "),_c('span',{staticStyle:{"float":"right"}},[_vm._v(_vm._s(_vm.parcelamento.totalParcelas))])]):_vm._e(),_c('b-row',[_c('b-col',[_c('tabela-repeticao',{staticClass:"tabela-parcelas",attrs:{"somente-visualizacao":false,"customizar-botoes":true,"colunas":_vm.colunas},scopedSlots:_vm._u([{key:"visualizacao",fn:function(ref){
var ref_slotScope = ref.slotScope;
var key = ref_slotScope.key;
var valor = ref_slotScope.valor;
return [(key === 'status')?_c('b-badge',{attrs:{"variant":valor.indexOf('atraso') > 0 ? 'light-danger' : (valor.indexOf('paga') > 0 ? 'light-success' : 'light-primary')}},[_vm._v(" "+_vm._s(valor)+" ")]):(key === 'competencia')?_c('span',{staticClass:"ml-50",style:(_vm.validaPeriodo(valor))},[_vm._v(" "+_vm._s(valor)+" ")]):_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(valor)+" ")])]}},{key:"botoes-customizados",fn:function(ref){
var item = ref.slotScope.item;
return [(item.status.indexOf('paga') < 0 && _vm.parcelamento.situacao != 'Encerrado por rescisão')?_c('div',[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-sm btn-none px-50 py-0",attrs:{"title":"Gerar Parcela"},on:{"click":function($event){$event.stopPropagation();_vm.visualizarGuia(item.id, item.status.indexOf('atraso') > 0)}}},[_c('font-awesome-icon',{staticClass:"text-primary",attrs:{"icon":"fa-solid fa-barcode"}})],1),_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-none rounded-pill m-0 icone-acao p-0",attrs:{"title":"Observação","variant":"none"},on:{"click":function($event){return _vm.$emit('openObservacoes', item)}}},[_c('font-awesome-icon',{class:{ 'text-warning': item.temObservacoesNaoLidas, 'text-primary': !item.temObservacoesNaoLidas },attrs:{"icon":"fa-regular fa-message"}})],1),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-sm btn-none px-50 py-0",attrs:{"title":"Enviar por e-mail"},on:{"click":function($event){_vm.openEnvioEmail(item.id, item.status.indexOf('atraso') > 0)}}},[_c('feather-icon',{staticClass:"text-primary",attrs:{"icon":"SendIcon","size":"20"}})],1)],1):_vm._e()]}}]),model:{value:(_vm.parcelas),callback:function ($$v) {_vm.parcelas=$$v},expression:"parcelas"}})],1)],1),(_vm.showParcEntrada)?_c('h5',{staticClass:"mt-2"},[_vm._v(" Entrada "),_c('span',{staticStyle:{"float":"right"}},[_vm._v(_vm._s(_vm.parcelamento.totalParcelasEntrada))])]):_vm._e(),_c('b-row',[_c('b-col',[_c('tabela-repeticao',{staticClass:"tabela-parcelas",attrs:{"somente-visualizacao":false,"customizar-botoes":true,"colunas":_vm.colunas},scopedSlots:_vm._u([{key:"visualizacao",fn:function(ref){
var ref_slotScope = ref.slotScope;
var key = ref_slotScope.key;
var valor = ref_slotScope.valor;
return [(key === 'status')?_c('b-badge',{attrs:{"variant":valor.indexOf('atraso') > 0 ? 'light-danger' : (valor.indexOf('paga') > 0 ? 'light-success' : 'light-primary')}},[_vm._v(" "+_vm._s(valor)+" ")]):_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(valor)+" ")])]}},{key:"botoes-customizados",fn:function(ref){
var item = ref.slotScope.item;
return [(item.status.indexOf('paga') < 0)?_c('div',[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-sm btn-none px-50 py-0",attrs:{"title":"Gerar Parcela"},on:{"click":function($event){$event.stopPropagation();_vm.visualizarGuia(item.id, item.status.indexOf('atraso') > 0)}}},[_c('font-awesome-icon',{staticClass:"text-primary",attrs:{"icon":"fa-solid fa-barcode"}})],1),_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-none rounded-pill m-0 icone-acao p-0",attrs:{"title":"Observação","variant":"none"},on:{"click":function($event){return _vm.$emit('openObservacoes', item)}}},[_c('font-awesome-icon',{class:{ 'text-warning': item.temObservacoesNaoLidas, 'text-primary': !item.temObservacoesNaoLidas },attrs:{"icon":"fa-regular fa-message"}})],1),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-sm btn-none px-50 py-0",attrs:{"title":"Enviar por e-mail"},on:{"click":function($event){_vm.openEnvioEmail(item.id, item.status.indexOf('atraso') > 0)}}},[_c('feather-icon',{staticClass:"text-primary",attrs:{"icon":"SendIcon","size":"20"}})],1)],1):_vm._e()]}}]),model:{value:(_vm.parcelasEntrada),callback:function ($$v) {_vm.parcelasEntrada=$$v},expression:"parcelasEntrada"}})],1)],1)],1),_c('loading',{attrs:{"show-cancel-button":false,"is-loading":_vm.isLoading,"descricao":'Buscando'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }