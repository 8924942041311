<template>
  <cadastro-base
    :salvar="salvar"
    :cancelar="cancelar"
  >
    <template slot="conteudo">
      <div slot="modal-title">
        <h5>Datas de atualizações das rotinas</h5>
        <fieldset class="font-weight-bold text-muted">
          Nos informe quais dias do mês deseja que o sistema faça a atualização das rotinas automáticas.
        </fieldset>
        <hr
          class="mx-0 mt-0 mb-2"
          style="border-top: 1px solid #a3a4a66b !important;"
        >
      </div>
      <div>
        <b-row>
          <b-col cols="12">
            <h5>CERTIDÕES
              <feather-icon
                v-b-tooltip.hover
                icon="InfoIcon"
                size="12"
                :title="mensagemPadraoCnd"
              />
            </h5>
          </b-col>
        </b-row>
        <b-row style="margin-top: 1%;">
          <b-col cols="auto">
            <b-form-group
              label="RFB/PGFN"
              label-for="diaRfbPgfnInput"
            >
              <validation-provider
                #default="{ errors }"
                rules="diaValido"
                name="RFB/PGFN"
              >
                <b-row>
                  <b-col cols="auto" class="pr-0">
                    <b-input
                      id="diaRfbPgfnInput"
                      v-model="form.cndRfbPgfn.dia"
                      type="number"
                      placeholder="Dia da consulta"
                      :disabled="form.cndRfbPgfn.isBloqueado"
                      @blur="formatarNumero('diaRfbPgfn')"
                    />
                  </b-col>
                  <b-col style="margin-top: 10px;" class="pl-0 pr-0">
                    <historico-parametro
                      :ref="`modalHistoricoParametro`"
                      tipo-parametro="RFB/PGFN"
                      tipo-grupo="Certidão"
                    />
                  </b-col>
                </b-row>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <fieldset v-if="form.cndRfbPgfn.isBloqueado" class="text-danger" style="margin-top: 5px">Bloqueado até {{ this.form.cndRfbPgfn.dataBloqueio }}</fieldset>
            </b-form-group>
          </b-col>

          <!-- <b-col cols="auto">
            <b-form-group
              label="FGTS"
              label-for="diaFgtsInput"
            >
              <validation-provider
                #default="{ errors }"
                rules="diaValido"
                name="FGTS"
              >
                <b-input
                  id="diaFgtsInput"
                  v-model="form.diaFgts"
                  type="number"
                  placeholder="Dia da consulta"
                  :disabled="bloqueio"
                  @blur="formatarNumero('diaFgts')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col cols="auto">
            <b-form-group
              label="MTE"
              label-for="diaMteInput"
            >
              <validation-provider
                #default="{ errors }"
                rules="diaValido"
                name="MTE"
              >
                <b-row>
                  <b-col cols="auto" class="pr-0">
                    <b-input
                      id="diaMteInput"
                      v-model="form.cndMte.dia"
                      type="number"
                      placeholder="Dia da consulta"
                      :disabled="form.cndMte.isBloqueado"
                      @blur="formatarNumero('diaMte')"
                    />
                  </b-col>
                  <b-col style="margin-top: 10px;" class="pl-0 pr-0">
                    <historico-parametro
                      :ref="`modalHistoricoParametro`"
                      tipo-parametro="MTE"
                      tipo-grupo="Certidão"
                    />
                  </b-col>
                </b-row>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <fieldset v-if="form.cndMte.isBloqueado" class="text-danger" style="margin-top: 5px">Bloqueado até {{ this.form.cndMte.dataBloqueio }}</fieldset>
            </b-form-group>
          </b-col>
          <b-col cols="auto">
            <b-form-group
              label="Trabalhista"
              label-for="diaTrabalhistaInput"
            >
              <validation-provider
                #default="{ errors }"
                rules="diaValido"
                name="Trabalhista"
              >
                <b-row>
                  <b-col cols="auto" class="pr-0">
                    <b-input
                      id="diaTrabalhistaInput"
                      v-model="form.cndTrabalhista.dia"
                      type="number"
                      placeholder="Dia da consulta"
                      :disabled="form.cndTrabalhista.isBloqueado"
                      @blur="formatarNumero('diaTrabalhista')"
                    />
                  </b-col>
                  <b-col style="margin-top: 10px;" class="pl-0 pr-0">
                    <historico-parametro
                      :ref="`modalHistoricoParametro`"
                      tipo-parametro="Trabalhista"
                      tipo-grupo="Certidão"
                    />
                  </b-col>
                </b-row>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <fieldset v-if="form.cndTrabalhista.isBloqueado" class="text-danger" style="margin-top: 5px">Bloqueado até {{ this.form.cndTrabalhista.dataBloqueio }}</fieldset>
            </b-form-group>
          </b-col>
          <b-col cols="auto">
            <b-form-group
              label="Estadual"
              label-for="diaEstadualInput"
            >
              <validation-provider
                #default="{ errors }"
                rules="diaValido"
                name="Estadual"
              >
                <b-row>
                  <b-col cols="auto" class="pr-0">
                    <b-input
                      id="diaEstadualInput"
                      v-model="form.cndEstadual.dia"
                      type="number"
                      placeholder="Dia da consulta"
                      :disabled="form.cndEstadual.isBloqueado"
                      @blur="formatarNumero('diaEstadual')"
                    />
                  </b-col>
                  <b-col style="margin-top: 10px;" class="pl-0 pr-0">
                    <historico-parametro
                      :ref="`modalHistoricoParametro`"
                      tipo-parametro="Estadual"
                      tipo-grupo="Certidão"
                    />
                  </b-col>
                </b-row>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <fieldset v-if="form.cndEstadual.isBloqueado" class="text-danger" style="margin-top: 5px">Bloqueado até {{ this.form.cndEstadual.dataBloqueio }}</fieldset>
            </b-form-group>
          </b-col>
          <b-col cols="auto">
            <b-form-group
              label="Municipal"
              label-for="diaMunicipalInput"
            >
              <validation-provider
                #default="{ errors }"
                rules="diaValido"
                name="Municipal"
              >
                <b-row>
                  <b-col cols="auto" class="pr-0">
                    <b-input
                      id="diaMunicipalInput"
                      v-model="form.cndMunicipal.dia"
                      type="number"
                      placeholder="Dia da consulta"
                      :disabled="form.cndMunicipal.isBloqueado"
                      @blur="formatarNumero('diaMunicipal')"
                    />
                  </b-col>
                  <b-col style="margin-top: 10px;" class="pl-0 pr-0">
                    <historico-parametro
                      :ref="`modalHistoricoParametro`"
                      tipo-parametro="Municipal"
                      tipo-grupo="Certidão"
                    />
                  </b-col>
                </b-row>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <fieldset v-if="form.cndMunicipal.isBloqueado" class="text-danger" style="margin-top: 5px">Bloqueado até {{ this.form.cndMunicipal.dataBloqueio }}</fieldset>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <fieldset
        style="margin-top: 10px;"
        class="font-weight-bold text-muted"
      >
        FGTS será atualizada conforme a data do seu vencimento.
      </fieldset>
      <div style="margin-top: 5px; margin-block-end: 50px;">
        <fieldset
          class="font-weight-bold text-muted"
        >
          Ao salvar o cadastro, essa tela ficará com o campo alterado bloqueado para alterações temporariamente. As parametrizações só podem ser refeitas após 30 dias!
        </fieldset>
        <fieldset
          v-if="form.cndRfbPgfn.isBloqueado || form.cndMte.isBloqueado || form.cndTrabalhista.isBloqueado ||
          form.cndEstadual.isBloqueado || form.cndMunicipal.isBloqueado"
          class="font-weight-bold"
          style="color: #ea5455;"
        >
          Os campos que se encontram bloqueados devem permanecer assim por 30 dias. Após esse período será permitido efetuar novas alterações.
        </fieldset>
      </div>

      <div>
        <b-row>
          <b-col cols="12">
            <h5>FEDERAIS e-CAC
              <feather-icon
                v-b-tooltip.hover
                icon="InfoIcon"
                size="12"
                :title="mensagemPadraoECac"
              />
            </h5>
          </b-col>
        </b-row>
        <b-row style="margin-top: 1%;">
          <b-col cols="auto">
            <b-form-group
              label="Situação Fiscal"
              label-for="situacaoFiscalInput"
            >
              <validation-provider
                #default="{ errors }"
                rules="diaValido"
                name="Situação Fiscal"
              >
                <b-row>
                  <b-col cols="auto" class="pr-0">
                    <b-input
                      id="situacaoFiscalInput"
                      v-model="form.ecacSituacaoFiscal.dia"
                      type="number"
                      placeholder="Dia da atualização"
                      :disabled="form.ecacSituacaoFiscal.isBloqueado"
                      @blur="formatarNumero('diaSituacaoFiscal')"
                    />
                  </b-col>
                  <b-col style="margin-top: 10px;" class="pl-0 pr-0">
                    <historico-parametro
                      :ref="`modalHistoricoParametro`"
                      tipo-parametro="Situação Fiscal"
                      tipo-grupo="Federal"
                    />
                  </b-col>
                </b-row>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <fieldset v-if="form.ecacSituacaoFiscal.isBloqueado" class="text-danger" style="margin-top: 5px">Bloqueado até {{ this.form.ecacSituacaoFiscal.dataBloqueio }}</fieldset>
            </b-form-group>
          </b-col>
          <b-col cols="auto">
            <b-form-group
              label="DCTFWeb"
              label-for="diaDctfwebInput"
            >
              <validation-provider
                #default="{ errors }"
                rules="diaValido"
                name="DCTFWeb"
              >
                <b-row>
                  <b-col cols="auto" class="pr-0">
                    <b-input
                      id="diaDctfwebInput"
                      v-model="form.ecacDctfWeb.dia"
                      type="number"
                      placeholder="Dia da atualização"
                      :disabled="form.ecacDctfWeb.isBloqueado"
                      @blur="formatarNumero('diaDctfweb')"
                    />
                  </b-col>
                  <b-col style="margin-top: 10px;" class="pl-0 pr-0">
                    <historico-parametro
                      :ref="`modalHistoricoParametro`"
                      tipo-parametro="DCTFWeb"
                      tipo-grupo="Federal"
                    />
                  </b-col>
                </b-row>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <fieldset v-if="form.ecacDctfWeb.isBloqueado" class="text-danger" style="margin-top: 5px">Bloqueado até {{ this.form.ecacDctfWeb.dataBloqueio }}</fieldset>
            </b-form-group>
          </b-col>
          <b-col cols="auto">
            <b-form-group
              label="DAS"
              label-for="diaDasInput"
            >
              <validation-provider
                #default="{ errors }"
                rules="diaValido"
                name="DAS"
              >
                <b-row>
                  <b-col cols="auto" class="pr-0">
                    <b-input
                      id="diaDasInput"
                      v-model="form.ecacDas.dia"
                      type="number"
                      placeholder="Dia da atualização"
                      :disabled="form.ecacDas.isBloqueado"
                      @blur="formatarNumero('diaDas')"
                    />
                  </b-col>
                  <b-col style="margin-top: 10px;" class="pl-0 pr-0">
                    <historico-parametro
                      :ref="`modalHistoricoParametro`"
                      tipo-parametro="DAS"
                      tipo-grupo="Federal"
                    />
                  </b-col>
                </b-row>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <fieldset v-if="form.ecacDas.isBloqueado" class="text-danger" style="margin-top: 5px">Bloqueado até {{ this.form.ecacDas.dataBloqueio }}</fieldset>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <fieldset
        style="margin-top: 10px;"
        class="font-weight-bold text-muted"
      >
        O sistema efetuará a atualização automática uma vez ao mês, na data definida acima.
      </fieldset>
      <div style="margin-top: 5px; margin-block-end: 50px;">
        <fieldset
          class="font-weight-bold text-muted"
        >
          Após o sistema efetuar a atualização da rotina o campo ficará bloqueado temporariamente para alterações até o dia 01º do mês seguinte. A DCTFWeb e o DAS são atualizados apenas para as empresas que possuem suas configurações marcadas no cadastro da empresa!
        </fieldset>
      </div>
    </template>
  </cadastro-base>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { diaValido } from '@pilar/plugins/p-validation/p-validation'
import api from './shared/services/api'

export default {
  name: 'ParametroSistema',
  components: {
    ValidationProvider,
    CadastroBase: () => import('@pilar/components/cadastro-base/CadastroBase.vue'),
    HistoricoParametro: () => import('./shared/components/HistoricoParametro.vue'),
  },
  data() {
    return {
      form: {},
      diaValido,
      mensagemPadraoCnd: 'Caso nenhuma data seja informada o sistema considera dia 15 para execução das rotinas.',
      mensagemPadraoECac: 'As atualizações automáticas acontecem uma vez ao mês, conforme a parametrização do sistema.',
    }
  },
  created() {
    this.carregar()
  },
  methods: {
    formatarNumero(campo) {
      if (this.form[campo] && +this.form[campo] < 10) {
        this.form[campo] = `0${+this.form[campo]}`
      }
    },
    adicionarZero(numero) {
      return numero < 10 ? `0${numero}` : `${!numero ? '' : numero}`
    },
    carregar() {
      api.get().then(payload => {
        this.form = payload.data
        this.form.cndRfbPgfn.dia = this.adicionarZero(this.form.cndRfbPgfn.dia)
        this.form.cndMte.dia = this.adicionarZero(this.form.cndMte.dia)
        this.form.cndTrabalhista.dia = this.adicionarZero(this.form.cndTrabalhista.dia)
        this.form.cndEstadual.dia = this.adicionarZero(this.form.cndEstadual.dia)
        this.form.cndMunicipal.dia = this.adicionarZero(this.form.cndMunicipal.dia)
        this.form.ecacSituacaoFiscal.dia = this.adicionarZero(this.form.ecacSituacaoFiscal.dia)
        this.form.ecacDctfWeb.dia = this.adicionarZero(this.form.ecacDctfWeb.dia)
        this.form.ecacDas.dia = this.adicionarZero(this.form.ecacDas.dia)
      }).catch(err => {
        throw err
      })
    },
    cancelar() {
      this.$router.push({ name: 'dashboard-analitico' })
    },
    salvar() {
      if (this.verificarData(this.form.cndRfbPgfn.dia) || this.verificarData(this.form.cndMte.dia) || this.verificarData(this.form.cndTrabalhista.dia)
            || this.verificarData(this.form.cndEstadual.dia) || this.verificarData(this.form.cndMunicipal.dia) || this.verificarData(this.form.ecacSituacaoFiscal.dia)
            || this.verificarData(this.form.ecacDctfWeb.dia) || this.verificarData(this.form.ecacDas.dia)) {
        this.$erro('Data selecionada não pode ser igual a data atual.', 'danger')
        return null
      }

      return new Promise((resolve, reject) => {
        api.salvar(this.form)
          .then(payload => {
            this.form = payload.data
            this.carregar()
            const acao = this.form.dataBloqueio !== undefined ? 'alterado' : 'cadastrado'
            resolve(`Parâmetros do sistema ${acao} com sucesso`)
          })
          .catch(error => {
            reject(error.response.data.message)
          })
        return this.form
      })
    },
    verificarData(data) {
      const dataAtual = new Date()
      return dataAtual.getDate().toString() === data
    },
  },
}
</script>
