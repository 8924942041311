<template>
  <b-row>
    <b-col>
      <tabela-repeticao
        v-model="value"
        :somente-visualizacao="false"
        :customizar-botoes="true"
        :colunas="colunas"
        class="tabela-pendencias"
      >
        <template #visualizacao="{slotScope: {key, valor}}">
          <b-badge
            v-if="key === 'status'"
            :variant="valor.indexOf('atraso') > 0 ? 'light-danger' : (valor.indexOf('paga') > 0 ? 'light-success' : 'light-primary')"
          >
            {{ valor }}
          </b-badge>
          <div
            v-else
            class="ml-50"
          >
            {{ valor }}
          </div>
        </template>
        <template #botoes-customizados="{slotScope: {item}}">
          <button
            v-if="item.status && item.status.indexOf('paga') < 0"
            v-b-tooltip.hover
            title="Gerar Parcela"
            class="btn btn-sm btn-none px-50 py-0"
            @click.stop="visualizarGuia(item.id, item.status.indexOf('atraso') > 0)"
          >
            <font-awesome-icon
              icon="fa-solid fa-barcode"
              class="text-primary"
            />
          </button>

          <slot
            name="acao-observacao"
            :props="item"
          />

          <button
            v-b-tooltip.hover
            title="Enviar por e-mail"
            class="btn btn-sm btn-none px-50 py-0"
            @click="openEnvioEmail(item.id)"
          >
            <feather-icon
              icon="SendIcon"
              size="20"
              class="text-primary"
            />
          </button>
        </template>
      </tabela-repeticao>
    </b-col>
  </b-row>

</template>

<script>
import colunas from './useColunas'

export default {
  name: 'VisualizacaoParcelas',
  components: {
    TabelaRepeticao: () => import('@pilar/components/tabela-repeticao/TabelaRepeticao.vue'),
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    grupoTributo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      colunas,
    }
  },
  methods: {
    openEnvioEmail(id) {
      this.$emit('envioEmail', id)
    },
    visualizarGuia(id) {
      this.$emit('mostrarGuia', id)
    },
  },
}
</script>
