export default [{
  key: 'dataAlteradoEm', label: 'Data',
},
{
  key: 'data', label: 'De / Para',
},
{
  key: 'usuario', label: 'Usuário',
},
]
