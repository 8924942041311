<template class="coluna">
  <div class="acoes-grid">
    <b-btn
      v-b-tooltip.hover
      :title="getTextByStatus( info.row.situacao)"
      variant="none"
      class="btn rounded-pill icone-acao p-0"
      style="margin-right:-1rem; margin-left: 0.5rem;"
      @click="verificar(info.row)"
    >
      <font-awesome-icon
        icon="fa-solid fa-lock"
        :class="getColorByStatus(info.row.situacao)"
      />
    </b-btn>
    <b-btn
      v-b-tooltip.hover
      title="Editar"
      style="margin-right:1rem"
      variant="none"
      class="btn btn-none rounded-pill icone-acao"
      @click="editar(info.row)"
    >
      <feather-icon
        icon="Edit2Icon"
        class="text-success"
        size="20"
      />
    </b-btn>
    <b-btn
      v-b-tooltip.hover
      title="Remover"
      style="margin-left:-1rem;"
      variant="none"
      class="btn btn-none rounded-pill icone-acao"
      @click="remover([info.row])"
    >
      <feather-icon
        icon="TrashIcon"
        class="text-danger"
        size="20"
      />
    </b-btn>
    <b-btn
      v-b-tooltip.hover
      title="Trocar certificado"
      style="margin-left:0.5rem;"
      variant="none"
      class="btn btn-none rounded-pill icone-acao"
      @click="trocar(info.row)"
    >
      <font-awesome-icon
        :icon="faWrench"
        style="width: 20px; height: 20px;"
      />
    </b-btn>
    <empresas-vinculadas ref="empresas-vinculadas" />
  </div>
</template>

  <style lang="scss">
  .coluna {
    .acoes-grid {
    display: inline-block;
  }
  .icone-acao {
    padding: 0.3rem;
  }
  }
  </style>

<script>

import {
  faWrench,
} from '@fortawesome/free-solid-svg-icons'

export default {
  components: {
    EmpresasVinculadas: () => import('@/app/certificado-digital/shared/components/vinculo-empresas/components/empresas-vinculadas/ModalEmpresasVinculadas.vue'),
  },

  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      faWrench,
      active: false,
    }
  },
  setup(props, context) {
    const verificar = info => {
      context.emit('verificar', info)
    }
    const editar = info => {
      context.emit('editar', info)
    }

    const remover = info => {
      context.emit('remover', info)
    }

    const trocar = info => {
      context.emit('trocar', info)
    }
    return {
      verificar,
      editar,
      remover,
      trocar,
    }
  },
  methods: {
    show() {
      this.active = !this.active
    },
    getColorByStatus(situacao) {
      if (!situacao) {
        return 'btn btn-gray text-gray'
      }
      if (situacao === 'ComEmpresaVinculada') {
        return 'btn text-success'
      }
      if (situacao === 'CertificadoAVencer' || situacao === 'CpfDiferente' || situacao === 'VerificacaoDuasEtapas') {
        return 'btn text-warning'
      }
      if (situacao === 'SemEmpresaVinculada') {
        return 'btn text-primary'
      }
      if (situacao === 'CertificadoVencido') {
        return 'btn text-danger'
      }
      if (situacao === 'CertificadoRevogado' || situacao === 'AutorizacaoEcac' || situacao === 'CadastroGov' || situacao === 'PendenteDeAprovacao') {
        return 'btn btn-purple text-purple'
      }
      return ''
    },
    getTextByStatus(situacao) {
      if (!situacao) {
        return 'Não possui certificado'
      }
      if (situacao === 'ComEmpresaVinculada') {
        return 'Visualizar empresas vinculadas'
      }
      if (situacao === 'CadastroDeEmpresas') {
        return 'Importar empresas vinculadas ao certificado'
      }
      if (situacao === 'CertificadoAVencer') {
        return 'Certificado próximo do vencimento'
      }
      if (situacao === 'SemEmpresaVinculada') {
        return 'Não há empresas vinculadas'
      }
      if (situacao === 'CertificadoVencido') {
        return 'Certificado vencido'
      }
      if (situacao === 'AutorizacaoEcac') {
        return 'Autorização e-CAC'
      }
      if (situacao === 'CadastroGov') {
        return 'Certificado não esta cadastrado no Gov'
      }
      if (situacao === 'PendenteDeAprovacao') {
        return 'Pendente de aprovação'
      }
      if (situacao === 'CertificadoRevogado') {
        return 'Certificado digital revogado'
      }
      if (situacao === 'VerificacaoDuasEtapas') {
        return 'Verificação em duas etapas'
      }
      if (situacao === 'CpfDiferente') {
        return 'CPF no certificado digital é diferente do CPF na Receita Federal'
      }
      return ''
    },
  },

}
</script>
