<template>
  <b-modal
    id="modalCertificadoVinculado"
    ref="modalCertificadoVinculado"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    header-class="pb-0"
    ok-only
    ok-title="Ok"
    button-size="md"
    size="xl"
    @hidden="onModalHidden"
    @ok="fecharModal()"
  >
    <template #modal-title>
      <h3>{{ value.razaoSocial }}</h3>
    </template>
    <b-col>
      <b-row>
        <h5>
          Certificados cadastrados na plataforma&nbsp;
          <feather-icon
            v-b-tooltip.hover
            title="Nesta tela é possível alterar o certificado da empresa ou atualizar certificados vencidos."
            icon="AlertCircleIcon"
            class="text-primary"
          />
        </h5>
      </b-row>
    </b-col>
    <b-row>
      <b-col cols="12">
        <hr
          class="mx-0 mt-0 mb-2"
          style="border-top: 1px solid #a3a4a66b !important;"
        >
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="auto">
        <h3>Certificado vinculado:</h3>
      </b-col>
      <b-col cols="auto">
        <certificado-select
          v-model="value.configuracao.certificadoDigital"
          style="width: 45vw !important;"
          @change="handleChange"
        />
      </b-col>
      <div
        style="width: 90px; display: flex; justify-content: flex-start;"
      >
        <b-btn
          id="botao-vinculo"
          v-b-tooltip.hover
          :title="getTextByStatus( value.certificadoVinculado && value.certificadoVinculado.situacao)"
          class="btn rounded-pill icone-acao p-0"
          variant="none"
          style="padding-left: 10px;"
          @click="openModalEmpresasVinculadas(value.configuracao.certificadoDigital)"
        >
          <font-awesome-icon
            icon="fa-solid fa-lock"
            class="rounded-pill icone-acao"
            :class="getColorByStatus(value.certificadoVinculado && value.certificadoVinculado.situacao)"
          />
        </b-btn>
        <b-btn
          v-b-tooltip.hover
          title="Editar"
          class="btn btn-none icone-acao"
          variant="none"
          style="padding-left: 10px;"
          @click="openModalCertificadoVinculado(value.configuracao.certificadoDigital)"
        >
          <feather-icon
            icon="Edit2Icon"
            class="text-success"
            size="20"
          />
        </b-btn>
      </div>
    </b-row>
    <br>
    <b-col cols="15">
      <b-form-group
        style="margin-bottom:0.5rem"
      >
        <busca-input
          v-model="buscaDigitada"
          :placeholder="'Buscar por palavra chave e Vencimento'"
          @input="buscar(false)"
        />
      </b-form-group>
    </b-col>
    <br>
    <vue-good-table
      ref="certificadosList"
      mode="remote"
      :max-height="`${maxHeight}px`"
      class="certificados-grid"
      :columns="gridInfo.colunas"
      :total-rows="gridInfo.totalCertificados"
      :rows="gridInfo.certificadosFiltrados"
      :is-loading.sync="isCarregando"
      :sort-options="{ enabled: true }"
      @on-sort-change="onOrdenacaoAlterada"
    >
      <template #emptystate>
        <p class="text-center m-0">
          Nenhum registro encontrado.
        </p>
      </template>
      <template #table-row="{ column, row }">
        <div
          v-if="column.field === 'nome'"
          style="margin-top: 12px !important;"
        >
          {{ formatar(row.nome) }}
        </div>
        <div
          v-if="column.field === 'porProcuracao'"
          style="margin-top: 12px !important;"
        >
          {{ row.porProcuracao }}
        </div>
        <div
          v-if="column.field === 'vencimento'"
          style="margin-top: 12px !important;"
        >
          {{ row.vencimento }}
        </div>
        <coluna-acoes
          v-if="column.field === 'acoes'"
          :info="{ column, row }"
          style="display: flex; justify-content: space-between; align-items: center;"
          @verificar="openModalEmpresasVinculadas(row)"
          @editar="openModalCertificadoVinculado(row)"
          @remover="remover(row)"
          @trocar="trocar(row)"
        />
      </template>
    </vue-good-table>
    <div class="d-flex align-items-center mb-0 mt-1">
      <span class="text-nowrap"> Total de {{ gridInfo.totalCertificados }} itens </span>
    </div>
    <empresas-vinculadas
      ref="empresas-vinculadas"
      @fechou="fechouModal()"
    />
    <editar-empresas-vinculadas
      ref="editar-empresas-vinculadas"
      @buscar="buscar()"
      @carregarCertificados="carregarCertificados()"
      @fechou="fechouModal()"
    />
  </b-modal>
</template>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#modalCertificadoVinculado {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: center !important;
  .text-purple {
    color: #64249c;

    &:hover {
      color: #64249c;
    }
  }
  .acoes-grid {
      display: inline-block !important;
      margin-left: 0px !important;
      margin-top: 0px !important;
      margin-right: 0px !important;
      margin-bottom: 0px !important;
    }
  .text-gray {
    color: gray;

    &:hover {
      color: gray;
    }
  }
  .icone-acao {
      padding: 0.3rem;
    }
  .certificados-grid {
    ::-webkit-scrollbar {
      width: 12px;
    }

    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(122, 122, 122, 0.4);
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 15px $primary;
    }
  }

    td {
    padding: 0 !important;
    line-height: 1 !important;
    div {
      margin-left: 10px;
    }
  }

    .search-items {
      color: #5d5b5b;
      padding-left: calc(0.05rem + 0.5rem + 1rem + 0.2rem);
      padding-right: calc(0.1rem + 0.1rem + 1rem + 0.2rem);
    &:focus {
      border-color: rgba(24, 28, 33, 0.1);
    }
  }

  .fonte-menor {
    font-size: 0.94rem;
    }

    .search-group {
      position: relative;

      & .icon {
        position: absolute;
        padding: 0.05rem;
      left: 0.5rem;
      top: calc(
        50% - 0.5em
      );
      color: #7c7a7a;
    }
    & .icon-delete {
      position: absolute;
      padding: 0.05rem;
      right: 0.5rem;
      top: calc(50% - 0.7em);
      cursor: pointer;
      color: #7c7a7a;
    }
  }

  .search-group label.search-placeholder {
    top: calc(50% - 0.6rem);
    padding-left: 1.5rem;
    text-align: top;
    font-weight: 500;
    color: rgba(124, 122, 122, 0.4117647058823529);
  }
}
.trocar {
  margin: 0 5px; /* Espaçamento horizontal entre os botões */
}
</style>

<script>

import apiEmpresa from '@/app/empresas/shared/services/api'
import Vue from 'vue'
import apiCertificado from '@/app/certificado-digital/shared/services/api'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    ColunaAcoes: () => import('./coluna-acoes/ColunaAcoes.vue'),
    EmpresasVinculadas: () => import('@/app/certificado-digital/shared/components/vinculo-empresas/components/empresas-vinculadas/ModalEmpresasVinculadas.vue'),
    VueGoodTable,
    EditarEmpresasVinculadas: () => import('@app/certificado-digital/shared/components/vinculo-empresas/components/editar-empresas-vinculadas/ModalEditarEmpresasVinculadas.vue'),
    CertificadoSelect: () => import('@/app/shared/components/certificado-select/CertificadoSelect.vue'),
    BuscaInput: () => import('@pilar/components/busca-input/BuscaInput.vue'),
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      originalConfig: { ...this.value.configuracao },
      idsRemovidos: [],
      fechar: true,
      gridInfo: {
        certificados: [],
        totalCertificados: 0,
        certificadosFiltrados: [],
        colunas: [
          {
            field: 'nome', label: 'Razão Social', sortable: true, width: '250px',
          },
          {
            field: 'porProcuracao', label: 'Procuração', sortable: true, width: '115px',
          },
          {
            field: 'vencimento', label: 'Vencimento', sortable: true, width: '200px',
          },
          {
            field: 'acoes', label: 'Ação', sortable: false, thClass: 'th-acao', width: '150px',
          },
        ],
      },
      isCarregando: false,
      buscaDigitada: '',
      filtros: [{ colunas: ['Razão Social', 'Procuracao', 'Vencimento'], valor: null }],
      ordenacao: [],
      maxHeight: 500,
    }
  },
  mounted() {
    this.showModal()
    this.updateHeight()
    window.addEventListener('resize', this.updateHeight)
    document.addEventListener('keydown', this.handleEscKey)
    this.carregarCertificados()
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleEscKey)
    window.removeEventListener('resize', this.updateHeight)
  },
  methods: {
    formatar(nome) {
      const tamanhoMaximo = 40
      if (!nome) {
        return nome
      }
      if (nome.length - 3 > tamanhoMaximo) {
        const nomeFormatado = `${nome.substring(0, tamanhoMaximo)}...`
        return nomeFormatado
      }
      return nome
    },
    handleChange() {
      if (this.value.configuracao.certificadoDigital) {
        if (this.value.certificadoVinculado) {
          this.value.certificadoVinculado.situacao = this.value.configuracao.certificadoDigital.situacao
        } else {
          this.value.certificadoVinculado = this.value.configuracao.certificadoDigital
        }
      } else if (this.value.certificadoVinculado) {
        this.value.certificadoVinculado.situacao = null
      }
      this.carregarCertificados()
      this.buscar()
    },
    houveAlteracao() {
      return JSON.stringify(this.originalConfig) !== JSON.stringify(this.value.configuracao)
    },
    fecharModal() {
      if (this.idsRemovidos.length !== 0) {
        while (this.idsRemovidos.length !== 0) {
          apiCertificado.removerCertificado(this.idsRemovidos.pop())
        }
      }
      if (!this.houveAlteracao()) {
        this.$emit('hidden')
        this.$emit('atualizarGrid')
        this.$refs.modalCertificadoVinculado.hide()
        return
      }
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        apiEmpresa.alterarEmpresa(this.value.configuracao.id, this.value.configuracao)
          .then(() => {
            resolve('Empresa atualizado com sucesso')
            this.$refs.modalCertificadoVinculado.hide()
            this.$emit('hidden')
            this.$emit('atualizarGrid')
          })
          .catch(err => {
            this.$erro('Erro na API:', err.response.data.message)
            reject(err)
          })
      })
    },
    handleEscKey(event) {
      if (event.key === 'Escape' && this.fechar) {
        this.$emit('hidden')
        this.$emit('atualizarGrid')
        this.$refs.modalCertificadoVinculado.hide()
      }
    },
    onModalHidden() {
      this.$emit('hidden')
    },
    fechouModal() {
      this.fechar = true
    },
    trocar(linha) {
      this.fechar = false
      const empresa = `${this.value.configuracao.razaoSocial}`
      const confirmar = () => ({
        title: 'Trocar certificados',
        html: `Ao confirmar a troca, o certificado da empresa <b>${empresa}</b> será substituído pelo certificado selecionado. Tem certeza que deseja alterar o certificado?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Salvar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn w-10 btn-outline-success trocar',
          cancelButton: 'btn w-10 btn-outline-danger trocar',
        },
        buttonsStyling: false,
      })
      Vue.swal.fire(confirmar())
        .then(result => {
          this.fechar = true
          if (result.isConfirmed) {
            if (linha.situacao === 'CertificadoVencido') {
              this.$erro('Certificado Expirado!')
            } else {
              const certificadoSelecionado = this.gridInfo.certificados.find(cert => cert.id === linha.id)
              this.$set(this.value.configuracao, 'certificadoDigital', certificadoSelecionado)
              if (certificadoSelecionado && certificadoSelecionado.porProcuracao) {
                certificadoSelecionado.porProcuracao = certificadoSelecionado.porProcuracao === 'Sim'
              }
              this.handleChange()
            }
          }
        })
    },
    remover(linha) {
      this.fechar = false
      const ids = linha.id
      // this.$confirmar('Remover?', 'Tem certeza que deseja remover o registro?', 'warning')
      const confirmar = () => ({
        title: 'Remover?',
        html: 'Tem certeza que deseja remover o registro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Salvar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn w-10 btn-outline-success trocar',
          cancelButton: 'btn w-10 btn-outline-danger trocar',
        },
        buttonsStyling: false,
      })
      Vue.swal.fire(confirmar())
        .then(result => {
          this.fechar = true
          if (result.isConfirmed) {
            this.idsRemovidos.push(ids)
            const index = this.gridInfo.certificados.findIndex(certificado => certificado.id === ids)
            if (index !== -1) {
              this.gridInfo.certificados.splice(index, 1)
              this.gridInfo.totalCertificados -= 1
              this.buscar()
            }
          }
        })
    },
    carregarCertificados() {
      this.isCarregando = true
      apiCertificado
        .getCertificadosResumidos(this.filtros)
        .then(response => {
          const certificados = response.data
          let certificadosFiltrados = certificados
          if (this.value.configuracao.certificadoDigital) {
            certificadosFiltrados = certificados.filter(cert => cert.id !== this.value.configuracao.certificadoDigital.id)
          }
          this.gridInfo.certificados = certificadosFiltrados.map(cert => ({
            ...cert,
            porProcuracao: cert.porProcuracao ? 'Sim' : 'Não',
            vencimento: new Date(cert.vencimento).toLocaleDateString('pt-BR', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false,
            }),
          }))
          this.gridInfo.certificadosFiltrados = this.gridInfo.certificados
          this.gridInfo.totalCertificados = this.gridInfo.certificadosFiltrados.length
        })
        .catch(err => this.$erro('Erro ao carregar certificados:', err.response.data.message))
        .finally(() => {
          this.isCarregando = false
        })
    },
    openModalEmpresasVinculadas(certificadoDigital) {
      this.fechar = false
      if (certificadoDigital) {
        if (certificadoDigital.situacao !== 'SemEmpresaVinculada') {
          this.$refs['empresas-vinculadas'].showModal(certificadoDigital.id, certificadoDigital.nome)
        }
      }
    },
    openModalCertificadoVinculado(certificadoDigital) {
      this.fechar = false
      if (certificadoDigital) {
        this.$refs['editar-empresas-vinculadas'].showModal(certificadoDigital)
      }
    },
    showModal() {
      this.carregarCertificados()
      this.$refs.modalCertificadoVinculado.show()
    },
    buscar() {
      if (!this.buscaDigitada) {
        this.gridInfo.certificadosFiltrados = [...this.gridInfo.certificados]
      } else {
        const busca = this.buscaDigitada.toLowerCase()
        this.gridInfo.certificadosFiltrados = this.gridInfo.certificados.filter(
          cert => cert.nome.toLowerCase().includes(busca)
            || cert.vencimento.toLowerCase().includes(busca),
        )
      }
      this.gridInfo.totalCertificados = this.gridInfo.certificadosFiltrados.length
    },
    onOrdenacaoAlterada(param) {
      const colOrdenada = param[0]
      if (!colOrdenada) return

      const { field, type } = colOrdenada

      this.gridInfo.certificadosFiltrados.sort((a, b) => {
        if (type === 'asc') {
          return a[field] > b[field] ? 1 : -1
        } if (type === 'desc') {
          return a[field] < b[field] ? 1 : -1
        }
        return 0
      })
    },
    getColorByStatus(situacao) {
      if (!situacao) {
        return 'btn btn-gray text-gray'
      }
      if (situacao === 'ComEmpresaVinculada') {
        return 'btn text-success'
      }
      if (situacao === 'CertificadoAVencer' || situacao === 'CpfDiferente' || situacao === 'VerificacaoDuasEtapas') {
        return 'btn text-warning'
      }
      if (situacao === 'SemEmpresaVinculada') {
        return 'btn text-primary'
      }
      if (situacao === 'CertificadoVencido') {
        return 'btn text-danger'
      }
      if (situacao === 'CertificadoRevogado' || situacao === 'AutorizacaoEcac' || situacao === 'CadastroGov' || situacao === 'PendenteDeAprovacao') {
        return 'btn btn-purple text-purple'
      }
      return ''
    },
    getTextByStatus(situacao) {
      if (!situacao) {
        return 'Não possui certificado'
      }
      if (situacao === 'ComEmpresaVinculada') {
        return 'Visualizar empresas vinculadas'
      }
      if (situacao === 'CadastroDeEmpresas') {
        return 'Importar empresas vinculadas ao certificado'
      }
      if (situacao === 'CertificadoAVencer') {
        return 'Certificado próximo do vencimento'
      }
      if (situacao === 'SemEmpresaVinculada') {
        return 'Não há empresas vinculadas'
      }
      if (situacao === 'CertificadoVencido') {
        return 'Certificado vencido'
      }
      if (situacao === 'AutorizacaoEcac') {
        return 'Autorização e-CAC'
      }
      if (situacao === 'CadastroGov') {
        return 'Certificado não esta cadastrado no Gov'
      }
      if (situacao === 'PendenteDeAprovacao') {
        return 'Pendente de aprovação'
      }
      if (situacao === 'CertificadoRevogado') {
        return 'Certificado digital revogado'
      }
      if (situacao === 'VerificacaoDuasEtapas') {
        return 'Verificação em duas etapas'
      }
      if (situacao === 'CpfDiferente') {
        return 'CPF no certificado digital é diferente do CPF na Receita Federal'
      }
      return ''
    },
    updateHeight() {
      const windowHeight = window.innerHeight
      const topOffset = this.$refs.certificadosList?.$el?.getBoundingClientRect().top || 0
      this.tamanho = windowHeight - topOffset - 400
      this.maxHeight = this.tamanho > 275 ? this.tamanho : 275
    },
  },
}
</script>
