<template>
  <div>
    <b-modal
      id="parcelasList"
      ref="parcelasList"
      button-size="sm"
      no-close-on-backdrop
      ok-only
      no-stacking
      ok-olny
      ok-title="Ok"
      size="lg"
      :no-enforce-focus="true"
      @hidden="hide"
    >
      <div slot="modal-title">
        <h4>{{ 'Parcelas - ' + parcelamento.razaoSocial }}</h4>
        <fieldset class="font-weight-bold text-muted small">
          Nesta tela estão as ultimas parcelas lidas pelo Monitor, você pode mandar as guias por email ou baixar
        </fieldset>
      </div>
      <b-row>
        <b-col>
          <div
            v-for="parc in parcelas"
            :key="parc.id"
          >
            <h5>{{ parc.tributo }}</h5>
            <tabela-repeticao
              :value="[...parc]"
              :somente-visualizacao="false"
              :customizar-botoes="true"
              :colunas="colunas"
              class="tabela-parcelas"
            >
              <template #visualizacao="{slotScope: {key, valor}}">
                <b-badge
                  v-if="key === 'status'"
                  :variant="valor.indexOf('atraso') > 0 ? 'light-danger' : (valor.indexOf('paga') > 0 ? 'light-success' : 'light-primary')"
                >
                  {{ valor }}
                </b-badge>
                <span
                  v-else
                  class="ml-50"
                >
                  {{ valor }}
                </span>
              </template>
              <template #botoes-customizados="{slotScope: {item}}">
                <div v-if="item.status.indexOf('paga') < 0">
                  <button
                    v-b-tooltip.hover
                    title="Gerar Parcela"
                    class="btn btn-sm btn-none px-50 py-0"
                    @click.stop="visualizarGuia(item.id, item.status.indexOf('atraso') > 0)"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-barcode"
                      class="text-primary"
                    />
                  </button>
                  <button
                    v-b-tooltip.hover
                    title="Enviar por e-mail"
                    class="btn btn-sm btn-none px-50 py-0"
                    @click="openEnvioEmail(item.id, item.status.indexOf('atraso') > 0)"
                  >
                    <feather-icon
                      icon="SendIcon"
                      size="20"
                      class="text-primary"
                    />
                  </button>
                </div>
              </template>
            </tabela-repeticao>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <loading
      :show-cancel-button="false"
      :is-loading="isLoading"
      :descricao="'Buscando'"
    />
  </div>
</template>

<style lang="scss">
.modal:nth-of-type(even) {
    z-index: 1052 !important;
}
.modal-backdrop.show:nth-of-type(even) {
    z-index: 1051 !important;
}
</style>

<script>
export default {
  name: 'ParcelasRecentes',
  components: {
    TabelaRepeticao: () => import('@pilar/components/tabela-repeticao/TabelaRepeticao.vue'),
    Loading: () => import('@pilar/components/loading/Loading.vue'),
  },
  props: {
    parcelamento: {
      type: Object,
      required: true,
    },
    requestParcelas: {
      type: Function,
      required: true,
    },
    tipoParcelamento: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mostrarArquivo: false,
      urlArquivo: '',
      parcelas: [],
      parcelasEntrada: [],
      colunas: [
        {
          key: 'competencia', label: 'Competência', tdClass: 'col-2',
        },
        {
          key: 'numeroParcela', label: 'Parcela', tdClass: 'col-2',
        },
        {
          key: 'status', label: 'Status',
        },
        {
          key: 'valor', label: 'Valor (R$)',
        }],
      isLoading: false,
      showParc: false,
      showParcEntrada: false,
    }
  },
  methods: {
    carregarParcelas() {
      if (this.requestParcelas) {
        this.requestParcelas(this.parcelamento.id)
          .then(payload => {
            this.parcelas = payload.data.filter(f => !f.entrada)
            this.showParc = this.parcelas.length > 0
          })
          .catch(err => {
            throw err
          })
      }
    },
    show() {
      this.carregarParcelas()
      this.$refs.parcelasList.show()
    },
    pararEspera() {
      this.isLoading = false
    },
    iniciarEspera() {
      this.isLoading = true
    },
    openEnvioEmail(id) {
      this.$emit('envioEmail', { id: this.parcelamento.id, parcelaId: id })
    },
    visualizarGuia(id, esperar) {
      if (esperar) {
        this.iniciarEspera()
      }
      this.$emit('mostrarGuia', { id: this.parcelamento.id, parcelaId: id })
    },
  },
}

</script>
