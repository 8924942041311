<!-- eslint-disable vue/this-in-template -->
<template>
  <div>
    <componente-certificado-digital-cadastro
      v-model="this.form"
      @remover-arquivo="removerArquivo"
      @click-custom-empresas="clickCustomEmpresas"
      @salvar="salvar()"
      @cancelar="cancelar"
    />
  </div>
</template>

<script>

import {
  ref,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
// eslint-disable-next-line import/no-cycle
import useCertificadoDigital from '@app/certificado-digital/shared/components/cadastro/useCertificadoDigital'

export default {
  name: 'CertificadoDigital',
  components: {
    ComponenteCertificadoDigitalCadastro: () => import('@/app/certificado-digital/shared/components/cadastro/components/ComponenteCertificadoDigitalCadastro.vue'),
  },
  setup() {
    const { route } = useRouter()
    const modoEdicao = route.value.path.indexOf('editar') > 0
    const {
      salvar,
      carregar,
      cancelar,
      form,
      carregarEmpresasParaVinculo,
      updateResponsavelLegal,
    } = useCertificadoDigital(modoEdicao)

    if (modoEdicao) {
      carregar(route.value.params.id, route.value.params.filtro)
    } else {
      carregarEmpresasParaVinculo()
    }

    const removerArquivo = () => {
      form.value.arquivo = {
        url: null,
        fileName: null,
      }
      form.value.porProcuracao = !form.value.porProcuracao
      form.value.porProcuracao = !form.value.porProcuracao
    }

    const corIcone = payload => {
      if (payload) {
        return 'text-success'
      }
      return 'text-muted'
    }

    const clickCustomEmpresas = params => {
      const temp = form.value.empresasVinculadas.vinculadas.find(find => find.id === params.row.id)
      temp.responsavelLegal = !params.row.responsavelLegal
      updateResponsavelLegal(params.row.id, !params.row.responsavelLegal)
    }
    const uploadOpt = ref(null)
    return {
      carregar,
      cancelar,
      form,
      removerArquivo,
      uploadOpt,
      corIcone,
      clickCustomEmpresas,
      salvar,
    }
  },
}
</script>
