<template>
  <b-card>
    <b-row align-h="end">
      <b-col cols="auto">
        <atualizar-em-lote
          texto-botao="Atualizar DAS por PA"
          tipo-rotina="DAS"
          tool-tip="A atualização busca a Guia do DAS do período escolhido e atualiza o vencimento para todas as empresas selecionadas. Cada atualização consumirá dois créditos do saldo em conta."
          titulo-cabecalho="Atualização do DAS por Empresa e período de apuração"
          subtitulo-cabecalho="Selecione as empresas que deseja atualizar"
          titulo="Selecione a(s) empresa(s) que deseja atualizar a guia do DAS"
          subtitulo="As empresas listadas abaixo possuem as configurações do DAS e o Sublimite marcadas no cadastro de empresas em Configurações de rotinas e-CAC"
          :api="apiDas"
          alterar-style="font-size: 11.5px;padding: .707rem 1rem;"
          @atualizar-grid="atualizarGrid()"
        />
      </b-col>
      <b-col cols="auto">
        <download-em-excel
          :filtro="filtroAtual"
          tipo="Das"
          alterar-style="font-size: 11.5px;padding: .65rem 2rem;"
          :api="apiDas"
        />
      </b-col>
      <b-col cols="auto">
        <download-em-lote
          tipo-arquivo="todos DAS"
          tipo="Das"
          :filtro="filtroAtual.filtroBy"
          :palavras-filtro="[['Regulares', 'Irregulares', 'Motivo']]"
          @baixar-lote="baixarEmLote"
          alterar-style="font-size: 11.5px;padding: .45rem 1rem;"
        />
      </b-col>
    </b-row>
    <das-list
      ref="dasList"
      :botoes-opcoes="botoesOption"
      :visualizacao-opcoes="visualizacao"
      :filtros="filtros"
      :filtros-rapidos="filtrosRapidos"
    >
      <template
        slot="custom"
        slot-scope="{ props} "
      >
        <div
          v-if="props.column.field === 'dasEmAtraso'"
        >
          <span
            v-if="props.row.possuiDas == true && props.row[props.column.field] == null"
          >
            -
          </span>
          <b-badge
            v-else
            :variant="props.row[props.column.field] == null ? 'light-primary' : !props.row[props.column.field]? 'light-success': 'light-danger'"
          >
            {{ props.row[props.column.field] == null ? 'Sem DAS' : props.row[props.column.field]? 'Sim' : 'Não' }}
          </b-badge>
        </div>
        <b-badge
          v-if="props.column.field === 'hasDebitos'"
          :variant="!props.row[props.column.field]? 'light-success': 'light-danger'"
        >
          {{ props.row[props.column.field]? 'Sim' : 'Não' }}
        </b-badge>
      </template>
      <template
        slot="acoes"
        slot-scope="{ props} "
      >
        <acoes
          :ref="`acoes${props.row.id}`"
          :grid-props="props"
          @atualizar-grid="atualizarGrid"
        />
      </template>
    </das-list>
  </b-card>
</template>

<style lang="scss">
.modal-lg {
  max-width: 1000px!important;
}

.data-width{
  width: 120px!important;
}

.tamanho-cnpj {
  min-width: 194px!important;
}
</style>

<script>
import colunas from './shared/components/colunas'
import api from './shared/services/api'

export default {
  name: 'DasView',
  components: {
    DasList: () => import('@pilar/components/visualizacao-base/VisualizacaoBase.vue'),
    Acoes: () => import('./shared/components/acoes/Acoes.vue'),
    DownloadEmLote: () => import('@app/download-lote/DownloadEmLote.vue'),
    DownloadEmExcel: () => import('@app/download-excel/DownloadEmExcel.vue'),
    AtualizarEmLote: () => import('../shared/components/atualizacao-em-lote/AtualizacaoEmLote.vue'),
    // FiltroRapido: () => import('@pilar/components/visualizacao-base/components/filtro-rapido/FiltroRapido.vue'),
  },
  data() {
    return {
      apiDas: api,
      botoesOption: null,
      linha: null,
      visualizacao: {
        acaoClass: 'col-td-acao-3',
        disabledCheckbox: true,
        placeholderBusca: 'Buscar por Razão Social, CNPJ/CPF, Competência',
        colunas,
        evento: this.carregar,
      },
      filtros: [{ colunas: ['CadastroFederal', 'RazaoSocial', 'CompetenciaSN', 'CadastroFederalSemMascara'], valor: null }],
      filtrosRapidos: [
        {
          id: 'DasPago',
          colunas: ['DasDoMes'],
          label: 'DAS do mês paga',
          valor: 1,
        },
        {
          id: 'DasEmAberto',
          colunas: ['DasDoMesAberto'],
          label: 'Das do mês em aberto',
          valor: 2,
        },
        {
          id: 'SemDas',
          colunas: ['SemDas'],
          label: 'Sem DAS',
          valor: true,
        },
        {
          id: 'Debito',
          colunas: ['ComDebito'],
          label: 'Débito de DAS',
          valor: true,
        },
        {
          id: 'SemDebito',
          colunas: ['Debito'],
          label: 'Sem débito de DAS',
          valor: false,
        },
      ],
      filtroAtual: {},
    }
  },
  mounted() {
    this.aplicarFiltroQuery()
  },
  updated() {
    this.aplicarFiltroQuery()
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    carregar(pagina, tamanhoPagina, filtro) {
      this.filtroAtual = filtro

      return api.getPaginado(pagina, tamanhoPagina, filtro)
    },

    baixarEmLote(evt) {
      api.baixaEmLote(this.filtroAtual, evt)
    },

    atualizarGrid() {
      this.$refs.dasList.refresh()
    },

    aplicarFiltroQuery() {
      this.$nextTick(() => {
        const params = this.$route.query
        if (params && this.$refs.dasList) {
          const filtroRapido = params.filtroRapido
            ? this.filtrosRapidos.find(f => f.id === params.filtroRapido)
            : null
          this.$refs.dasList.aplicarFiltro(params.busca, filtroRapido)
        }
      })
    },
  },
}
</script>
