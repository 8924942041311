<template>
  <div class="componente">
    <b-row class="accordion">
      <b-col cols="12">
        <div @click="mostrarEsferas()">
          <br>
          <span style="font-weight: bold"> {{ capitalizedTipoEsfera }} </span>
          <b-badge
            v-if="value && qtdTipo > 0"
            variant="primary"
          >
            + {{ quantidadeTipo() }}
          </b-badge>
          <div style="float:right">
            <feather-icon
              v-if="show"
              style="margin-right:0.25rem"
              icon="ChevronUpIcon"
            />
            <feather-icon
              v-else
              style="margin-right:0.25rem"
              icon="ChevronDownIcon"
            />
          </div>
        </div>
        <br>
        <b-col cols="12">
          <hr
            class="mx-0 mt-0 mb-2"
            style="border-top: 0.1rem solid #a3a4a66b !important; margin-left: 1.5rem !important;"
          >
        </b-col>
        <div style="float:right;">
          <b-btn
            v-if="show && quantidadeTipo() > 0"
            variant="none"
            class="btn btn-outline-primary mr-1"
            size="sm"
            @click="novaEsfera()"
          >
            <feather-icon
              style="margin-right:0.25rem"
              icon="PlusIcon"
            />Novo
          </b-btn>
        </div>
        <br>
        <br>
        <div
          v-for="(item, index) in getGestaoSenha()"
          :key="capitalizedTipoEsfera + '-' + index"
        >
          <div v-if="capitalizedTipoEsfera === 'Federal'">
            <div
              v-if="temEsfera('federal', index)"
              style="margin-top: -0.62rem;"
            >
              <b-collapse v-model="show">
                <b-card-body>
                  <esfera-senhas
                    :value="model(index)"
                    @input="updateGestaoSenha(index, $event)"
                    @remover-esfera="removerEsfera(index)"
                    @salvar-backup="salvarBackup(index)"
                  />
                  <br>
                  <br>
                  <br>
                  <b-col cols="12">
                    <hr
                      class="mx-0 mt-0 mb-2"
                      style="border-top: 0.1rem solid #a3a4a66b !important;"
                    >
                  </b-col>
                </b-card-body>
              </b-collapse>
            </div>
          </div>
          <div v-if="capitalizedTipoEsfera === 'Estadual'">
            <div
              v-if="temEsfera('estadual', index) "
              style="margin-top: -0.62rem;"
            >
              <b-collapse v-model="show">
                <b-card-body>
                  <esfera-senhas
                    :value="model(index)"
                    @input="updateGestaoSenha(index, $event)"
                    @remover-esfera="removerEsfera(index)"
                    @salvar-backup="salvarBackup(index)"
                  />
                  <br>
                  <br>
                  <br>
                  <b-col cols="12">
                    <hr
                      class="mx-0 mt-0 mb-2"
                      style="border-top: 0.1rem solid #a3a4a66b !important;"
                    >
                  </b-col>
                </b-card-body>
              </b-collapse>
            </div>
          </div>
          <div v-if="capitalizedTipoEsfera === 'Municipal'">
            <div
              v-if="temEsfera('municipal', index)"
              style="margin-top: -0.62rem;"
            >
              <b-collapse v-model="show">
                <b-card-body>
                  <esfera-senhas
                    :value="model(index)"
                    @input="updateGestaoSenha(index, $event)"
                    @salvar-backup="salvarBackup(index)"
                    @remover-esfera="removerEsfera(index)"
                  />
                  <br>
                  <br>
                  <br>
                  <b-col cols="12">
                    <hr
                      class="mx-0 mt-0 mb-2"
                      style="border-top: 0.1rem solid #a3a4a66b !important;"
                    >
                  </b-col>
                </b-card-body>
              </b-collapse>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

  <style lang="scss">
  .componente {
    .accordion {
    background-color: rgb(242, 242, 242);
    border-radius: 5px;
    padding: 5px;
  }

  .accordion.disabled {
    background-color: rgb(248, 248, 248);
    color: rgb(150, 150, 150);
  }
  }
  </style>

<script>
export default {
  name: 'ComponenteSenhas',
  components: {
    EsferaSenhas: () => import('./esfera/EsferaSenhas.vue'),
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    tipoEsfera: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      show: true,
      qtdTipo: 0,
    }
  },
  computed: {
    capitalizedTipoEsfera() {
      if (!this.tipoEsfera) return ''
      return this.tipoEsfera.charAt(0).toUpperCase() + this.tipoEsfera.slice(1).toLowerCase()
    },
  },
  methods: {
    salvarBackup(index) {
      this.$emit('salvar-backup', index)
    },
    temEsfera(tipo, index) {
      if (this.value.gestaoSenha[index].tipoEsferaExibicao === tipo) {
        return true
      }
      return false
    },
    model(index) {
      return this.value.gestaoSenha[index]
    },
    updateGestaoSenha(index, newValue) {
      this.$set(this.value.gestaoSenha, index, newValue)
    },
    quantidadeTipo() {
      this.qtdTipo = this.value.gestaoSenha.filter(w => w.tipoEsferaExibicao !== undefined && w.tipoEsferaExibicao.toLowerCase() === this.tipoEsfera.toLowerCase()).length
      return this.qtdTipo
    },
    getGestaoSenha() {
      this.quantidadeTipo()
      return this.value.gestaoSenha
    },
    novaEsfera() {
      const novaEsfera = ({
        tipoEsfera: this.tipoEsfera, show: false, tipoEsferaExibicao: this.tipoEsfera, edicao: true,
      })
      this.value.gestaoSenha.push(novaEsfera)
    },
    mostrarEsferas() {
      if (this.show) {
        this.show = false
      } else {
        this.show = true
      }
    },
    removerEsfera(index) {
      this.$confirmar('Remover?', 'Tem certeza que deseja excluir o registro?', 'warning')
        .then(result => {
          if (result.value) {
            this.value.gestaoSenha.splice(index, 1)
            this.$emit('remover-esfera', index)
          }
        })
    },
  },
}
</script>
