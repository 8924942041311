<template>
  <b-row>
    <b-col>
      <tabela-repeticao
        v-model="parametros"
        :somente-visualizacao="true"
        :colunas="colunas"
        class="tabela-historico-parametros"
      >
        <template #visualizacao="{slotScope: {key,valor}}">
          <div>
            {{ valor }}
          </div>
        </template>
      </tabela-repeticao>
    </b-col>
  </b-row>

</template>

<script>
import colunas from './useColunas'

export default {
  name: 'VisualizacaoHistorico',
  components: {
    TabelaRepeticao: () => import('@pilar/components/tabela-repeticao/TabelaRepeticao.vue'),
  },
  props: {
    parametros: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      colunas,
    }
  },

  methods: {
  },
}

</script>
