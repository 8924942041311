<!-- eslint-disable vue/this-in-template -->
<template>
  <b-modal
    id="editar-empresas-vinculadas"
    ref="editar-empresas-vinculadas"
    close-on-backdrop
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    header-class="pb-0"
    button-size="sm"
    dialog-class="custom-modal2"
    hide-footer
  >
    <template #modal-title>
      <h3>Editar certificado digital</h3>
    </template>
    <componente-certificado-digital-cadastro
      v-model="this.value"
      @remover-arquivo="removerArquivo"
      @click-custom-empresas="clickCustomEmpresas"
      @salvar="salvar()"
      @cancelar="cancelar()"
    />
  </b-modal>
</template>

  <style lang="scss">
  #editar-empresas-vinculadas {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: center !important;
}
.custom-modal2 {
  max-width: 1500px !important; /* Defina o tamanho desejado */
  width: 90%;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
    @import "@core/scss/vue/libs/vue-good-table.scss";
  </style>

<script>

import '@core/scss/vue/libs/vue-select.scss'
import apiCertificado from '@/app/certificado-digital/shared/services/api'
import Vue from 'vue'
import EventBus from '@app/shared/mixins/event-bus/eventBus'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ModalEditarEmpresasVinculadas',
  components: {
    ComponenteCertificadoDigitalCadastro: () => import('@/app/certificado-digital/shared/components/cadastro/components/ComponenteCertificadoDigitalCadastro.vue'),
  },
  data() {
    return {
      form: {
        certificadoDigital: {
        },
      },
      value: {
        id: 0,
        arquivo: {},
        senha: '',
        porProcuracao: false,
        cadastrarEmpresas: false,
        habilitarCampos: false,
        cadastroFederal: '',
        razaoSocial: '',
        cadastroFederalTipo: 'cnpj',
        empresasVinculadas: {
          vinculadas: [],
          naoVinculadas: [],
          totalVinculadas: 0,
          totalNaoVinculadas: 0,
        },
      },
      toast: useToast(),
    }
  },
  methods: {
    removerArquivo() {
      this.value.arquivo = {
        url: null,
        fileName: null,
      }
      this.value.porProcuracao = !this.value.porProcuracao
      this.value.porProcuracao = !this.value.porProcuracao
    },
    clickCustomEmpresas(params) {
      const temp = this.value.empresasVinculadas.vinculadas.find(find => find.id === params.row.id)
      temp.responsavelLegal = !params.row.responsavelLegal
      this.updateResponsavelLegal(params.row.id, !params.row.responsavelLegal)
    },
    showModal(certificadoDigital) {
      this.form.certificadoDigital = certificadoDigital
      this.carregar()
      this.$refs['editar-empresas-vinculadas'].show()
    },
    updateResponsavelLegal(empresaId, veracidade) {
      apiCertificado.alterarResponsavelLegal(empresaId, veracidade)
    },
    carregar() {
      apiCertificado.getCertificado(this.form.certificadoDigital.id)
        .then(payload => {
          this.value = payload.data
          this.value.arquivo = {
            fileName: payload.data.nomeArquivo,
          }
        })
        .catch(err => {
          throw err
        })
        .finally(() => {
          EventBus.$emit('finalizaLoading')
        })
    },
    cancelar() {
      this.$emit('fechou')
      this.$refs['editar-empresas-vinculadas'].hide()
    },
    salvar() {
      // eslint-disable-next-line no-unused-vars
      const promi = new Promise((resolve, reject) => {
        EventBus.$emit('inicioLoading')
        apiCertificado.alterarCertificado(this.value.id, this.value)
          .then(() => {
            this.$sucesso('Certificado digital atualizado com sucesso')
          })
          .catch(err => {
            // eslint-disable-next-line no-undef
            this.tratarErro(err, reject)
          })
          .finally(() => {
            EventBus.$emit('finalizaLoading')
          })
      })
      this.$emit('fechou')
      this.$emit('carregarCertificados')
      this.$emit('buscar')
      this.$refs['editar-empresas-vinculadas'].hide()
    },
    tratarErro(err, reject) {
      if (!err.response || (err.response && err.response.status !== 500)) {
        reject(err)
      } else {
        Vue.prototype.$toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.message === 'A senha de rede especificada não está correta.' ? 'Senha Inválida!' : err.response.data.message,
            icon: 'fa-solid fa-circle-exclamation',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>
