<template>
  <span class="acoes-grid">
    <b-btn
      :id="`botao-vinculo${row.id}`"
      v-b-tooltip.hover
      variant="none"
      class="btn rounded-pill icone-acao p-0"
      style="margin-right:0.3rem"
      :class="vinculoButtonClass"
      :title="vinculoTooltipText"
      @click="openVinculo"
    >
      <font-awesome-icon
        icon="fa-solid fa-lock"
        class="rounded-pill icone-acao"
      />
    </b-btn>
  </span>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['row'],
  data() {
    return {
    }
  },
  computed: {
    vinculoButtonClass() {
      return this.getColorByStatus(this.row.situacao)
    },
    vinculoTooltipText() {
      return this.getTextByStatus(this.row.situacao)
    },
  },
  methods: {
    openVinculo() {
      this.$emit('open-vinculo', this.row)
    },
    getColorByStatus(situacao) {
      switch (situacao) {
        case 'ComEmpresaVinculada': return 'btn text-success'
        case 'CertificadoAVencer':
        case 'CpfDiferente':
        case 'VerificacaoDuasEtapas': return 'btn text-warning'
        case 'SemEmpresaVinculada': return 'btn text-primary'
        case 'CertificadoVencido': return 'btn text-danger'
        case 'CertificadoRevogado':
        case 'AutorizacaoEcac':
        case 'CadastroGov':
        case 'PendenteDeAprovacao': return 'btn btn-purple text-purple'
        default: return ''
      }
    },
    getTextByStatus(situacao) {
      switch (situacao) {
        case 'ComEmpresaVinculada': return 'Visualizar empresas vinculadas'
        case 'CadastroDeEmpresas': return 'Importar empresas vinculadas ao certificado'
        case 'CertificadoAVencer': return 'Certificado próximo do vencimento'
        case 'SemEmpresaVinculada': return 'Não há empresas vinculadas'
        case 'CertificadoVencido': return 'Certificado vencido'
        case 'AutorizacaoEcac': return 'Autorização e-CAC'
        case 'CadastroGov': return 'Certificado não está cadastrado no Gov'
        case 'PendenteDeAprovacao': return 'Pendente de aprovação'
        case 'CertificadoRevogado': return 'Certificado digital revogado'
        case 'VerificacaoDuasEtapas': return 'Verificação em duas etapas'
        case 'CpfDiferente': return 'CPF no certificado digital é diferente do CPF na Receita Federal'
        default: return ''
      }
    },
  },
}
</script>
