<template>
  <b-row>
    <b-col>
      <divisoria-descricao
        v-if="value.razaoSocial"
        :titulo="`Empresa: ${value.cadastroFederal} - ${value.razaoSocial}`"
      />
      <divisoria-descricao
        titulo="Senhas de acesso"
        descricao="Informe as senhas e códigos de acesso correspondente a cada esfera"
        espaco-extra
      >
        <template slot="valor-extra">
          <div style="float:right;">
            <v-select
              id="selectAcesso"
              v-model="selectedEsfera"
              v-b-tooltip.hover
              variant="none"
              label="nome"
              :options="opcoesEsfera"
              placeholder="+ Novo"
              style="width: 112px;padding: 0rem 1rem;"
              @input="novo"
            />
          </div>
        </template>
      </divisoria-descricao>
      <div>
        <div>
          <componente-senhas
            v-if="tem('Federal') > 0"
            id="federal"
            v-model="value"
            tipo-esfera="federal"
            @salvar-backup="salvarBackup"
            @remover-esfera="removerEsfera"
          />
          <br>
          <b-col
            v-if="tem('Federal') > 0"
            cols="12"
          >
            <hr
              class="mx-0 mt-0 mb-2"
              style="border-top: 1px solid #a3a4a66b !important;"
            >
          </b-col>
          <componente-senhas
            v-if="tem('Estadual') > 0"
            id="estadual"
            v-model="value"
            tipo-esfera="estadual"
            @salvar-backup="salvarBackup"
            @remover-esfera="removerEsfera"
          />
          <br>
          <b-col
            v-if="tem('Estadual') > 0"
            cols="12"
          >
            <hr
              class="mx-0 mt-0 mb-2"
              style="border-top: 1px solid #a3a4a66b !important;"
            >
          </b-col>
          <componente-senhas
            v-if="tem('Municipal') > 0"
            id="municipal"
            v-model="value"
            tipo-esfera="municipal"
            @salvar-backup="salvarBackup"
            @remover-esfera="removerEsfera"
          />
          <b-row>
            <b-col
              v-if="tem('Municipal') > 0"
              cols="12"
            >
              <hr
                class="mx-0 mt-1 mb-1"
                style="border-bottom: 1px solid #a3a4a66b !important;"
              >
            </b-col>
          </b-row>
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<style lang="scss">

  [dir] #selectAcesso .vs__dropdown-toggle{
  border-color: #2c3e50 !important;
  height: 2rem !important;
}

#selectAcesso .vs__open-indicator {
  display: none ;
}

#selectAcesso .vs__dropdown-menu {
  min-width: unset ;
}
#selectAcesso .vs__search {
  margin-top: -1px;
}
#selectAcesso {
  margin-left: -8rem !important;
}

#selectAcesso .vs__dropdown-option {
  text-align: left;
}
</style>

<script>
import '@core/scss/vue/libs/vue-select.scss'
import vSelect from 'vue-select'

export default {
  name: 'SenhasAcesso',
  components: {
    vSelect,
    DivisoriaDescricao: () => import('@pilar/components/divisoria-descricao/DivisoriaDescricao.vue'),
    ComponenteSenhas: () => import('./components/ComponenteSenhas.vue'),
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      vezes: 0,
      selectedEsfera: '',
      entrou: false,
      gestaoSenhaBackup: [{
      }],
      opcoesEsfera: ['Federal', 'Estadual', 'Municipal'],
    }
  },
  methods: {
    removerEsfera(index) {
      if (this.gestaoSenhaBackup[index + 1] !== undefined) {
        this.gestaoSenhaBackup.splice(index + 1, 1)
      }
    },
    salvarBackup(index) {
      if (this.value.gestaoSenha[index].existia) {
        this.gestaoSenhaBackup.splice(index, 1)
      }
      this.value.gestaoSenha[index].existia = true
      this.gestaoSenhaBackup.push(
        JSON.parse(JSON.stringify(this.value.gestaoSenha[index])),
      )
    },
    novo(selectedEsfera) {
      if (selectedEsfera !== null) {
        // eslint-disable-next-line no-param-reassign
        selectedEsfera = selectedEsfera.charAt(0).toLowerCase() + selectedEsfera.slice(1).toLowerCase()
        const novaEsfera = {
          tipoEsfera: selectedEsfera, show: false, edicao: true, tipoEsferaExibicao: selectedEsfera,
        }
        for (let i = 0; i < this.value.gestaoSenha.length; i += 1) {
          if (this.value.gestaoSenha[i].edicao === true) {
            if (!this.value.gestaoSenha[i].existia && !this.value.gestaoSenha[i].id) {
              this.value.gestaoSenha.splice(i, 1)
            } else {
              this.value.gestaoSenha[i].edicao = false
              this.value.gestaoSenha[i] = JSON.parse(JSON.stringify(this.gestaoSenhaBackup[i + 1]))
            }
          }
        }
        this.value.gestaoSenha.push(novaEsfera)
        this.selectedEsfera = null
      }
    },
    tem(tipo) {
      if (this.vezes === 0 && this.$props.value.gestaoSenha.length > 0) {
        this.$props.value.gestaoSenha.forEach(valor => {
          // eslint-disable-next-line no-param-reassign
          valor.tipoEsferaExibicao = JSON.parse(JSON.stringify(valor.tipoEsfera))
          this.gestaoSenhaBackup.push(JSON.parse(JSON.stringify(valor)))
        })
        this.vezes += 1
      }
      const tipoNormalizado = tipo.toLowerCase()
      if (this.value.gestaoSenha.length >= 1) {
        return this.value.gestaoSenha.filter(item => item.tipoEsferaExibicao === tipoNormalizado).length
      }

      return 0
    },
  },
}
</script>
