import Vue from 'vue'

export default function () {
  Vue.swal({
    title: '<div class="text-warning">Atenção</div>',
    html:
        `<div class="text-primary">
            Prezado Cliente,
            <br>Informamos que estamos enfrentando instabilidade na geração de guias dos parcelamentos devido a dificuldades técnicas no e-CAC, 
            o que pode afetar alguns processos automáticos.
            <br>Nossa equipe técnica já está trabalhando para resolver o problema com urgência e estamos monitorando a situação de perto. 
            <br>Caso tenha dúvidas ou precise de suporte, entre em contato com nossa equipe de atendimento.
            <br><br><br>
            <br><br><br>
            Para mais informações, estamos a disposição através do (11) 99158-8992.
        </div><br>

        <div class="text-warning">
            Atenciosamente, Equipe Monitor Contábil
        </div>`,
    icon: 'info',
    iconColor: '#FF9F43',
    showConfirmButton: false,
    width: '42%',
    customClass: {
      popup: 'swal-m',
    },
  })
}
