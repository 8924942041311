<!-- eslint-disable vue/this-in-template -->
<template>
  <span>
    <button
      v-b-tooltip.hovern
      :style="alterarStyle"
      :title="toolTip"
      class="btn btn-sm btn-outline-primary"
      @click="abrirModal()"
    >
      <span>{{ textoBotao }}</span>
    </button>
    <b-modal
      ref="modalAtualizacaoPa"
      size="xl"
      :hide-header-close="true"
      modal-class="tamanho-modal"
    >
      <template #modal-title>
        <b-row>
          <b-col>
            <h4>{{ tituloCabecalho }}</h4>
          </b-col>
        </b-row>
        <fieldset
          class="font-weight-bold text-muted small d-flex flex-row align-items-center"
          style="gap: .3rem;"
        >
          <span>{{ subtituloCabecalho }}</span>
          <feather-icon
            v-b-tooltip.hover
            :title="`Ao confirmar a atualização será descontado ${tipoRotina === 'Situação Fiscal' ? 'um' : 'dois'} crédito${tipoRotina === 'Situação Fiscal' ? '' : 's'} de cada empresa selecionada.`"
            icon="AlertCircleIcon"
            size="12"
            :class="'text-primary'"
          />
        </fieldset>
      </template>
      <template #modal-footer>
        <div class="d-flex justify-content-end">
          <b-btn
            variant="none"
            size="sm"
            class="btn-outline-success btn-none"
            style="margin-left: 0.3rem;"
            @click="verificarModalAbrir()"
          >
            Salvar
          </b-btn>
          <b-btn
            variant="none"
            size="sm"
            class="btn-outline-danger btn-none"
            style="margin-left: 0.3rem;"
            @click="fecharModal()"
          >
            Cancelar
          </b-btn>
        </div>
      </template>
      <div>
        <b-row
          v-if="tipoRotina==='DCTFWeb' || tipoRotina==='DAS'"
          class="d-flex align-items-end mb-2"
        >
          <b-col cols="auto">
            <h5>Selecione o período de apuração desejado</h5>
          </b-col>
          <b-col
            cols="auto"
            class="mr-2"
          >
            <b-form-input
              v-model="filtroCustomData"
              type="month"
              style="width: 12rem;"
              @change="aplicarFiltroRapido"
            />
          </b-col>
          <div
            v-if="tipoRotina==='DAS'"
            class="d-flex align-items-center"
          >
            <b-col
              cols="auto"
              class="pr-0"
            >
              <h5>Selecione a data de pagamento</h5>
            </b-col>
            <b-col style="padding-bottom: 10px;gap: 1rem;">
              <button
                variant="none"
                class="btn btn-none rounded-pill m-0 icone-acao p-0 btn-none"
                @click="openCalcularDas()"
              >
                <font-awesome-icon
                  icon="fa-calculator"
                  class="text-primary fa-lg"
                />
              </button>
            </b-col>
          </div>
        </b-row>
        <vinculo-empresas
          v-model="empresasVinculadas"
          :cadastro-usuario="false"
          :visualizacao-opcoes="visualizacao"
          :tem-busca="true"
          :inverter-vincular-desvincular="true"
          :filtros-vinculadas="filtrosVinculadas"
          :filtros-nao-vinculadas="filtrosNaoVinculadas"
          :tipo-rotina="tipoRotina"
          :filtros-rapidos="filtrosRapidos"
          :tem-filtro-rapido-vinculado="temFiltroRapido"
          :quantidade="quantidadeCaracteres"
        />
      </div>
    </b-modal>
    <consumo-credito
      ref="atualizarManual"
      :tipo-rotina="tipoRotina"
      :credito-consumo="creditoConsumo"
      @atualizar="atualizarManual()"
      @fechar="$refs.atualizarManual.$refs.consumoCredito.hide()"
    />
    <loading
      :show-cancel-button="false"
      :is-loading="isLoading"
      :titulo-carregamento="'Aguarde!'"
      :descricao="`Estamos atualizando ${this.tipoRotina === 'DAS' ? 'o' : 'a'} ${tipoRotina}.`"
    />
    <recalcular-das
      ref="recalcularDasModal"
      @recalcular="getDataPagamento($event.data)"
    />
  </span>
</template>

<style lang="scss">

.tamanho-modal {
  margin-left: 18rem;
  width: calc(100% - 18rem);
}

</style>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import EventBus from '@app/shared/mixins/event-bus/eventBus'
import moment from 'moment'

export default {
  components: {
    FontAwesomeIcon,
    VinculoEmpresas: () => import('@pilar/components/vinculo-empresas/VinculoEmpresas.vue'),
    ConsumoCredito: () => import('@/app/shared/components/modal-consumo-credito/ModalConsumoCredito.vue'),
    Loading: () => import('@pilar/components/loading/Loading.vue'),
    RecalcularDas: () => import('../../../das/shared/components/recalcular/RecalcularDas.vue'),
  },
  props: {
    textoBotao: {
      type: String,
      default: 'Atualizar em lote',
    },
    tipoRotina: {
      type: String,
      required: true,
    },
    toolTip: {
      type: String,
      required: true,
    },
    tituloCabecalho: {
      type: String,
      required: true,
    },
    subtituloCabecalho: {
      type: String,
      required: true,
    },
    titulo: {
      type: String,
      required: true,
    },
    subtitulo: {
      type: String,
      required: true,
    },
    api: {
      type: Object,
      required: true,
    },
    alterarStyle: {
      type: String,
      default: '',
    },
    temFiltroRapido: {
      type: Boolean,
      default: false,
    },
    filtrosRapidos: {
      type: Array,
      default: () => [],
    },
    quantidadeCaracteres: {
      type: Number,
      default: 25,
    },
  },
  data() {
    return {
      filtroCustomData: '',
      visualizacao: {
        placeholderBusca: 'Busca por palavra chave e CNPJ/CPF',
        tituloVinculada: this.tipoRotina === 'DAS' ? 'Empresas com DAS' : 'Empresas disponíveis para atualização',
        tituloDesvinculada: 'Empresas que devem ser atualizadas',
        evento: this.getEmpresasAtualizacaoEmLoteFiltrado,
      },
      filtrosVinculadas: [{ colunas: ['CadastroFederalSemMascara', 'CadastroFederal', 'RazaoSocial'], valor: null }],
      filtrosNaoVinculadas: [{ colunas: ['CadastroFederalSemMascara', 'CadastroFederal', 'RazaoSocial'], valor: null }],
      empresasVinculadas: {
        vinculadas: [],
        naoVinculadas: [],
        id: 0,
      },
      creditoConsumo: undefined,
      isLoading: false,
      dataPagamento: null,
      dataApuracao: null,
    }
  },
  created() {
    this.filtroCustomData = moment().subtract(1, 'month').format('yyyy-MM')
    this.visualizacao.customTitle = this.titulo
    this.visualizacao.customSubtitle = this.subtitulo
  },
  methods: {
    abrirModal() {
      this.limparModal()
      this.api.getEmpresasAtualizacaoEmLote()
        .then(payload => {
          this.empresasVinculadas = payload.data
          this.$refs.modalAtualizacaoPa.show()
        })
        .catch(() => {
          this.$erro(`Não foi possível buscar as empresas vinculadas à ${this.tipoRotina}.`)
        })
    },
    fecharModal() {
      this.$refs.modalAtualizacaoPa.hide()
    },
    limparModal() {
      this.dataPagamento = null
      this.filtroCustomData = moment().subtract(1, 'month').format('yyyy-MM')
      this.$refs.recalcularDasModal.dataRecalculo = ''
    },
    openModalCreditos() {
      this.api.getConsumoCredito({
        empresasId: this.empresasVinculadas.naoVinculadas.map(m => m.id),
        competencia: this.filtroCustomData,
      }).then(payload => {
        this.creditoConsumo = payload.data
        this.$emit('stacking', false)
        this.$nextTick(() => {
          this.$refs.atualizarManual.$refs.consumoCredito.show()
        })
      })
    },
    atualizarManual() {
      if (this.empresasVinculadas.naoVinculadas.some(s => s)) {
        this.isLoading = true
        this.$refs.atualizarManual.$refs.consumoCredito.hide()
        this.$refs.modalAtualizacaoPa.hide()

        this.api.atualizarManual(
          {
            empresasId: this.empresasVinculadas.naoVinculadas.map(m => m.id),
            competencia: this.filtroCustomData,
          },
          this.dataPagamento,
        )
          .then(payload => {
            this.$emit('atualizar-grid')
            this.verificarRetornos(payload.data)
          })
          .catch(err => {
            this.isLoading = false
            if (err.response.data.message.includes('Empresa(s) não atualizada(s):')) {
              this.openModalErro(`Empresa(s) sem ${this.tipoRotina} ou com erro`, err.response.data.message)
            } else {
              this.$erro('Não foi possível fazer a atualização. Tente novamente mais tarde!', err)
            }
            throw err
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.$erro('Selecione pelo menos uma empresa para continuar.')
      }
    },
    verificarRetornos(enumResult) {
      if (enumResult === 1) {
        this.$sucesso(`${this.tipoRotina} atualizada com sucesso!`)
      } else if (enumResult === 3) {
        this.openModalErro(`Limite atingido', 'O limite de emissão diária da ${this.tipoRotina} foi atingido para todos os certificados válidos disponíveis. Aguarde a liberação de novas consultas.`)
      } else if (enumResult === 4) {
        this.openModalErro('Nenhum certificado encontrado', 'Nenhum certificado válido foi encontrado. A atualização manual não pode ser realizada.')
      } else if (enumResult === 5) {
        this.$erro('Saldo insuficiente para concluir a operação.')
      } else if (enumResult === 6) {
        this.$erro('Procuração eletrônica - Empresa apresenta erro com a procuração eletrônica. Verifique e corrija para evitar cobranças indesejadas e erros nas atualizações das rotinas.')
      } else if (enumResult === 8) {
        this.$erro(`Não possui uma ${this.tipoRotina} para o período de apuração`)
      } else if (enumResult.includes('Empresa(s) não atualizada(s):')) {
        this.openModalErro('Empresa(s) com erro na atualização', enumResult)
      } else {
        this.$erro('Houve um problema com a atualização. Tente novamente mais tarde!')
      }
    },
    openModalErro(title, html) {
      this.$swal({
        title: `<div class="text-danger">${title}!</div>`,
        html: `<div class="text-primary">${html}</div>`,
        icon: 'warning',
        iconColor: '#ea5455',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-outline-danger mr-50',
        },
        buttonsStyling: false,
      })
    },
    getEmpresasAtualizacaoEmLoteFiltrado(id, body) {
      this.bodyModificado = body
      this.bodyModificado.periodoApuracao = this.filtroCustomData
      return this.api.getEmpresasAtualizacaoEmLoteFiltrado(this.bodyModificado)
    },
    openCalcularDas(id) {
      this.$refs.recalcularDasModal.show(id)
    },
    getDataPagamento(data) {
      this.dataPagamento = data
    },
    verificarModalAbrir() {
      if (!this.empresasVinculadas.naoVinculadas.some(s => s)) {
        this.$erro('Selecione pelo menos uma empresa para continuar.')
      } else if (this.tipoRotina === 'DAS' && this.dataPagamento === null) {
        this.openCalcularDas()
      } else {
        this.openModalCreditos()
      }
    },
    aplicarFiltroRapido(dataPeriodoApuracao) {
      this.verificarValorPeriodoApuracao(dataPeriodoApuracao)
      if (this.tipoRotina === 'DAS') {
        EventBus.$emit('aplicarFiltroPeridoApuracaoDas')
      }
    },
    verificarValorPeriodoApuracao(data) {
      if (!data && this.tipoRotina === 'DAS') {
        this.filtroCustomData = moment().subtract(1, 'month').format('yyyy-MM')
      }
    },
  },
}
</script>
