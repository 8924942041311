<template>
  <div style="display: inline-block;">
    <button
      v-b-tooltip.hover
      variant="none"
      title="Visualizar guia do parcelamento"
      class="btn btn-none rounded-pill m-0 icone-acao p-0 btn-none"
      :disabled="disableBySituacaoEParcela"
      @click="visualizarGuia(gridProps.row)"
    >
      <font-awesome-icon
        icon="fa-solid fa-barcode"
        :class="gridProps.row.ultimaParcelaPaga || disableBySituacaoEParcela ? 'text-primary' : 'text-warning'"
      />
    </button>

    <b-btn
      v-b-tooltip.hover
      title="Observação"
      variant="none"
      class="btn-none rounded-pill m-0 icone-acao p-0"
      :disabled="disableBySituacaoEParcela"
      @click="openModalObservacao(gridProps.row)"
    >
      <font-awesome-icon
        icon="fa-regular fa-message"
        :class="{ 'text-warning': temObservacaoNova, 'text-primary': !temObservacaoNova }"
      />
    </b-btn>

    <button
      v-b-tooltip.hover
      variant="none"
      title="Enviar por e-mail"
      class="btn btn-none rounded-pill m-0 icone-acao p-0 btn-none"
      :disabled="disableBySituacaoEParcela"
      @click="openEnvioEmail(gridProps.row)"
    >
      <feather-icon
        icon="SendIcon"
        class="text-primary"
        size="20"
      />
    </button>
    <button
      v-b-tooltip.hover
      variant="none"
      title="Parcelas"
      :disabled="gridProps.row.mesUltimaParcela == '-'"
      class="btn btn-none rounded-pill m-0 icone-acao p-0 btn-none"
      @click="openModalParcelas(gridProps.row.id, gridProps.row.nroUltimaParcelaDe)"
    >
      <font-awesome-icon
        icon="fa-sharp fa-solid fa-list"
        :class="gridProps.row.parcelasEmAtraso ? 'text-danger' : gridProps.row.ultimaParcelaPaga ? 'text-success' :'text-primary'"
      />
    </button>
    <envio-email
      :ref="`modalEnvioEmail`"
      :title="`Enviar ${getTipoParcelamento()} por email ${gridProps.row.razaoSocial}`"
      :sugestao="gridProps.row.emailEmpresa"
      :sugestao-email-adicional="gridProps.row.emailAdicionalEmpresa"
      @enviarEmail="enviarEmail($event)"
    />

    <parcelas
      :ref="`modalParcelas${gridProps.row.id}`"
      title="Parcelas"
      :parcelamento="gridProps.row"
      :request-parcelas="requestParcelas"
      :tipo-parcelamento="tipoParcelamento"
      @envioEmail="openEnvioEmail($event)"
      @mostrarGuia="visualizarGuia($event)"
      @openObservacoes="openModalObservacaoParcela($event)"
    />

    <visualizar-arquivo
      v-if="mostrarArquivo && urlArquivo"
      :url="urlArquivo"
      @hidden="fecharArquivo()"
    />

    <modal-observacao
      :ref="`modalObservacao`"
      v-model="docSelecionado"
      :tipo="tipoParcelamento"
      :api="apiObservacao"
      :notificar="{ sucesso: notficarSucesso, erro: notificarErro }"
      @hidden="fecharObservacao($event)"
    />

    <loading
      :show-cancel-button="false"
      :is-loading="isLoading"
      :descricao="'Buscando'"
    />
  </div>
</template>

<style lang="scss" scoped>
.acoes-usuario {
  margin-right: 0.3rem;
  padding: 0.4rem;
}
.botoes-acao {
  padding: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
}
</style>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import apiParcela from '@/app/parcelamentos/shared/components/parcelas/services/api'
import Vue from 'vue'

export default {
  components: {
    VisualizarArquivo: () => import('@pilar/components/visualizar-arquivo/VisualizarArquivo.vue'),
    ModalObservacao: () => import('@/app/shared/components/modal-observacao/ModalObservacao.vue'),
    EnvioEmail: () => import('@/app/shared/components/envio-email/EnvioEmail.vue'),
    Parcelas: () => import('../parcelas/Parcelas.vue'),
    Loading: () => import('@pilar/components/loading/Loading.vue'),
  },
  props: {
    gridProps: {
      type: Object,
      required: true,
    },
    requestEnviarEmail: {
      type: Function,
      required: true,
    },
    requestParcelas: {
      type: Function,
      required: true,
    },
    requestGuiaParcelamento: {
      type: Function,
      required: true,
    },
    tipoParcelamento: {
      type: String,
      default: '',
    },
    api: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mostrarArquivo: false,
      urlArquivo: '',
      currParcelaId: 0,
      isLoading: false,
      docSelecionado: this.gridProps.row,
      temObservacaoNova: false,
      apiObservacao: this.api,
    }
  },
  computed: {
    disableBySituacaoEParcela() {
      return (
        this.gridProps.row.situacao !== 'Em parcelamento'
        && this.gridProps.row.situacao !== 'Ativo (em dia)'
        && this.gridProps.row.situacao !== 'Deferida e consolidada'
        && this.gridProps.row.situacao !== 'Ativo (em atraso)'
      ) || this.gridProps.row.mesUltimaParcela === '-'
    },
  },
  watch: {
    temObservacaoNova(val, oldVal) {
      if (val !== oldVal) {
        this.val = val
      }
    },
  },
  mounted() {
    this.temObservacaoNova = this.gridProps.row.temObservacoesNaoLidas
  },
  methods: {
    enviarEmail(itens) {
      if (itens.emailsPara.length < 1 && itens.emailsCc < 1) {
        this.notificarErro('Informe ao menos um e-mail')
      } else {
        const parcelamentoId = this.gridProps.row.id
        if (this.currParcelaId) {
          this.$refs[`modalParcelas${this.gridProps.row.id}`].iniciarEspera()
        }

        this.requestEnviarEmail(this.gridProps.row.razaoSocial, itens.emailsPara, itens.emailsCc, parcelamentoId, this.currParcelaId, itens.mensagensAdicional, itens.definirEmailPadroes)
          .then(payload => {
            this.urlArquivo = payload.data
            this.mostrarArquivo = true
            this.$refs[`modalParcelas${parcelamentoId}`].pararEspera()
            this.$sucesso('E-mail enviado', 'success')
          })
          .catch(err => {
            this.$refs[`modalParcelas${parcelamentoId}`].pararEspera()

            if (err.response.data.message.includes('Erro ao enviar email')) {
              this.notificarErro('Não foi possível enviar o email. Verifique as configurações de SMTP e tente novamente.')
            } else if (err.response.data.message.includes('Guia indisponível.')) {
              this.notificarErro('Não foi possível enviar o email. Guia indisponível no momento, tente novamente mais tarde.')
            } else {
              this.notificarErro('Ocorreu um erro interno, favor entrar em contato com o suporte.')
            }

            throw err
          })
        this.currParcelaId = 0
      }
    },
    visualizarGuia(parcelamento) {
      if (parcelamento.situacao === 'Em parcelamento - Aguardando confirmação de remanescentes') {
        Vue.swal({
          title: 'Aguardando confirmação',
          text: 'Acesse o portal do E-cac, para confirmar suas parcelas remanescentes',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'E-cac',
          cancelButtonText: 'OK',
          customClass: {
            cancelButton: 'btn btn-outline-primary',
            confirmButton: 'btn btn-outline-warning mr-50',
          },
          buttonsStyling: false,
        })
          .then(result => {
            if (result.value) {
              window.location.replace('https://cav.receita.fazenda.gov.br/autenticacao/login')
            }
          })
      } else if (parcelamento.nroUltimaParcelaDe && parcelamento.nroUltimaParcelaDe.includes('-')) {
        Vue.swal({
          title: 'Nenhuma guia para visualização.',
          text: 'Nenhuma parcela em aberto ou em atraso para ser possível a geração de guia',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-outline-primary mr-50',
          },
          buttonsStyling: false,
        })
      } else if (parcelamento.ultimaParcelaPaga === true) {
        Vue.swal({
          title: 'Parcela do mês paga',
          text: 'Não há documento para impressão.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-outline-primary mr-50',
          },
          buttonsStyling: false,
        })
      } else {
        this.isLoading = true
        this.requestGuiaParcelamento(parcelamento.id, parcelamento.parcelaId)
          .then(payload => {
            if (payload.data === '') {
              Vue.swal({
                title: 'Parcela paga',
                text: 'Não há documento para impressão.',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-outline-primary mr-50',
                },
                buttonsStyling: false,
              })
            } else {
              this.urlArquivo = payload.data
              this.mostrarArquivo = true
              this.$refs[`modalParcelas${parcelamento.id}`].carregarParcelas()
            }
            this.$refs[`modalParcelas${parcelamento.id}`].pararEspera()
            this.isLoading = false
          })
          .catch(err => {
            this.isLoading = false
            this.fecharArquivo()
            this.$refs[`modalParcelas${parcelamento.id}`].pararEspera()
            this.notificarErro(err.response.data.message)
            throw err
          })
      }
    },
    fecharArquivo() {
      this.mostrarArquivo = false
      this.urlArquivo = ''
    },
    openEnvioEmail(parcelamento) {
      if (parcelamento.nroUltimaParcelaDe && parcelamento.nroUltimaParcelaDe.includes('-')) {
        Vue.swal({
          title: 'Não há documento para o envio!',
          text: 'Não foram encontradas parcelas em aberto para o contribuinte.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-outline-primary mr-50',
          },
          buttonsStyling: false,
        })
      } else {
        if (parcelamento.parcelaId) {
          this.currParcelaId = parcelamento.parcelaId
        }
        this.$refs.modalEnvioEmail.$refs.modalEnviarEmail.show()
      }
    },
    openModalParcelas(id, nroUltimaParcelaDe) {
      if (!nroUltimaParcelaDe || nroUltimaParcelaDe.includes('-')) {
        Vue.swal({
          title: 'Não há guia para impressão!',
          text: 'Não foram encontradas parcelas em aberto para o contribuinte.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-outline-primary mr-50',
          },
          buttonsStyling: false,
        })
      } else {
        this.$refs[`modalParcelas${id}`].show()
      }
    },
    notficarSucesso(msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: msg,
          icon: 'fa-solid fa-circle-check',
          variant: 'success',
        },
      })
    },
    notificarErro(msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: msg,
          icon: 'fa-solid fa-circle-exclamation',
          variant: 'danger',
        },
      })
    },
    openModalObservacao(documento) {
      this.docSelecionado = documento
      this.$nextTick(() => {
        this.$refs.modalObservacao.show()
      })
    },
    openModalObservacaoParcela(documento) {
      this.$refs[`modalParcelas${this.gridProps.row.id}`].hide()
      this.apiObservacao = apiParcela(this.tipoParcelamento)
      const document = documento
      document.title = `${this.gridProps.row.identificacao ? this.gridProps.row.identificacao : 'Parcela'} ${documento.numeroParcela} - ${this.gridProps.row.razaoSocial}`
      this.$refs[`modalParcelas${this.gridProps.row.id}`].stacking = false
      this.openModalObservacao(document)
    },
    fecharObservacao(observacao) {
      if (observacao.value.numeroParcela) {
        const index = this.$refs[`modalParcelas${this.gridProps.row.id}`].parcelas.findIndex(where => where.id === observacao.value.id)
        this.$refs[`modalParcelas${this.gridProps.row.id}`].parcelas[index].temObservacoesNaoLidas = (observacao.hasObservacaoNova ? observacao.hasObservacaoNova : false)
      } else {
        this.temObservacaoNova = observacao.hasObservacaoNova
      }
      this.$set(this.docSelecionado, null)
      this.gridProps.row.observacao = ''
      this.apiObservacao = this.api
      this.$refs[`modalParcelas${this.gridProps.row.id}`].stacking = true
    },
    getTipoParcelamento() {
      return this.tipoParcelamento === 'pert' || this.tipoParcelamento === 'relp' ? `${this.tipoParcelamento.toUpperCase()}-SN` : this.tipoParcelamento.toUpperCase().replace('_', ' ')
    },
  },
}
</script>
