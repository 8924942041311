<template>
  <b-card>
    <b-row
      align-h="end"
      class="mr-0"
      style="gap: 1rem;"
    >
      <download-em-excel
        :filtro="filtroAtual"
        tipo="CNDs RFB PGFN"
        :api="apiRfbPgfn"
      />
      <download-em-lote
        tipo-arquivo="todas as CND's RFB/PGFN"
        tipo="Cnd RFB"
        :filtro="filtroAtual.filtroBy"
        :palavras-filtro="[['Regulares', 'Irregulares', 'Motivo']]"
        @baixar-lote="baixarEmLote"
      />
    </b-row>

    <cnd-federal-list
      ref="cndFederalList"
      :botoes-opcoes="botoesOption"
      :visualizacao-opcoes="visualizacao"
      :filtros="filtros"
      :filtros-rapidos="filtrosRapidos"
    >
      <template
        slot="custom"
        slot-scope="{ props} "
      >
        <cnd-situacao
          v-if="props.column.field === 'situacao'"
          :value="props.row[props.column.field]"
          :descricao="props.row['situacaoDesc']"
        />
        <span
          v-if="props.column.field === 'razaoSocial'"
          @click.stop="$refs[`acoes${props.row.id}`].abrirArquivo(props.row)"
        >
          {{ props.formattedRow[props.column.field] }}
        </span>
        <div
          class="d-flex"
          style="justify-content: space-between;"
        >
          <div class="d-inline-block">
            <b-badge
              v-if="props.column.field === 'statusSituacaoFiscal' && props.row[props.column.field] == 'Regular'"
              variant="light-success"
              class="badge-size-p"
            >
              Regular
            </b-badge>
            <b-badge
              v-else-if="props.column.field === 'statusSituacaoFiscal' && props.row[props.column.field] == 'Irregular'"
              variant="light-danger"
              class="badge-size-p"
            >
              Irregular
            </b-badge>
            <b-badge
              v-else-if="props.column.field === 'statusSituacaoFiscal' && props.row[props.column.field] == 'Procuracao'"
              variant="light-warning"
              class="badge-size-p"
            >
              Procuracao
            </b-badge>
            <b-badge
              v-else-if="props.column.field === 'statusSituacaoFiscal'"
              variant="light-primary"
              class="badge-size-p"
            >
              Instabilidade
            </b-badge>
          </div>
          <b-btn
            v-if="props.column.field === 'statusSituacaoFiscal' && props.row[props.column.field] == 'Irregular'"
            :ref="`acoes${props.row.id}`"
            v-b-tooltip.hover
            title="Pendências"
            variant="none"
            class="btn btn-none rounded-pill m-0 icone-acao p-0"
            @click.stop="abrirClick(props)"
          >
            <p>
              <feather-icon
                icon="InfoIcon"
                class="text-danger"
                size="16"
              />
            </p>
          </b-btn>
          <b-btn
            v-else-if="props.column.field === 'statusSituacaoFiscal' && props.row[props.column.field] == 'Regular'"
            v-b-tooltip.hover
            title="PDF"
            variant="none"
            class="btn btn-none rounded-pill m-0 icone-acao p-0"
            @click.stop="abrirClick(props)"
          ><p>
            <feather-icon
              icon="InfoIcon"
              class="text-success"
              size="16"
            /></p>
          </b-btn>
        </div>
      </template>
      <template
        slot="acoes"
        slot-scope="{ props} "
      >
        <cnd-acoes
          :ref="`acoes${props.row.id}`"
          :grid-props="props"
          :api="apiRfbPgfn"
          :tipo-cnd="tipoCnd"
          :att-manual="true"
          @atualizar-grid="atualizarGrid"
        >
          <template v-slot:acao-custom>
            <b-btn
              v-if="props.row.statusSituacaoFiscal == 'Regular'"
              v-b-tooltip.hover
              :title="'Situação fiscal regular. Consultado em ' + props.row.situacaoFiscalConsultadoEm + '.'"
              variant="none"
              class="btn-none m-0 icone-acao p-0"
              @click.stop="abrirSituacaoFiscal(props)"
            >
              <icone-letra
                letra="SF"
                :class="'text-success'"
                :is-duas-letras="true"
              />
            </b-btn>
            <b-btn
              v-else-if="props.row.statusSituacaoFiscal == 'Irregular'"
              v-b-tooltip.hover
              :title="'Situação fiscal irregular. Consultado em ' + props.row.situacaoFiscalConsultadoEm + '.'"
              variant="none"
              class="btn-none m-0 icone-acao p-0"
              @click.stop="abrirSituacaoFiscal(props)"
            >
              <icone-letra
                letra="SF"
                :class="'text-danger'"
                :is-duas-letras="true"
              />
            </b-btn>
            <b-btn
              v-else-if="props.row.statusSituacaoFiscal == 'Procuracao' || props.row.statusSituacaoFiscal == 'Instabilidade'"
              v-b-tooltip.hover
              :title="'Situação fiscal instabilidade ou procuração. Não foi possível acessar a situação fiscal. Consultado em ' + props.row.situacaoFiscalConsultadoEm + '.'"
              variant="none"
              class="btn-none m-0 icone-acao p-0"
            >
              <icone-letra
                letra="SF"
                :is-duas-letras="true"
              />
            </b-btn>
            <b-btn
              v-else
              v-b-tooltip.hover
              :title="'Sem situação fiscal.'"
              variant="none"
              class="btn-none m-0 icone-acao p-0"
            >
              <icone-letra
                letra="SF"
                :is-duas-letras="true"
              />
            </b-btn>
            <b-btn
              v-b-tooltip.hover
              variant="none"
              title="Histórico de CNDs RFB/PGFN"
              class="btn-none icone-acao m-0 p-0"
              @click="openModalHistorico(props)"
            >
              <font-awesome-icon
                icon="fa-sharp fa-solid fa-list"
                class="text-primary"
              />
              <modal-cnd-rfb-pgfn
                :ref="`modalHistorico${props.row.id}`"
                rotina="CND"
                sub-rotinas="RFB/PGFN"
                des-de="Março de 2024"
                :filtros-rapidos="filtrosRapidos"
                :empresa="props.row"
                :api="apiRfbPgfn"
                tipo="rfb"
                :coluna-historico="colunaHistorico"
                :filtro-by-historico="['DataValidade', 'UltimaConsulta']"
              />
            </b-btn>
          </template>
          <template v-slot:cabecalho="slotProps">
            <div class="cabecalho pt-1 pr-1 pl-1 bg-white rounded-top">
              <h5>Resultado da Consulta - {{ slotProps.empresaNome }}</h5>
            </div>
          </template>

          <template v-slot:rodape="slotProps">
            <div class="w-100 p-2 bg-white rounded-bottom position-absolute link-sf">
              <div>        <p
                class="float-left"
                style="max-width: 90%;"
              >
                <feather-icon
                  icon="AlertCircleIcon"
                  size="16"
                  :class="'text-warning'"
                />  Para visualizar o relatório completo da sua situação fiscal disponível no sistema
                <a
                  class="text-primary"
                  @click="slotProps.abrirArquivo(slotProps.gridRow)"
                >
                  Clique aqui
                </a>
              </p>
              </div>
              <b-button
                variant="primary"
                size="sm"
                class="float-right"
                @click="slotProps.fechar"
              >
                OK
              </b-button>
            </div>
          </template>
        </cnd-acoes>
      </template>
    </cnd-federal-list>
  </b-card>
</template>

<style lang="scss">
.modal-lg {
  max-width: 1000px !important;
}
.link-sf {
    border-top: 1px solid rgba(34, 41, 47, 0.05);
  }
</style>

<script>

import colunas from './shared/components/colunas'
import api from './shared/services/api'
import colunasHistorico from './shared/components/historico'

export default {
  name: 'CndFederalVisualizacao',
  components: {
    CndFederalList: () => import('@pilar/components/visualizacao-base/VisualizacaoBase.vue'),
    CndAcoes: () => import('@/app/shared/components/cnd-acoes/CndAcoes.vue'),
    CndSituacao: () => import('@/app/shared/components/cnd-situacao/CndSituacao.vue'),
    DownloadEmLote: () => import('@app/download-lote/DownloadEmLote.vue'),
    IconeLetra: () => import('@pilar/components/IconeLetra/IconeLetra.vue'),
    DownloadEmExcel: () => import('@app/download-excel/DownloadEmExcel.vue'),
    ModalCndRfbPgfn: () => import('@/app/shared/components/historico/ModalHistorico.vue'),
  },
  data() {
    return {
      apiRfbPgfn: api,
      tipoCnd: 'rfb',
      botoesOption: null,
      linha: null,
      visualizacao: {
        acaoClass: 'col-td-acao-4',
        placeholderBusca: 'Buscar por: CNPJ/CPF, Razão social, Consultado em, Data de validade e próxima consulta',
        disabledCheckbox: true,
      },
      filtros: [{ colunas: ['CadastroFederalSemMascara', 'CadastroFederal', 'RazaoSocial', 'UltimaConsulta', 'DataValidade', 'ProximaConsulta'], valor: null }],
      filtrosRapidos: [
        {
          id: 'Regular',
          colunas: ['Situacao'],
          label: 'Regulares',
          valor: '1,2',
        },
        {
          id: 'Irregular',
          colunas: ['Situacao'],
          label: 'Irregulares',
          valor: '3',
        },
        {
          id: 'Motivo',
          colunas: ['Situacao'],
          label: 'Motivo não geração',
          valor: '4',
        }],
      criando: true,
      aplicando: false,
      filtroAtual: {},
      mostrarHistorico: false,
      empresaSelecionada: {
        emailEmpresa: [],
        emailAdicionalEmpresa: [],
      },
      colunaHistorico: colunasHistorico,
    }
  },
  created() {
    this.visualizacao.colunas = colunas
    this.visualizacao.evento = this.carregar
  },
  mounted() {
    this.aplicarFiltroQuery()
  },
  updated() {
    this.aplicarFiltroQuery()
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    carregar(pagina, tamanhoPagina, filtro) {
      this.filtroAtual = filtro
      return api.getPaginado(this.tipoCnd, pagina, tamanhoPagina, filtro)
    },
    aplicarFiltroQuery() {
      this.$nextTick(() => {
        const params = this.$route.query
        if (params && this.$refs.cndFederalList) {
          const filtroRapido = params.filtroRapido
            ? this.filtrosRapidos.find(f => f.id === params.filtroRapido)
            : null
          this.$refs.cndFederalList.aplicarFiltro(params.busca, filtroRapido)
        }
      })
    },
    baixarEmLote(evt) {
      api.baixaEmLote(this.tipoCnd, this.filtroAtual, evt)
    },
    abrirClick(props) {
      if (props.row[props.column.field] === 'Irregular') {
        this.$refs[`acoes${props.row.id}`].abrirPendencias(props.row)
      } else if (props.row[props.column.field] === 'Regular') {
        this.$refs[`acoes${props.row.id}`].abrirArquivoFiscal(props.row)
      } else if (props.row[props.column.field] === 'SemSituacao') {
        this.$refs[`acoes${props.row.id}`].abrirArquivoFiscal(props.row)
      }
    },
    abrirSituacaoFiscal(props) {
      if (props.row.statusSituacaoFiscal === 'Irregular') {
        this.$refs[`acoes${props.row.id}`].abrirPendencias(props.row)
      } else if (props.row.statusSituacaoFiscal === 'Regular') {
        this.$refs[`acoes${props.row.id}`].abrirArquivoFiscal(props.row)
      } else if (props.row.statusSituacaoFiscal === 'SemSituacao') {
        this.$refs[`acoes${props.row.id}`].abrirArquivoFiscal(props.row)
      }
    },
    atualizarGrid() {
      this.$refs.cndFederalList.buscar()
    },
    openModalHistorico(props) {
      this.$refs[`modalHistorico${props.row.id}`].abrirModal()
    },
  },
}
</script>
