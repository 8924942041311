<template>
  <div>
    <b-modal
      id="declaracoesList"
      ref="declaracoesList"
      button-size="sm"
      no-close-on-backdrop
      ok-only
      ok-title="Ok"
      size="lg"
      :no-stacking="noStacking"
      :no-enforce-focus="true"
    >
      <div slot="modal-title">
        <h4>{{ 'Declarações - ' + declaracao.razaoSocial }}</h4>
        <fieldset class="font-weight-bold text-muted small">
          Nesta tela estão todas as Declarações DCTFWeb da empresa, você pode mandar as guias por email ou baixar
        </fieldset>
      </div>
      <h5
        style=""
      >
        Histórico
      </h5>
      <b-row>
        <b-col>
          <tabela-repeticao
            v-model="dctfsWeb"
            :colunas="colunas"
            :somente-visualizacao="false"
            :customizar-botoes="true"
          >

            <template #visualizacao="{slotScope: {key, valor}}">
              <b-badge
                v-if="key === 'situacao'"
                :variant="getColorTag(valor)"
              >
                {{ valor }}
              </b-badge>
              <span
                v-else
                class="ml-50"
              >
                {{ valor }}
              </span>
            </template>
            <template #botoes-customizados="{slotScope: {item}}">
              <span
                v-b-tooltip.hover
                :title="item.temDarf ? 'Gerar DARF' : 'Guia indisponível no momento'"
              >
                <b-btn
                  variant="none"
                  class="btn-none icone-acao m-0 p-0"
                  :disabled="!item.temDarf"
                  @click="visualizarGuia(item, true)"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-barcode"
                    class="text-primary"
                  />
                </b-btn>
              </span>
              <b-btn
                v-b-tooltip.hover
                title="Enviar por e-mail"
                variant="none"
                class="btn-none icone-acao m-0 p-0"
                @click="openEnvioEmail(item)"
              >
                <feather-icon
                  icon="SendIcon"
                  class="text-primary"
                  size="16"
                />
              </b-btn>
              <span
                v-b-tooltip.hover
                :title="item.temDarf ? 'Visualizar recibo' : 'Recibo indisponível no momento'"
              >
                <b-btn
                  v-b-tooltip.hover
                  variant="none"
                  title="Visualizar recibo"
                  class="btn-none icone-acao m-0 p-0"
                  @click="visualizarGuia(item, false)"
                >
                  <font-awesome-icon
                    icon="fa-sharp fa-regular fa-clipboard"
                    class="text-primary"
                  />
                </b-btn>
              </span>
              <span style="margin-inline-start: .1rem;">
                <atualizacao-manual
                  tipo-rotina="DCTFWeb"
                  :enviar-atualizacao="atualizarManual"
                  :info-extra="item.periodoApuracao"
                  @stacking="atualizarStacking($event)"
                />
              </span>
            </template>
          </tabela-repeticao>
        </b-col>
      </b-row>
    </b-modal>
    <loading
      :show-cancel-button="false"
      :is-loading="isLoading"
      :descricao="'Buscando'"
    />
  </div>
</template>

<style lang="scss">
.modal:nth-of-type(even) {
    z-index: 1052 !important;
}
.modal-backdrop.show:nth-of-type(even) {
    z-index: 1051 !important;
}
</style>

<script>
import api from '../../services/api'

export default {
  name: 'DctfWebEmpresaView',
  components: {
    TabelaRepeticao: () => import('@pilar/components/tabela-repeticao/TabelaRepeticao.vue'),
    Loading: () => import('@pilar/components/loading/Loading.vue'),
    AtualizacaoManual: () => import('@/app/shared/components/botoes-inline/atualizacao-manual/AtualizacaoManual.vue'),
  },
  props: {
    declaracao: {
      type: Object,
      required: true,
    },
    requestDeclaracao: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      mostrarArquivo: false,
      urlArquivo: '',
      dctfsWeb: [],
      colunas: [
        {
          key: 'periodoApuracao', label: 'Período Apuração',
        },
        {
          key: 'situacao', label: 'Situação',
        },
        {
          key: 'debitoApurado', label: 'Débito Apurado',
        },
        {
          key: 'saldoPagar', label: 'Saldo a Pagar',
        }],
      isLoading: false,
      noStacking: true,
    }
  },
  methods: {
    carregarDeclaracoes() {
      if (this.requestDeclaracao) {
        this.requestDeclaracao(this.declaracao.empresaId)
          .then(payload => {
            this.dctfsWeb = payload.data
          })
          .catch(err => {
            throw err
          })
      }
    },
    show() {
      this.carregarDeclaracoes()
      this.$refs.declaracoesList.show()
    },
    pararEspera() {
      this.isLoading = false
    },
    iniciarEspera() {
      this.isLoading = true
    },
    openEnvioEmail(item) {
      this.$emit('envioEmail', { item })
    },
    visualizarGuia(item, isDarf) {
      this.$emit('mostrarGuia', { item, isDarf })
    },
    getColorTag(situacao) {
      if (situacao === 'EmAndamento') {
        return 'light-primary'
      }
      if (situacao === 'Ativa') {
        return 'light-success'
      }
      if (situacao === 'Retificada') {
        return 'light-warning'
      }
      if (situacao === 'Excluida') {
        return 'light-danger'
      }
      return 'light-secondary'
    },
    atualizarManual(periodoApuracao) {
      return api.atualizarManual({
        empresasId: [this.declaracao.empresaId],
        competencia: periodoApuracao,
      })
    },
    atualizarStacking(stacking) {
      this.noStacking = stacking
    },
  },
}

</script>
