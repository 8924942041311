<template>
  <b-modal
    ref="modalEnviarEmail"
    size="lg"
    no-close-on-backdrop
    :hide-header-close="true"
    @hidden="fecharModal()"
  >
    <template #modal-title>
      <h3 style="word-break: break-all;">
        {{ title }}
      </h3>
    </template>
    <div style="margin-top: 20px;">
      <label for="emails-para">Para</label>
      <span
        v-b-tooltip.hover
        title="O endereço de email padrão vem do cadastro da empresa. Para alterar o email padrão no cadastro acesso o menu Cadastros/Empresas"
        style="margin-left: 3px;"
        class="text-primary"
      >
        <feather-icon
          icon="InfoIcon"
          size="10"
          style="margin-bottom: 1px !important;"
        />
      </span>
      <br>
      <b-form-tags
        v-model="emailPara"
        placeholder=""
        separator=";"
        invalid-tag-text=""
        duplicate-tag-text="Possui e-mails repetidos"
        :tag-validator="validator"
        :add-button-text="'Adicionar'"
        remove-on-delete
        tag-class="tags"
        input-id="emails-para"
      />
    </div>
    <div style="margin-top: 20px;">
      <label for="emails-cc">Emails Cópia</label>
      <b-form-tags
        v-model="emailCc"
        placeholder=""
        separator=";"
        invalid-tag-text="E-mail inválido"
        duplicate-tag-text="Possui e-mails repitidos"
        :tag-validator="validator"
        :add-button-text="'Adicionar'"
        remove-on-delete
        tag-class="tags"
        input-id="emails-cc"
        @input="verificacaoComTags"
      />
    </div>
    <div
      style="margin-top: 3px; margin-left: 0%;"
      class="small-checkbox"
    >
      <b-form-checkbox
        v-model="definirEmailPadrao"
        switch
        :disabled="emailCc == ''"
      >
        Salvar email cópia ao cadastro da empresa
      </b-form-checkbox>
    </div>
    <div style="margin-top: 20px;">
      <label for="mensagens-adicional">Mensagem adicional</label>
      <b-form-textarea
        v-model="mensagemAdicional"
        style="height: 10rem;"
        input-id="mensagens-adicional"
      />
    </div>

    <template #modal-footer>
      <b-btn
        variant="none"
        size="sm"
        class="btn-outline-success btn-none"
        style="margin-left: 0.3rem;"
        @click="enviarEmail"
      >
        Enviar
      </b-btn>
      <b-btn
        variant="none"
        size="sm"
        class="btn-outline-danger btn-none"
        style="margin-left: 0.3rem;"
        @click="$refs.modalEnviarEmail.hide()"
      >
        Cancelar
      </b-btn>
    </template>
  </b-modal>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Envio de email',
    },
    sugestao: {
      type: Array,
      default: null,
    },
    sugestaoEmailAdicional: {
      type: Array,
      default: null,
    },
    idEntidade: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      emailPara: this.sugestao,
      emailCc: this.sugestaoEmailAdicional,
      mensagemAdicional: '',
      definirEmailPadrao: false,
    }
  },
  watch: {
    sugestao(value, oldValue) {
      if (value !== oldValue) {
        this.emailPara = value
      }
    },
    sugestaoEmailAdicional(value, oldValue) {
      if (value !== oldValue) {
        this.emailCc = value
      }
    },
  },
  methods: {
    verificacaoComTags(tags) {
      if (tags.length === 0) {
        this.definirEmailPadrao = false
      }
    },
    enviarEmail() {
      const itens = {
        emailsPara: this.emailPara,
        emailsCc: this.emailCc,
        mensagensAdicional: this.mensagemAdicional,
        definirEmailPadroes: this.definirEmailPadrao,
      }
      if (this.idEntidade > 0) {
        this.$emit('enviarEmail', { id: this.idEntidade, itensEmail: itens })
      } else {
        this.$emit('enviarEmail', itens)
      }
      this.$refs.modalEnviarEmail.hide()
    },
    validator(tag) {
      const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(tag)
    },
    abrirModal() {
      this.$refs.modalEnviarEmail.show()
    },
    fecharModal() {
      this.$emit('hide')
    },
  },
}
</script>
