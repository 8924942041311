<template class="componenteCertificadoCadastro">
  <cadastro-base
    :salvar="salvar"
    :cancelar="cancelar"
    :is-loading="isLoading"
  >
    <template slot="conteudo">
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Certificado Digital A1"
            label-for="certificadoDigitalInput"
          >
            <botao-upload
              id="btnUpload"
              :ref="`uploadEmpresas`"
              v-model="value.arquivo"
              :extensions="'.pfx'"
              :upload-auto="false"
              :options="uploadOpt"
              @removed="removerArquivo()"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Senha"
            label-for="SenhaInput"
          >
            <senha-input
              id="id"
              v-model="value.senha"
              :mostrar-senha="true"
              :validacao-direita="true"
              auto-complete-senha="new-password"
              required
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="6"
        >
          <b-form-checkbox
            v-model="value.porProcuracao"
            style="margin-top: -15px;"
            value="true"
            unchecked-value="false"
          >
            Por Procuração
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row
        v-if="value.habilitarCampos"
        style="margin-top: 1rem;"
      >
        <b-col cols="1">
          <b-form-group
            label-for="tipoInscricaoInput"
            label="Tipo inscrição"
          >
            <b-select
              id="tipoInscricaoInput"
              ref="tipoInscricao"
              v-model="value.cadastroFederalTipo"
              :options="[{text:'CNPJ', value:'cnpj'},{value:'cpf',text:'CPF'}]"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group>
            <cadastro-federal-input
              ref="cadastroFederal"
              v-model="value.cadastroFederal"
              class="mb-50"
              :is-to-buscar-dados="false"
              :is-cnpj="value.cadastroFederalTipo === 'cnpj'"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="value.cadastroFederalTipo !== 'cnpj'"
          cols="3"
        >
          <b-form-group
            label="Razão Social"
            label-for="razaoSocialInput"
          >
            <b-input
              id="razaoSocialInput"
              v-model="value.razaoSocial"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row style="margin-top: 10px !important;">
        <b-col>
          <vinculo-empresas
            v-model="value.empresasVinculadas"
            :cadastro-usuario="false"
            :visualizacao-opcoes="visualizacao"
            :filtros-vinculadas="filtrosVinculadas"
            :filtros-nao-vinculadas="filtrosNaoVinculadas"
            :filtros-rapidos="filtrosComponente"
            :tem-filtro-rapido="true"
            :tem-busca="true"
            @eventClickCustom="clickCustomEmpresas($event)"
          />
        </b-col>
      </b-row>
    </template>
  </cadastro-base>
</template>

<style lang="scss">
.componenteCertificadoCadastro {
  .swal-m {
    width: 35%;
  }
  .vinculo-empresas {
    margin-top: 100px;
  }
  .row mt-4 {
    margin-top: 0px !important;
  }
}
</style>

<script>

import api from '@/app/certificado-digital/shared/services/api'
import {
  defineComponent, ref,
} from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import { required } from '@validations'
import EventBus from '@app/shared/mixins/event-bus/eventBus'
import { ValidationProvider } from 'vee-validate'
import useCertificadoDigital from '@app/certificado-digital/shared/components/cadastro/useCertificadoDigital'

export default defineComponent({
  name: 'CertificadoDigital',
  components: {
    ValidationProvider,
    CadastroBase: () => import('@pilar/components/cadastro-base/CadastroBase.vue'),
    SenhaInput: () => import('@/app/shared/components/senha-input/SenhaInput.vue'),
    BotaoUpload: () => import('@pilar/components/botao-upload/BotaoUpload.vue'),
    VinculoEmpresas: () => import('@pilar/components/vinculo-empresas/VinculoEmpresas.vue'),
    CadastroFederalInput: () => import('@pilar/components/cadastro-federal-input/CadastroFederalInput.vue'),
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      visualizacao: {
        colunasAdicionais: [{ label: 'Fim da vigência', field: 'fimVigencia', tdClass: 'col-tamanho-data-2' },
          {
            label: '', field: 'responsavelLegal', icon: 'fa-solid fa-user-tie', colorIcon: this.corIcone, eventClickCustom: true, ocultarNaoVinculados: true,
          },
        ],
        placeholderBusca: 'Buscar por palavra chave e CNPJ/CPF',
        evento(id, filtro) {
          return api.getEmpresasPorCertificado(id, filtro)
        },
      },
      filtrosVinculadas: [{ colunas: ['CadastroFederalSemMascara', 'CadastroFederal', 'RazaoSocial', 'FimVigencia'], valor: null }],
      filtrosNaoVinculadas: [{ colunas: ['CadastroFederalSemMascara', 'CadastroFederal', 'RazaoSocial', 'FimVigencia'], valor: null }],
      filtrosComponente: [
        {
          id: 'ComCertificadoComponente',
          colunas: ['ComCertificado'],
          label: 'Com certificado',
          valor: this.value.id,
        },
        {
          id: 'SemCertificadoComponente',
          colunas: ['SemCertificado'],
          label: 'Sem certificado / procuração',
          valor: this.value.id,
        },
      ],
      isLoading: true,
    }
  },
  setup() {
    const { route } = useRouter()
    const modoEdicao = route.value.path.indexOf('editar') > 0
    const {
      carregar,
      carregarEmpresasParaVinculo,
    } = useCertificadoDigital(modoEdicao)

    if (modoEdicao) {
      carregar(route.value.params.id, route.value.params.filtro)
    } else {
      carregarEmpresasParaVinculo()
    }
    const corIcone = payload => {
      if (payload) {
        return 'text-success'
      }
      return 'text-muted'
    }
    const uploadOpt = ref(null)

    return {
      corIcone,
      carregar,
      uploadOpt,
    }
  },
  created() {
    EventBus.$on('finalizaLoading', () => this.atualizaLoading(false))
    EventBus.$on('inicioLoading', () => this.atualizaLoading(true))
  },
  methods: {
    removerArquivo() {
      this.$emit('remover-arquivo')
    },
    salvar() {
      this.$emit('salvar')
    },
    cancelar() {
      this.$emit('cancelar')
    },
    clickCustomEmpresas(event) {
      this.$emit('click-custom-empresas', event)
    },
    atualizaLoading(status) {
      this.isLoading = status
    },
  },
})
</script>
