<template>
  <div class="esfera">
    <validation-observer ref="observer">
      <b-row align-v="center">
        <b-col cols="auto">
          <b-form-group
            label="Esfera"
          >
            <b-select
              v-model="value.tipoEsfera"
              :options="[{text:'Federal',value:'federal'},{text:'Estadual',value:'estadual'},{text:'Municipal',value:'municipal'}]"
              :disabled="!vaiEditar"
              class="w-100"
              style="width: 32rem !important"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="auto"
        >
          <b-btn
            v-b-tooltip.hover
            :title="vaiEditar ? 'Salvar' : 'Editar'"
            :variant="vaiEditar ? 'outline-success' : 'none'"
            class="btn-none rounded-pill icone-acao"
            :class="vaiEditar ? 'btn-outline-success' : ''"
            style="margin-top: 0.8rem; "
            @click="salvarEsfera() "
          >
            <feather-icon
              :icon="vaiEditar ? 'CheckIcon' : 'Edit2Icon'"
              :class="vaiEditar ? '' : 'text-success'"
            />
          </b-btn>
        </b-col>
        <b-col cols="auto">
          <b-btn
            v-b-tooltip.hover
            title="Remover"
            variant="none"
            style="margin-top: 0.8rem;"
            class="btn-outline-danger btn-none rounded-pill icone-acao"
            @click="removerEsfera()"
          >
            <feather-icon
              icon="TrashIcon"
            />
          </b-btn>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="auto">
          <b-form-group
            label="Login/Código de acesso"
            style="width: 15rem !important"
          >
            <validation-provider
              #default="{ errors }"
              name="Login de contato"
              :rules="value.senha !== '' ? 'required' : ''"
            >
              <b-input
                v-model="value.email"
                :state="errors.length > 0 ? false : null"
                :disabled="!vaiEditar"
                name="login"
                placeholder="meuacesso@aqui.com.br"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="auto">
          <b-form-group
            label="Senha"
            :disabled="!vaiEditar"
            class="col-nowrap"
            style="width: 15rem !important;"
          >
            <senha-input
              id="id"
              ref="senhaInputRef"
              :required="!temCertificado || value.email !== ''"
              :mostrar-senha="vaiEditar ? true : false"
              :validacao-direita="true"
              auto-complete-senha="new-password"
              :value="vaiEditar ? value.senha : '****'"
              placeholder="••••"
              @input="vaiEditar ? $emit('input', { ...value, senha: $event }) : null"
            />
          </b-form-group>
        </b-col>
        <b-col cols="auto">
          <b-form-checkbox
            v-model="value.temCertificado"
            style="margin-top: 2.3rem;"
            :disabled="!vaiEditar"
            @change="mostrarCertificado()"
          >
            Certificado Digital A1
          </b-form-checkbox>
        </b-col>
        <b-col
          v-if="temCertificado"
          cols="4"
        >
          <label>Certificado Digital A1</label>
          <validation-provider
            #default="{ errors }"
            name="Certificado de contato"
            :rules="temCertificado ? 'required' : ''"
          >
            <select-base
              v-model="value.certificadoDigital"
              :state="errors.length > 0 ? false : null"
              :label-select="'nome'"
              :carregar="carregarCertificados"
              :required="temCertificado"
              :multiple="multiple"
              :disabled="!vaiEditar"
              :style="vaiEditar ? 'background-color: white' : ''"
            />
            <small
              class="text-danger"
            >
              {{ errors[0] }}
            </small>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<style lang="scss">
.esfera {
  small.text-danger {
  float: left !important;
  }

.icone-acao {
  white-space: nowrap;
  }
}
</style>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import '@core/scss/vue/libs/vue-select.scss'

import apiCertificado from '@/app/certificado-digital/shared/services/api'

export default {
  name: 'EsferaSenhas',
  components: {
    ValidationProvider,
    ValidationObserver,
    SenhaInput: () => import('@/app/shared/components/senha-input/SenhaInput.vue'),
    SelectBase: () => import('@app/shared/components/select-base/SelectBase.vue'),
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      temCertificado: this.value.temCertificado || false,
      show: false,
    }
  },
  computed: {
    vaiEditar: {
      get() {
        return this.value.edicao
      },
      set(val) {
        this.$emit('input', { ...this.value, edicao: val })
      },
    },
    senhaFormatada() {
      return this.vaiEditar ? this.value.senha : '****'
    },
  },
  created() {
    this.mostrarCertificado()
    this.value.senha = ''
  },
  methods: {
    carregarCertificados(filtros) {
      return apiCertificado.getCertificadosResumidosValidos(filtros)
    },
    salvarEsfera() {
      if (this.vaiEditar) {
        // eslint-disable-next-line no-unused-vars
        const isValid = this.$refs.observer.validate()
        if (this.value.temCertificado && !this.value.certificadoDigital) {
          this.$erro('Erro ao gravar alterações', 'danger')
        } else if (!this.value.senha && this.value.email) {
          this.$erro('Erro ao gravar alterações', 'danger')
        } else if (this.value.senha && !this.value.email) {
          this.$erro('Erro ao gravar alterações', 'danger')
        } else if (!this.value.senha && !this.value.certificadoDigital) {
          this.$erro('Erro ao gravar alterações', 'danger')
        } else {
          if (!this.value.temCertificado && this.value.certificadoDigital) {
            this.value.certificadoDigital = null
          }
          this.value.tipoEsferaExibicao = this.value.tipoEsfera
          this.vaiEditar = false
          this.$emit('salvar-backup')
        }
      } else {
        this.vaiEditar = true
      }
    },
    mostrarCertificado() {
      this.temCertificado = this.value.temCertificado
      if (!this.value.temCertificado && this.value.certificadoDigital) {
        this.value.certificadoDigital = null
      }
    },
    removerEsfera() {
      this.$emit('remover-esfera')
    },
  },
}
</script>
