<template>
  <b-card>
    <empresas-list
      ref="empresasList"
      :botoes-opcoes="botoesOption"
      :visualizacao-opcoes="visualizacao"
      :filtros="filtros"
      :filtros-rapidos="filtrosRapidos"
      @suporte="setSuporte($event)"
    >
      <template slot="botoes-adicionais">
        <b-btn
          v-b-tooltip.hover
          variant="none"
          title="O download será feito conforme os filtro atualmente selecionado"
          class="btn btn-outline-primary mr-1"
          size="sm"
          :filtro="filtroAtual.filtroBy"
          @click="baixarEmExcel"
        >
          <font-awesome-icon icon="fa-solid fa-file-export" />
          Exportar
        </b-btn>
      </template>
      <template slot="botoes-adicionais">
        <b-btn
          v-b-tooltip.hover
          :title="botoesOption.NotificarLimite ? botoesOption.LimiteMensagem : ''"
          variant="none"
          class="btn btn-outline-primary mr-1"
          size="sm"
          @click="abrirImportacao"
        >
          <font-awesome-icon icon="fa-solid fa-file-import" />
          Importar empresas
        </b-btn>
        <b-btn
          v-if="suporte"
          variant="none"
          class="btn btn-outline-danger mr-1"
          size="sm"
          @click="abrirExclusaoEmpresas"
        >
          <font-awesome-icon icon="fa-solid fa-file-import" />
          Deletar empresas
        </b-btn>
      </template>
      <template
        slot="custom"
        slot-scope="{ props} "
      >
        <div class="d-block">
          <span
            v-if="props.column.field === 'certificadoVinculado'"
          >
            {{ props.row[props.column.field] && props.row[props.column.field].nomeFormatado }}
          </span>
          <span
            v-else
          >
            {{ props.row[props.column.field] }}
            <span
              v-if="props.row['emailConfirmado'] == 'aguardando'"
              class="badge outline-warning"
            > Aguardando</span>
            <span
              v-if="props.row['emailConfirmado'] == 'confirmado'"
              class="badge outline-success"
            > Confirmado</span>
          </span>
        </div>
      </template>

      <template
        slot="acoes"
        slot-scope="{ props} "
      >
        <b-btn
          v-b-tooltip.hover
          :title="props.row.favorita ? 'Remover dos favoritos' : 'Favoritar'"
          variant="none"
          class="btn-none rounded-pill text icone-acao pr-0"
          @click="favoritarEmpresa(props.row)"
        >
          <feather-icon
            icon="StarIcon"
            size="22"
            :style="props.row.favorita ? 'color: #ff9f43' : 'color: #b8c2cc'"
          />
        </b-btn>
        <b-btn
          v-b-tooltip.hover
          title="Configuração rotinas e-Cac"
          variant="none"
          class="btn-none rounded-pill text-primary icone-acao"
          @click="abrirModalConfiguracoes(props.row)"
        >
          <feather-icon
            icon="SettingsIcon"
            size="22"
          />
        </b-btn>
        <b-btn
          :id="`botao-vinculo${props.row.id}`"
          v-b-tooltip.hover
          variant="none"
          class="btn rounded-pill icone-acao p-0"
          style="margin-right:0.3rem"
          :title="getTextByStatus( props.row.certificadoVinculado && props.row.certificadoVinculado.situacao
          )"
          @click="openModalCertificadoVinculado(props.row)"
        >
          <font-awesome-icon
            icon="fa-solid fa-lock"
            class="rounded-pill icone-acao"
            :class="getColorByStatus( props.row.certificadoVinculado && props.row.certificadoVinculado.situacao
            )"
          />
        </b-btn>
      </template>
    </empresas-list>

    <importar-empresas
      ref="impotar-empresas"
      :is-remover="isRemover"
      @atualizacaoGrid="atualizarGrid()"
    />
    <modal-configuracoes
      v-if="mostrarConfiguracoesEcac"
      ref="modalConfiguracoesEcac"
      v-model="empresaSelecionada"
      @hidden="fecharModalConfiguracoes"
    />
    <certificado-vinculado
      v-if="mostrarCertificado"
      ref="modalCertificadoVinculado"
      v-model="empresaSelecionada"
      @hidden="fecharModalCertificado"
      @atualizarGrid="atualizarGrid"
    />
  </b-card>

</template>

<style lang="scss">
  .text-purple {
    color: #64249c;
  }
  .text-purple:hover {
  color: #64249c;
  }
  .text-gray{
    color: gray;
  }
  .text-gray:hover {
  color: gray;
  }
</style>

<script>

import Vue from 'vue'
import colunas from './shared/components/colunas'
import api from './shared/services/api'

export default {
  name: 'EmpresaVisualizacao',
  components: {
    EmpresasList: () => import('@pilar/components/visualizacao-base/VisualizacaoBase.vue'),
    ImportarEmpresas: () => import('./shared/components/importar-empresas/ImportarEmpresas.vue'),
    ModalConfiguracoes: () => import('./shared/components/configuracoes-ecac/ConfiguracoesEcac.vue'),
    CertificadoVinculado: () => import('./shared/components/vinculo-certificado/components/certificado-vinculado/ModalCertificadoVinculado.vue'),
  },
  data() {
    return {
      botoesOption: {
        NotificarLimite: false,
        LimiteMensagem: 'Limite de cadastro do plano atingido. Será enviado um e-mail com as informações do novo cadastro para melhor controle de sua base.',
        novo: {
          evento: this.novo,
        },
        editar: {
          evento: this.editar,
        },
        favoritar: {
          evento: this.favoritar,
        },
        remover: {
          evento: this.remover,
        },
      },
      visualizacao: {
        acaoClass: 'col-td-acao-4',
        placeholderBusca: 'Busca por palavra chave e CNPJ/CPF',
      },
      filtros: [{ colunas: ['CadastroFederalSemMascara', 'CadastroFederal', 'RazaoSocial', 'InscricaoEstadual', 'Municipio', 'SiglaEstado', 'CertificadoVinculado'], valor: null }],
      filtrosRapidos: [
        {
          id: 'ComCertificado',
          colunas: ['ComCertificado'],
          label: 'Com certificado',
          valor: true,
        },
        {
          id: 'SemCertificado',
          colunas: ['SemCertificado'],
          label: 'Sem certificado / procuração',
          valor: true,
        },
        {
          id: 'ProcuracaoVencida',
          colunas: ['ProcuracaoVencida'],
          label: 'Procuração vencida',
          valor: true,
        },
        {
          id: 'ProcuracaoVencida30Dias',
          colunas: ['ProcuracaoVencida30Dias'],
          label: 'Procuração vencendo em 30 dias',
          valor: true,
        },
        {
          id: 'EmpresaInativa',
          colunas: ['EmpresaInativa'],
          label: 'Empresa inativa',
          valor: true,
        },
        {
          id: 'RegimeSN',
          colunas: ['RegimeSN'],
          label: 'Regime Simples Nacional',
          valor: '1',
        },
        {
          id: 'RegimeNormal',
          colunas: ['RegimeNormal'],
          label: 'Regime Normal',
          valor: '2',
        },
        {
          id: 'RegimeMEI',
          colunas: ['RegimeMEI'],
          label: 'Regime MEI',
          valor: '3',
        },
        {
          id: 'Favoritas',
          colunas: ['Favorita'],
          label: 'Favoritas',
          valor: true,
        },
        {
          id: 'NaoFavorita',
          colunas: ['Favorita'],
          label: 'Não Favoritas',
          valor: false,
        },
      ],
      filtroAtual: {},
      empresaSelecionada: {},
      mostrarConfiguracoesEcac: false,
      isRemover: false,
      suporte: false,
      mostrarCertificado: false,
    }
  },
  mounted() {
    this.aplicarFiltroQuery()
  },
  updated() {
    this.aplicarFiltroQuery()
  },
  created() {
    this.verificarLimite()
    this.visualizacao.colunas = colunas
    this.visualizacao.colunas[0].evento = this.editar
    this.visualizacao.evento = this.carregar
  },
  methods: {
    verificarLimite() {
      return new Promise((resolve, reject) => {
        api.verificarLimiteCadastro()
          .then(payload => {
            this.botoesOption.NotificarLimite = payload.data
            resolve(payload.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    carregar(pagina, tamanhoPagina, filtro) {
      this.filtroAtual = filtro
      return api.getPaginado(pagina, tamanhoPagina, filtro)
    },
    novo() {
      this.$router.push({ name: 'empresa-novo' })
    },
    editar(value) {
      this.$router.push({ name: 'empresa-editar', params: { id: value.id } })
    },
    remover(linhas) {
      const msgErro = linhas && linhas.length > 0 ? 'Não foi possível excluir uma ou mais empresas' : `Não foi possível remover a empresa ${linhas[0].nome}`
      const ids = linhas && linhas.length > 0 ? linhas.map(m => m.id) : linhas[0].id
      return new Promise((resolve, reject) => {
        api.removerEmpresa(ids)
          .then(payload => {
            if (payload.data === false) {
              reject(new Error(msgErro))
            }
            resolve(payload.data)
          })
          .catch(err => {
            reject(new Error(msgErro, err))
          })
      })
    },
    favoritar(linhas) {
      const msgErro = linhas && linhas.length > 0 ? 'Não foi possível favoritar uma ou mais empresas' : `Não foi possível favoritar a empresa ${linhas[0].nome}`
      const ids = linhas && linhas.length > 0 ? linhas.map(m => m.id) : linhas[0].id
      return new Promise((resolve, reject) => {
        api.favoritarEmpresas(ids)
          .then(payload => {
            if (payload.data === false) {
              reject(new Error(msgErro))
            }
            resolve(payload.data)
            this.atualizarGrid()
          })
          .catch(err => {
            reject(new Error(msgErro, err))
          })
      })
    },
    favoritarEmpresa(row) {
      api.favoritarEmpresa(row.id, !row.favorita)
        .then(() => this.atualizarGrid())
    },
    openModalCertificadoVinculado(row) {
      api.getEmpresa(row.id)
        .then(payload => {
          this.mostrarCertificado = true
          this.empresaSelecionada = row
          this.empresaSelecionada.configuracao = payload.data
        })
        .catch(err => {
          this.$erro(`Não foi possível abrir o certificado. ${err.response.data.message}`)
          throw err
        })
    },
    fecharModalCertificado() {
      this.mostrarCertificado = false
    },
    abrirModalConfiguracoes(row) {
      api.getConfiguracoesEcacEmpresa(row.id)
        .then(payload => {
          this.mostrarConfiguracoesEcac = true
          this.empresaSelecionada = row
          this.empresaSelecionada.configuracao = payload.data
        })
        .catch(err => {
          this.$erro(`Não foi possível abrir as configurações do e-CAC. ${err.response.data.message}`)
          throw err
        })
    },
    fecharModalConfiguracoes() {
      this.mostrarConfiguracoesEcac = false
    },
    abrirImportacao() {
      this.isRemover = false
      api.novaEmpresa()
        .then(payload => {
          if (payload.data.contador.id !== 0) {
            this.$refs['impotar-empresas'].show()
          } else {
            Vue.swal({
              title: '<h4>Usuário contador não cadastrado!</h4>',
              html: '<div>Faça o cadastro de um usuário contador antes de continuar. <a style="color: #ff9f43; text-decoration: underline;" href="../usuario/visualizar?busca=">Clique aqui</a> para concluir seu cadastro</div>',
              icon: 'warning',
              iconColor: '$warning',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-outline-dark mr-50',
              },
              buttonsStyling: false,
            })
          }
        })
        .catch(err => {
          throw err
        })
    },
    abrirExclusaoEmpresas() {
      this.isRemover = true
      this.$refs['impotar-empresas'].show()
    },
    aplicarFiltroQuery() {
      this.$nextTick(() => {
        const params = this.$route.query
        if (params && this.$refs.empresasList) {
          const filtroRapido = params.filtroRapido
            ? this.filtrosRapidos.find(f => f.id === params.filtroRapido)
            : null
          this.$refs.empresasList.aplicarFiltro(params.busca, filtroRapido)
        }
      })
    },
    atualizarGrid() {
      this.$refs.empresasList.buscar()
    },
    baixarEmExcel(evt) {
      api.baixarEmExcel(this.filtroAtual, evt)
        .then(payload => {
          this.arquivo = payload.data
          window.open(this.arquivo.url)
          api.deletarExcel(this.arquivo.url, this.arquivo.nomeArquivo)
          this.arquivo.url = ''
          this.$sucesso('Exportação feita com sucesso')
        })
        .catch(err => {
          this.$erro(`Não foi possível fazer o download do arquivo. ${err.response.data.message}`)
          throw err
        })
    },
    setSuporte(isSuporte) {
      this.suporte = isSuporte
    },
    getColorByStatus(situacao) {
      if (!situacao) {
        return 'btn btn-gray text-gray'
      }
      if (situacao === 'ComEmpresaVinculada') {
        return 'btn text-success'
      }
      if (situacao === 'CertificadoAVencer' || situacao === 'CpfDiferente' || situacao === 'VerificacaoDuasEtapas') {
        return 'btn text-warning'
      }
      if (situacao === 'SemEmpresaVinculada') {
        return 'btn text-primary'
      }
      if (situacao === 'CertificadoVencido') {
        return 'btn text-danger'
      }
      if (situacao === 'CertificadoRevogado' || situacao === 'AutorizacaoEcac' || situacao === 'CadastroGov' || situacao === 'PendenteDeAprovacao') {
        return 'btn btn-purple text-purple'
      }
      return ''
    },
    getTextByStatus(situacao) {
      if (!situacao) {
        return 'Não possui certificado'
      }
      if (situacao === 'ComEmpresaVinculada') {
        return 'Visualizar empresas vinculadas'
      }
      if (situacao === 'CadastroDeEmpresas') {
        return 'Importar empresas vinculadas ao certificado'
      }
      if (situacao === 'CertificadoAVencer') {
        return 'Certificado próximo do vencimento'
      }
      if (situacao === 'SemEmpresaVinculada') {
        return 'Não há empresas vinculadas'
      }
      if (situacao === 'CertificadoVencido') {
        return 'Certificado vencido'
      }
      if (situacao === 'AutorizacaoEcac') {
        return 'Autorização e-CAC'
      }
      if (situacao === 'CadastroGov') {
        return 'Certificado não esta cadastrado no Gov'
      }
      if (situacao === 'PendenteDeAprovacao') {
        return 'Pendente de aprovação'
      }
      if (situacao === 'CertificadoRevogado') {
        return 'Certificado digital revogado'
      }
      if (situacao === 'VerificacaoDuasEtapas') {
        return 'Verificação em duas etapas'
      }
      if (situacao === 'CpfDiferente') {
        return 'CPF no certificado digital é diferente do CPF na Receita Federal'
      }
      return ''
    },
  },
}
</script>
