<template>
  <b-card>
    <certificados-list
      ref="certificadosList"
      :botoes-opcoes="botoesOption"
      :visualizacao-opcoes="visualizacao"
      :filtros-rapidos="filtrosRapidos"
      :filtros="filtros"
    >
      <template
        slot="custom"
        slot-scope="{ props }"
      >
        <span
          v-if="props.column.field === 'vencimento'"
        >
          <b-badge
            v-if="props.row.situacao === 'CertificadoRevogado'"
            v-b-tooltip.hover
            title="O certificado encontra-se revogado. Faça a troca para continuar tendo acesso as funcionalidades do sistema"
            style="background-color: #c8a8e3; color: #64249c;"
          >
            Certificado Revogado
          </b-badge>
          <b-badge
            v-else-if=" props.row.situacao === 'CpfDiferente'"
            v-b-tooltip.hover
            title="O CPF do responsável contido no certificado digital é diferente do CPF do responsável pela empresa nas bases de dados da Receita Federal do Brasil"
            variant="light-warning"
          >
            Cadastro CPF sócio
          </b-badge>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </span>
      </template>

      <template
        slot="acoes"
        slot-scope="{ props }"
      >
        <span class="acoes-grid">

          <b-btn
            :id="`botao-cadastro${props.row.id}`"
            v-b-tooltip.hover
            variant="none"
            class="btn rounded-pill building-circle-exclamation p-0"
            style="margin-right:0.6rem; margin-left:0.6rem;"
            :disabled="props.row.situacao == 'CertificadoVencido' || props.row.situacao == 'CertificadoRevogado' ? true : false"
            :title="props.row.situacao == 'CertificadoVencido' || props.row.situacao == 'CertificadoRevogado' ? '' : 'Importar empresas vinculadas ao certificado'"
            @click="openModalVincularEmpresasCertificado(props.row)"
          >
            <font-awesome-icon
              icon="fa-building-circle-exclamation"
              class="fa-light building-circle-exclamation text-primary"
            />
          </b-btn>
        </span>
        <cadeado
          :row="props.row"
          @open-vinculo="openModalEmpresasVinculadas"
        />
      </template>

    </certificados-list>

    <template slot="conteudo">
      <b-row>
        <b-col>
          <fieldset class="font-weight-bold text-muted small">
            Selecione as Permissões que deseja para este usuário
          </fieldset>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <hr
            class="mx-0 mt-0 mb-2"
            style="border-top: 1px solid #a3a4a66b !important;"
          >
        </b-col>
      </b-row>
    </template>
    <empresas-vinculadas ref="empresas-vinculadas" />
  </b-card>

</template>

<style lang="scss">
  .text-purple {
    color: #64249c;
  }
  .text-purple:hover {
  color: #64249c;
  }
</style>

<script>

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
import api from '@/app/certificado-digital/shared/services/api'
import colunas from './shared/components/colunas'

export default {
  name: 'CertificadosVisualizacao',
  components: {
    CertificadosList: () => import('@pilar/components/visualizacao-base/VisualizacaoBase.vue'),
    EmpresasVinculadas: () => import('./shared/components/vinculo-empresas/components/empresas-vinculadas/ModalEmpresasVinculadas.vue'),
    Cadeado: () => import('./shared/components/cadeado/Cadeado.vue'),
  },
  data() {
    return {
      botoesOption: {
        novo: {
          evento: this.novo,
        },
        editar: {
          evento: this.editar,
        },
        remover: {
          evento: this.remover,
        },
      },
      visualizacao: {
        acaoClass: 'col-td-acao-4',
        placeholderBusca: 'Buscar por Nome ou Vencimento',
      },
      filtros: [{ colunas: ['Nome', 'Vencimento'], valor: null }],
      filtrosRapidos: [
        {
          id: 'ComProcuracao',
          colunas: ['Procuracao'],
          label: 'Com procuração',
          valor: true,
        },
        {
          id: 'SemProcuracao',
          colunas: ['Procuracao'],
          label: 'Sem procuração',
          valor: false,
        },
        {
          id: 'ComEmpresas',
          colunas: ['TemEmpresas'],
          label: 'Com empresa(s) vinculada(s)',
          valor: true,
        },
        {
          id: 'SemEmpresas',
          colunas: ['SemEmpresas'],
          label: 'Sem empresa(s) vinculada(s)',
          valor: false,
        },
        {
          id: 'Vencidos',
          colunas: ['Vencidos'],
          label: 'Vencidos / revogados / outros',
          valor: false,
        },
        {
          id: 'Vencidos30Dias',
          colunas: ['Vencidos30Dias'],
          label: 'Vencimento nos próximos 30 dias',
          valor: false,
        },
        {
          id: 'NenhumaAcaoRealizada',
          colunas: ['Status'],
          label: 'Nenhuma ação realizada',
          valor: '0',
        },
        {
          id: 'EnviadoSolicitacaoCadastro',
          colunas: ['Status'],
          label: 'Enviado solicitação de cadastro',
          valor: '1',
        },
        {
          id: 'CadastroEmpresasRealizada',
          colunas: ['Status'],
          label: 'Cadastro da empresas realizada',
          valor: '2',
        },
        {
          id: 'ErroCadastroEmpresas',
          colunas: ['Status'],
          label: 'Erro no cadastro das empresas, aguarde',
          valor: '3',
        }],
    }
  },

  mounted() {
    this.aplicarFiltroQuery()
  },
  created() {
    this.visualizacao.colunas = colunas
    this.visualizacao.colunas[0].evento = this.editar
    this.visualizacao.evento = this.carregar
  },
  methods: {
    aplicarFiltroQuery() {
      this.$nextTick(() => {
        const params = this.$route.query
        if (params && this.$refs.certificadosList) {
          const filtroRapido = params.filtroRapido
            ? this.filtrosRapidos.find(f => f.id === params.filtroRapido)
            : null
          this.$refs.certificadosList.aplicarFiltro(params.busca, filtroRapido)
        }
      })
    },
    // eslint-disable-next-line no-unused-vars
    carregar(pagina, tamanhoPagina, filtros) {
      return api.getPaginado(pagina, tamanhoPagina, filtros)
    },
    novo() {
      this.$router.push({ name: 'certificado-novo' })
    },
    editar(value) {
      this.$router.push({ name: 'certificado-editar', params: { id: value.id } })
    },
    remover(linhas) {
      const msgErro = linhas && linhas.length > 0 ? 'Não foi possível excluir um ou mais certificados' : `Não foi possível o certificado ${linhas[0].nome}`
      const ids = linhas && linhas.length > 0 ? linhas.map(m => m.id) : linhas[0].id
      return new Promise((resolve, reject) => {
        api.removerCertificado(ids)
          .then(payload => {
            if (payload.data === false) {
              reject(new Error(msgErro))
            }
            resolve(payload.data)
          })
          .catch(err => {
            reject(new Error(msgErro, err))
          })
      })
    },
    openModalVincularEmpresasCertificado(row) {
      Vue.swal({
        title: 'Importar empresas vinculadas a procuração no e-CAC?',
        html: 'O sistema executa uma rotina acessando o e-CAC e verificando quais as empresas que estão vinculadas por procuração ao certificado digital selecionado. Esse processo pode levar até 72 horas para ser concluído! O usuário pode continuar usando o sistema enquanto a tarefa é concluída. Ao finalizar a execução todas as empresas com a procuração ativa vinculadas a esse certificado vão ser cadastradas automaticamente na base de dados do sistema. <br><strong>Deseja realmente importar essas empresas?</strong>',
        icon: 'warning',
        width: '40%',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        customClass: {
          popup: 'swal-m',
          confirmButton: 'btn btn-outline-primary mr-50',
          cancelButton: 'btn btn-outline-warning ',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            return new Promise(() => {
              api.cadastrarEmpresaPorCertificado(row.id)
                .then(() => {
                  this.$sucesso('Cadastro iniciado com sucesso.', 'success')
                })
                .catch(err => {
                  Vue.prototype.$toast({
                    component: ToastificationContent,
                    props: {
                      title: err.response.data.message === 'A senha de rede especificada não está correta.' ? 'Senha Inválida!' : 'Problema ao cadastrar empresas. Tente novamente mais tarde.',
                      icon: 'fa-solid fa-circle-exclamation',
                      variant: 'danger',
                    },
                  })
                })
            })
          }
          return null
        })
    },
    openModalEmpresasVinculadas(row) {
      if (row.situacao !== 'SemEmpresaVinculada') {
        this.$refs['empresas-vinculadas'].showModal(row.id, row.nome)
      }
    },
  },
}
</script>
