<template>
  <div id="vinculo-empresa">
    <b-row>
      <b-col>
        <h4>
          <span v-if="visualizacaoOpcoes.customTitle">
            {{ visualizacaoOpcoes.customTitle }}
          </span>
          <span v-else>
            Seleção de empresas {{ !cadastroUsuario ? 'vinculadas ao certificado' : '' }}
          </span>
        </h4>
        <fieldset class="font-weight-bold text-muted small">
          <span v-if="visualizacaoOpcoes.customSubtitle">
            {{ visualizacaoOpcoes.customSubtitle }}
          </span>
          <span v-else>
            Selecione as empresas que deseja vincular neste {{ cadastroUsuario ? 'usuário' : 'certificado' }}
          </span>
        </fieldset>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <hr
          class="mx-0 mt-0 mb-2"
          style="border-top: 1px solid #a3a4a66b !important;"
        >
      </b-col>
    </b-row>
    <b-row style="margin-top: -21px !important; padding-top: 0px">
      <b-col cols="6">
        <b-row>
          <b-col>
            <fieldset class="font-weight-bold pt-2 mb-1">
              {{ visualizacaoOpcoes.tituloVinculada || 'Empresas Vinculadas' }}
            </fieldset>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="temFiltroRapidoVinculado"
            :class="{ 'filtro-rapido': temBusca }"
          >
            <filtro-rapido
              id="filtro-rapido-vinculado"
              :filtros="filtrosRapidos"
              :filtro-atual="filtroAtual"
              style="left: 15px !important"
              @selecionado="aplicarFiltroRapido($event, true, true)"
            />
          </b-col>
          <b-col cols="8">
            <busca-input
              v-if="temBusca"
              v-model="buscaDigitadaVinculada"
              class="mb-1"
              :placeholder="visualizacaoOpcoes.placeholderBusca ? visualizacaoOpcoes.placeholderBusca : 'Buscar por palavra chave'"
              @input="buscar(true)"
              @clean="cleanInputVinculada"
            />
          </b-col>
          <b-col
            v-if="tipoRotina === 'DAS'"
            cols="4"
          >
            <v-select
              v-model="filtroStringDas"
              :options="filtrosRapidosDas"
              :clearable="true"
              multiple
              deselect-from-dropdown
              placeholder="Filtro por tag"
              class="mb-1"
              style="max-height: 3vh !important; margin-top: -2px !important;"
              @input="mudarFiltroDasSelecionado()"
            />
          </b-col>
        </b-row>
        <vue-good-table
          ref="tabelaVinculadas"
          mode="remote"
          class="vinculo-grid"
          :max-height="`${maxHeight}px`"
          max-width="100px"
          :columns="colunas.filter(f => !f.ocultarVinculados)"
          :rows="valueInner.vinculadas"
          :fixed-header="false"
          :is-loading.sync="isCarregando"
          @on-cell-click="clickVinculadas"
          @on-sort-change="onOrdenacaoAlteradaVinculadas"
        >
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span v-if="props.column.label == '' && props.column.icon">
              <font-awesome-icon
                class="text-primary"
                :icon="props.column.icon"
              />
            </span>
            <span v-else>
              {{ props.column.label }}
            </span>
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.label == '' && props.column.icon">
              <font-awesome-icon
                :class="props.column.colorIcon(props.formattedRow[props.column.field])"
                :icon="props.column.icon"
              />
            </span>
            <span
              v-else-if="props.column.field == 'razaoSocial'"
              class="d-flex justify-content-between align-items-center"
            >
              {{ formatar(props.formattedRow[props.column.field], quantidade) }}
              <b-badge
                v-if="tipoRotina === 'DAS' && props.row.situacaoDAS !== ''"
                v-b-tooltip.hover
                :variant="definirCorTag(props.row.situacaoDAS)"
              >
                {{ props.row.situacaoDAS }}
              </b-badge>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <div
            slot="emptystate"
          >
            <p style="text-align:center; margin:0;">
              Nenhum registro encontrado.
            </p>
          </div>
        </vue-good-table>
      </b-col>
      <b-col cols="6">
        <b-row>
          <fieldset class="font-weight-bold pt-2 mb-1">
            {{ visualizacaoOpcoes.tituloDesvinculada || 'Empresas Desvinculadas' }}
          </fieldset>
        </b-row>
        <b-row>
          <b-col
            v-if="temFiltroRapido"
            :class="{ 'filtro-rapido': temBusca }"
          >
            <filtro-rapido
              :filtros="filtrosRapidos"
              :filtro-atual="filtroAtual"
              @selecionado="aplicarFiltroRapido($event)"
            />
          </b-col>
          <b-col
            v-if="temBusca"
            cols="8"
          >
            <busca-input
              v-model="buscaDigitadaNaoVinculada"
              class="mb-1"
              :placeholder="visualizacaoOpcoes.placeholderBusca ? visualizacaoOpcoes.placeholderBusca : 'Buscar por palavra chave'"
              @input="buscar(false)"
              @clean="cleanInputNaoVinculada"
            />
          </b-col>
        </b-row>
        <vue-good-table
          ref="tabelaNaoVinculadas"
          mode="remote"
          class="vinculo-grid"
          :max-height="`${maxHeight}px`"
          :columns="colunas.filter(f => !f.ocultarNaoVinculados)"
          :rows="valueInner.naoVinculadas"
          :fixed-header="false"
          :is-loading.sync="isCarregando"
          @on-row-click="addVinculadas"
          @on-sort-change="onOrdenacaoAlteradaNaoVinculadas"
        >
          <div slot="emptystate">
            <p style="text-align:center; margin:0">
              Nenhum registro encontrado.
            </p>
          </div>
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span v-if="props.column.label == '' && props.column.icon">
              <font-awesome-icon
                class="text-primary"
                :icon="props.column.icon"
              />
            </span>
            <span v-else>
              {{ props.column.label }}
            </span>
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.label == '' && props.column.icon">
              <font-awesome-icon
                :class="props.column.colorIcon(props.formattedRow[props.column.field])"
                :icon="props.column.icon"
              />
            </span>
            <span
              v-else-if="props.column.field == 'razaoSocial'"
              class="d-flex justify-content-between align-items-center"
            >
              {{ formatar(props.formattedRow[props.column.field], quantidade) }}
              <b-badge
                v-if="props.row.situacaoDAS !== ''"
                v-b-tooltip.hover
                :variant="definirCorTag(props.row.situacaoDAS)"
              >
                {{ props.row.situacaoDAS }}
              </b-badge>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
    <b-row class="mb-2" />
    <b-row>
      <b-col>
        <span class="m-50">
          Total de {{ valueInner.totalVinculadas }} itens
        </span>
      </b-col>
      <b-col>
        <span class="m-50">
          Total de {{ valueInner.totalNaoVinculadas }} itens
        </span>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-btn
          variant="none"
          :class="`btn-outline-${!inverterVincularDesvincular ? 'danger' : 'success'} mt-1 mr-50 btn-sm`"
          @click="clearVinculadas"
        >{{ !inverterVincularDesvincular ? 'Desvincular Todas' : 'Vincular Todas' }}</b-btn>
      </b-col>
      <b-col class="text-left">
        <b-btn
          variant="none"
          :class="`btn-outline-${!inverterVincularDesvincular ? 'success' : 'danger'} mt-1 btn-sm`"
          @click="clearDesvinculadas"
        >{{ !inverterVincularDesvincular ? 'Vincular Todas' : 'Desvincular Todas' }} </b-btn>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#vinculo-empresa {
  .search-items {
    height: 37px !important;
    margin-top: -2px;
  }
  #filtro-rapido-vinculado {
    select {
      margin-left: -15px;
    }
  }
  .cadastro {
    width: 1vw !important;
  }
}

table.vgt-table {
  border-collapse: separate !important;
  border-spacing: 0;
  border-top: 0 !important;
}

table.vgt-table thead {
  position: sticky;
  top: 0;
  background-color: white;
}

table.vgt-table thead tr:first-child th {
  border-top: 1px solid #dcdfe6 !important
}

table.vgt-table th, .vgt-table td {
  border-width: .5px !important
}

.filtro-rapido {
  padding: unset !important;
  margin-left: 15px !important;
}

.vinculo-grid {
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(122, 122, 122, 0.4);
      border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      // background-color: $primary;
      -webkit-box-shadow: inset 0 0 15px $primary;
  }

  .nowrap {
    white-space: nowrap !important;
  }

}

.servico-badge {
  height: 1.2rem;
  font-size: 10px;
  font-weight: 600;
  border-radius: 2px;
  margin-left: 0.5rem;
}

.disable-table {
  pointer-events: none;
}
</style>
<script>
import {
  required,
} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import EventBus from '@app/shared/mixins/event-bus/eventBus'
import colunasPadrao from '../../../app/usuario/shared/components/vinculo-empresas/shared/colunasPadrao'

export default {
  name: 'UsuarioInterno',
  components: {
    vSelect,
    VueGoodTable,
    FiltroRapido: () => import('@pilar/components/visualizacao-base/components/filtro-rapido/FiltroRapido.vue'),
    BuscaInput: () => import('@pilar/components/busca-input/BuscaInput.vue'),
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    cadastroUsuario: {
      type: Boolean,
      default: true,
    },
    visualizacaoOpcoes: {
      type: Object,
      required: true,
    },
    quantidade: {
      type: Number,
      default: 12,
    },
    filtrosVinculadas: {
      type: Array,
      default: () => [],
    },
    filtrosNaoVinculadas: {
      type: Array,
      default: () => [],
    },
    filtrosRapidos: {
      type: Array,
      default: () => [],
    },
    temFiltroRapido: {
      type: Boolean,
      default: false,
    },
    temFiltroRapidoVinculado: {
      type: Boolean,
      default: false,
    },
    temBusca: {
      type: Boolean,
      default: false,
    },
    inverterVincularDesvincular: {
      type: Boolean,
      default: false,
    },
    tipoRotina: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      valueInner: {
        id: 0,
        vinculadas: [],
        naoVinculadas: [],
        totalVinculadas: 0,
        totalNaoVinculadas: 0,
      },
      colunas: colunasPadrao,
      buscaDigitadaVinculada: '',
      buscaDigitadaNaoVinculada: '',
      filtroAtivoVinculadas: {
        filtroBy: [],
        ordemBy: [],
      },
      filtroAtivoNaoVinculadas: {
        filtroBy: [],
        ordemBy: [],
      },
      filtroImutavel: [],
      filtroImutavelVinculadas: [],
      filtroAtual: null,
      idsVinculadas: [],
      isCarregando: false,
      filtroStringDas: [],
      filtrosRapidosDas: ['Em aberto', 'Em atraso'],
      maxHeight: 205,
    }
  },
  watch: {
    value(value, oldValue) {
      if (value !== oldValue) {
        this.valueInner = value
        this.idsVinculadas = this.valueInner.vinculadas.map(empresa => empresa.empresaId)
        this.atualizarContadores()
      }
    },
  },
  created() {
    this.valueInner = this.value
    this.filtroImutavel = [...this.filtrosNaoVinculadas]
    this.filtroImutavelVinculadas = [...this.filtrosVinculadas]
    this.filtroAtivoVinculadas.filtroBy = this.filtrosVinculadas
    this.filtroAtivoNaoVinculadas.filtroBy = this.filtrosNaoVinculadas
    this.colunas = this.visualizacaoOpcoes.colunasAdicionais ? [...this.colunas, ...this.visualizacaoOpcoes.colunasAdicionais] : this.colunas
    this.idsVinculadas = this.valueInner.vinculadas.map(empresa => empresa.empresaId)
    this.atualizarContadores()
    EventBus.$on('aplicarFiltroPeridoApuracaoDas', () => this.mudarFiltroDasSelecionado(this.filtro, true, true))
  },
  mounted() {
    this.updateHeight()
    window.addEventListener('resize', this.updateHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateHeight)
  },
  methods: {
    formatar(nome, tamanhoMaximo) {
      if (!nome) {
        return nome
      }
      if (nome.length - 3 > tamanhoMaximo) {
        const nomeFormatado = `${nome.substring(0, tamanhoMaximo)}...`
        return nomeFormatado
      }
      return nome
    },
    buscar(vinculadas) {
      if (!this.isCarregando) {
        if (vinculadas) {
          this.montarGrid(this.filtroAtivoVinculadas, vinculadas)
        } else {
          this.montarGrid(this.filtroAtivoNaoVinculadas, vinculadas)
        }
      }
    },
    cleanInputVinculada() {
      this.buscaDigitadaVinculada = ''
      this.buscar(true)
    },
    cleanInputNaoVinculada() {
      this.buscaDigitadaNaoVinculada = ''
      this.buscar(false)
    },
    addVinculadas(params) {
      this.idsVinculadas.push(params.row.empresaId)
      this.valueInner.vinculadas.push(params.row)
      this.valueInner.naoVinculadas.splice(params.pageIndex, 1)
      this.atualizarContadores()
    },
    clickVinculadas(params) {
      if (params.column.eventClickCustom) {
        this.$emit('eventClickCustom', params)
      } else {
        this.addDesvinculadas(params)
      }
    },
    addDesvinculadas(params) {
      const idRemover = params.row.empresaId
      this.idsVinculadas = this.idsVinculadas.filter(id => id !== idRemover)
      this.valueInner.naoVinculadas.push(params.row)
      this.valueInner.vinculadas.splice(params.rowIndex, 1)
      this.atualizarContadores()
    },

    clearDesvinculadas() {
      while (this.valueInner.naoVinculadas.length > 0) {
        const vinculada = this.valueInner.naoVinculadas.pop()
        this.valueInner.vinculadas.push(vinculada)
        this.idsVinculadas.push(vinculada.empresaId)
      }
      this.atualizarContadores()
    },

    clearVinculadas() {
      while (this.valueInner.vinculadas.length > 0) {
        const desvinculada = this.valueInner.vinculadas.pop()
        this.valueInner.naoVinculadas.push(desvinculada)
        const index = this.idsVinculadas.indexOf(desvinculada.empresaId)
        this.idsVinculadas.splice(index, 1)
      }
      this.atualizarContadores()
    },
    atualizarContadores() {
      this.valueInner.totalNaoVinculadas = this.valueInner.naoVinculadas.length
      this.valueInner.totalVinculadas = this.valueInner.vinculadas.length
    },
    aplicarFiltroQuery() {
      this.$nextTick(() => {
        const params = this.$route.query
        if (params) {
          const filtroRapido = params.filtroRapido
            ? this.filtrosRapidos.find(f => f.id === params.filtroRapido)
            : null
          this.aplicarFiltro(params.busca, filtroRapido)
        }
      })
    },
    aplicarFiltroRapido(filtro, filtroInterno = true, isVinculada = false) {
      if (isVinculada === false) {
        this.filtroAtivoNaoVinculadas.filtroBy = filtro !== null ? this.filtroAtivoNaoVinculadas.filtroBy : [...this.filtroImutavel]
        const index = filtroInterno ? this.filtroAtivoNaoVinculadas.filtroBy.findIndex(where => where.filtroInterno)
          : this.filtroAtivoNaoVinculadas.filtroBy.findIndex(where => where.colunas.length === 1 && where.colunas.some(any => filtro === null || any === (filtro.colunas[0])))
        if (index >= 0) {
          this.filtroAtivoNaoVinculadas.filtroBy.splice(index, 1)
        }
        if (filtro && filtro !== null) {
          // eslint-disable-next-line no-param-reassign
          filtro.filtroInterno = filtroInterno
          this.filtroAtivoNaoVinculadas.filtroBy.push(filtro)
          this.alterarRota(filtro)
        }
        this.montarGrid(this.filtroAtivoNaoVinculadas, isVinculada)
      }

      if (isVinculada === true) {
        this.filtroAtivoVinculadas.filtroBy = filtro !== null ? this.filtroAtivoVinculadas.filtroBy : [...this.filtroImutavelVinculadas]
        const index = filtroInterno ? this.filtroAtivoVinculadas.filtroBy.findIndex(where => where.filtroInterno)
          : this.filtroAtivoVinculadas.filtroBy.findIndex(where => where.colunas.length === 1 && where.colunas.some(any => filtro === null || any === (filtro.colunas[0])))
        if (index >= 0) {
          this.filtroAtivoVinculadas.filtroBy.splice(index, 1)
        }
        if (filtro && filtro !== null) {
          // eslint-disable-next-line no-param-reassign
          filtro.filtroInterno = filtroInterno
          this.filtroAtivoVinculadas.filtroBy.push(filtro)
          this.alterarRota(filtro)
        }
        this.montarGrid(this.filtroAtivoVinculadas, isVinculada)
      }
    },
    filtroRapidoViaQuery() {
      const query = this.$route.query.filtroRapido
      if (query && this.filtrosRapidos) {
        const item = this.filtrosRapidos.find(find => find.id === query)
        if (item) {
          this.aplicarFiltroRapido(item)
          return true
        }
      }
      return false
    },
    alterarRota(filtro) {
      let alterar = false
      const queries = JSON.parse(JSON.stringify(this.$route.query || {}))
      if (queries.buscaNaoVinculada !== this.buscaDigitadaNaoVinculada) {
        queries.buscaNaoVinculada = this.buscaDigitadaNaoVinculada
        alterar = true
      }
      if (queries.buscaVinculada !== this.buscaDigitadaVinculada) {
        queries.buscaVinculada = this.buscaDigitadaVinculada
        alterar = true
      }
      if (filtro && queries.filtroRapido !== filtro.id) {
        // eslint-disable-next-line prefer-destructuring
        queries.filtroRapido = filtro.id
        alterar = true
      }
      if (alterar) {
        this.$router.replace({ query: queries })
      }
    },
    montarGrid(enviarServidor, vinculadas) {
      this.isCarregando = true
      this.setValorFiltro()
      this.visualizacaoOpcoes.evento(this.valueInner.id,
        {
          filtro: enviarServidor,
          idsVinculadas: this.idsVinculadas,
          isVinculada: vinculadas,
        })
        .then(payload => {
          if (vinculadas) {
            this.valueInner.vinculadas = payload.data
          } else {
            this.valueInner.naoVinculadas = payload.data
          }
          this.atualizarContadores()
          this.isCarregando = false
        })
        .catch(err => {
          this.isCarregando = false
          // this.$erroResponse(err.response)
          throw err
        })
    },
    setValorFiltro() {
      if (this.filtroAtivoVinculadas.filtroBy && this.filtroAtivoVinculadas.filtroBy.length > 0) {
        this.filtroAtivoVinculadas.filtroBy[0].valor = this.buscaDigitadaVinculada
        this.alterarRota()
      }
      if (this.filtroAtivoNaoVinculadas.filtroBy && this.filtroAtivoNaoVinculadas.filtroBy.length > 0) {
        this.filtroAtivoNaoVinculadas.filtroBy[0].valor = this.buscaDigitadaNaoVinculada
        this.alterarRota()
      }
    },
    onOrdenacaoAlteradaVinculadas(param) {
      const colOrdenada = param[0]
      this.filtroAtivoVinculadas.ordemBy = [{
        tipo: colOrdenada.type,
        coluna: colOrdenada.field,
      }]
      this.montarGrid(this.filtroAtivoVinculadas, true)
    },
    onOrdenacaoAlteradaNaoVinculadas(param) {
      const colOrdenada = param[0]
      this.filtroAtivoNaoVinculadas.ordemBy = [{
        tipo: colOrdenada.type,
        coluna: colOrdenada.field,
      }]
      this.montarGrid(this.filtroAtivoNaoVinculadas, false)
    },
    mudarFiltroDasSelecionado() {
      this.filtro = {
        valores: this.filtroStringDas,
        colunas: [],
      }
      this.aplicarFiltroRapido(this.filtro, true, true)
    },
    definirCorTag(tag) {
      if (tag === 'Em atraso') {
        return 'light-danger'
      }
      return 'light-warning'
    },
    updateHeight() {
      const windowHeight = window.innerHeight
      const topOffset = this.$refs.certificadosList?.$el?.getBoundingClientRect().top || 0
      this.tamanho = windowHeight - topOffset - 600
      this.maxHeight = this.tamanho > 205 ? this.tamanho : 205
    },
  },
}
</script>
