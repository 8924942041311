<template>
  <div>
    <b-modal
      id="parcelasList"
      ref="parcelasList"
      button-size="sm"
      no-close-on-backdrop
      ok-only
      :no-stacking="stacking"
      ok-title="Ok"
      size="lg"
      :no-enforce-focus="true"
      @hidden="hide"
    >
      <div slot="modal-title">
        <h4>DAS em aberto - {{ value.razaoSocial }}</h4>
        <fieldset class="font-weight-bold text-muted small">
          Nesta tela estão as guias em aberto de PA anteriores ao exibido na grid principal, você poderá mandar as guias por email ou baxar.
        </fieldset>
      </div>
      <b-row>
        <b-col>
          <h5 style="float: right;">
            Total: {{ guias.length }}
          </h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <tabela-repeticao
            v-model="guias"
            :somente-visualizacao="false"
            :customizar-botoes="true"
            :colunas="colunas"
            class="tabela-guias"
          >
            <template #visualizacao="{slotScope: {key, valor}}">
              <b-badge
                v-if="key === 'status'"
                :variant="valor.indexOf('atraso') > 0 ? 'light-danger' : (valor.indexOf('paga') > 0 ? 'light-success' : 'light-primary')"
              >
                {{ valor }}
              </b-badge>
              <span
                v-else
                class="ml-50"
              >
                {{ valor }}
              </span>
            </template>
            <template #botoes-customizados="{slotScope: {item}}">
              <div>
                <button
                  v-b-tooltip.hover
                  title="Visualizar"
                  :disabled="!item.hasFile"
                  class="btn btn-lg btn-none px-50 py-0"
                  @click="abrirArquivo(item)"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-barcode"
                    class="text-primary"
                  />
                </button>
                <button
                  v-b-tooltip.hover
                  variant="none"
                  title="Consolidar recalculo do DAS para outra data."
                  class="btn btn-lg btn-none px-0 py-0"
                  @click="abrirCalcularDas(item.id)"
                >
                  <font-awesome-icon
                    icon="fa-calculator"
                    class="text-primary"
                  />
                </button>

                <b-btn
                  v-b-tooltip.hover
                  title="Visualizar Última Declaração PGDAS-D 2018"
                  variant="none"
                  class="btn p-0 pl-50 m-0"
                  @click="abrirArquivoSN(item)"
                >
                  <font-awesome-icon
                    icon="fa-sharp fa-regular fa-clipboard"
                    class="text-primary"
                    style="font-size:1.3rem !important;width:22px"
                  />
                </b-btn>

                <b-btn
                  v-b-tooltip.hover
                  title="Observação"
                  variant="none"
                  class="btn btn-lg btn-none px-50 py-0"
                  @click="abrirObservacoes(item)"
                >
                  <font-awesome-icon
                    icon="fa-regular fa-message"
                    :class="item.temObservacaoNova? 'text-secondary' : 'text-primary'"
                  />
                </b-btn>

                <button
                  v-b-tooltip.hover
                  title="Enviar por e-mail"
                  class="btn btn-lg btn-none px-0 py-0"
                  @click="abrirEnvioEmail(item)"
                >
                  <feather-icon
                    icon="SendIcon"
                    size="20"
                  />
                </button>
              </div>
            </template>
          </tabela-repeticao>
        </b-col>
      </b-row>
    </b-modal>
    <recalcular-das
      ref="cartaoDetalhesModal"
      v-model="value"
    />
    <loading
      :show-cancel-button="false"
      :is-loading="isLoading"
      :descricao="'Buscando'"
    />
  </div>
</template>

<style lang="scss">
.modal:nth-of-type(even) {
  z-index: 1052 !important;
}
.modal-backdrop.show:nth-of-type(even) {
  z-index: 1051 !important;
}
</style>

<script>
import api from '../../services/api'

export default {
  name: 'GuiasDas',
  components: {
    TabelaRepeticao: () => import('@pilar/components/tabela-repeticao/TabelaRepeticao.vue'),
    Loading: () => import('@pilar/components/loading/Loading.vue'),
    RecalcularDas: () => import('../recalcular/RecalcularDas.vue'),
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      api,
      stacking: true,
      mostrarArquivo: false,
      urlArquivo: '',
      guias: [],
      colunas: [
        {
          key: 'competencia', label: 'Competência', tdClass: 'col-5',
        },
        {
          key: 'valorDas', label: 'Valor principal (R$)', tdClass: 'col-5',
        },
      ],
      isLoading: false,
      temObservacaoNova: false,
      mostrarObservacoes: false,
      docSelecionado: null,
    }
  },
  methods: {
    abrirArquivo(documento) {
      this.$emit('abrir-arquivo', documento)
    },
    abrirArquivoSN(documento) {
      this.$emit('abrir-declaracao', documento)
    },
    carregarGuias() {
      api.requestGuiasDas(this.value.empresaId)
        .then(payload => {
          this.guias = payload.data
        })
        .catch(err => {
          throw err
        })
    },
    hide() {
      this.guias = []
    },
    show() {
      this.carregarGuias()
      this.$refs.parcelasList.show()
    },
    abrirEnvioEmail(item) {
      this.$emit('envio-email', item)
    },
    abrirCalcularDas(id) {
      this.$emit('calcular-das', { id })
    },
    abrirObservacoes(item) {
      this.$emit('open-observacoes', item)
    },
  },
}

</script>
