<template>
  <div style="display: inline-block;">
    <b-btn
      variant="none"
      class="btn btn-none rounded-pill m-0 icone-acao p-0 btn-none"
      :disabled="((gridProps.row.situacao !== 'Irregular' && (gridProps.row.mensagemErro === undefined || gridProps.row.mensagemErro === '')) && gridProps.row.situacao !== 'Regular' && gridProps.row.situacao !== 'Base indisponível')"
      @click="abrirArquivo(gridProps.row)"
    >
      <font-awesome-icon
        :icon="faExternalLinkAlt"
        class="text-primary"
      />
    </b-btn>
    <b-btn
      v-if="gridProps.row.situacao != 'Motivo'"
      v-b-tooltip.hover
      title="Enviar por e-mail"
      variant="none"
      class="btn-none icone-acao m-0 p-0"
      :disabled="gridProps.row.situacao !== 'Irregular' && gridProps.row.situacao !== 'Regular' && gridProps.row.situacao !== 'Base indisponível'"
      @click="openModalEnviarSF()"
    >
      <feather-icon
        icon="SendIcon"
        class="text-primary"
        size="20"
      />
    </b-btn>
    <b-btn
      variant="none"
      class="btn btn-none rounded-pill m-0 icone-acao p-0 btn-none"
      :disabled="gridProps.row.situacao !== 'Irregular'"
      @click="abrirPendencias(gridProps.row)"
    >
      <font-awesome-icon
        :icon="faMagnifyingGlass"
        class="text-primary"
      />
    </b-btn>

    <b-btn
      v-b-tooltip.hover
      title="Histórico"
      variant="none"
      class="btn-none icone-acao m-0 p-0"
      @click="openModalHistorico(gridProps)"
    >
      <font-awesome-icon
        icon="fa-sharp fa-solid fa-list"
        class="text-primary"
      />
    </b-btn>

    <span
      v-b-tooltip.hover
      :title="gridProps.row.habilitaAtualizacaoManual ? 'Atualizar a Situação Fiscal' : 'Não é possível fazer atualização manual no momento'"
    >
      <atualizacao-manual
        tipo-rotina="Situação Fiscal"
        :enviar-atualizacao="atualizarSF"
        :disabled="!gridProps.row.habilitaAtualizacaoManual"
        @atualizar-grid="$emit('atualizar-grid')"
      />
    </span>

    <visualizar-arquivo
      v-if="mostarArquivo && arquivo.url"
      :url="arquivo.url"
      :observacao="arquivo.observacao"
      @hidden="fecharArquivo()"
    />

    <envio-email
      ref="modalEnvioEmail"
      :title="`Enviar Situação Fiscal ${gridProps.row.razaoSocial}`"
      :sugestao="gridProps.row.emailEmpresa"
      :sugestao-email-adicional="gridProps.row.emailAdicionalEmpresa"
      @enviarEmail="enviarSF"
    />

    <pendencias
      v-if="mostrarPendencias"
      :empresa-id="situacaoFiscalSelecionada.id"
      :empresa-nome="situacaoFiscalSelecionada.razaoSocial"
      :grupos-existentes="situacaoFiscalSelecionada.tiposGrupoPendencias"
      @click-aqui="abrirArquivoPendencia(gridProps.row)"
      @hidden="fecharPendencias()"
    />

    <modal-historico
      :ref="`modalHistorico${gridProps.row.id}`"
      rotina="situação fiscal"
      des-de="Abril de 2024"
      :empresa="gridProps.row"
      :api="apiSituacaoFiscal"
      :coluna-historico="colunaHistorico"
      :filtros-rapidos="filtrosRapidos"
      :filtro-by-historico="['ConsultadoEm']"
    />

    <loading
      :show-cancel-button="false"
      :is-loading="isLoading"
      :titulo-carregamento="'Aguarde!'"
      descricao="Estamos atualizando a Situação Fiscal."
    />
  </div>
</template>

<style lang="scss">
</style>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faExternalLinkAlt, faMagnifyingGlass, faRefresh,
} from '@fortawesome/free-solid-svg-icons'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import colunasHistorico from '../historico'
import api from '../../services/api'

export default {
  components: {
    FontAwesomeIcon,
    VisualizarArquivo: () => import('@pilar/components/visualizar-arquivo/VisualizarArquivo.vue'),
    Pendencias: () => import('../pendencias/Pendencias.vue'),
    EnvioEmail: () => import('@/app/shared/components/envio-email/EnvioEmail.vue'),
    ModalHistorico: () => import('@/app/shared/components/historico/ModalHistorico.vue'),
    Loading: () => import('@pilar/components/loading/Loading.vue'),
    AtualizacaoManual: () => import('@/app/shared/components/botoes-inline/atualizacao-manual/AtualizacaoManual.vue'),
  },
  props: {
    gridProps: {
      type: Object,
      required: true,
    },
    filtrosRapidos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      faRefresh,
      faExternalLinkAlt,
      faMagnifyingGlass,
      mostarArquivo: false,
      arquivo: {},
      mostrarPendencias: false,
      situacaoFiscalSelecionada: null,
      colunaHistorico: colunasHistorico,
      apiSituacaoFiscal: api,
      isLoading: false,
    }
  },
  methods: {
    temPendencia(row) {
      return row.tiposGrupoPendencias && row.tiposGrupoPendencias.length > 0
    },
    abrirArquivoPendencia(linha) {
      this.mostrarPendencias = false
      this.abrirArquivo(linha)
    },
    abrirArquivo(linha) {
      if (!this.mostarArquivo && !this.mostrarPendencias) {
        this.situacaoFiscalSelecionada = linha
        if (linha.mensagemErro === undefined || linha.mensagemErro === '') {
          api.getURL(this.situacaoFiscalSelecionada.id)
            .then(payload => {
              this.arquivo.url = payload.data
              this.mostarArquivo = true
            })
            .catch(err => {
              this.fecharArquivo()
              this.notificarErro('Não foi possível abrir a visualização de arquivo.', err)
              throw err
            })
        } else {
          this.arquivo.url = `<div>${linha.mensagemErro}</div>`
          this.mostarArquivo = true
        }
      }
    },
    fecharArquivo() {
      this.mostarArquivo = false
      this.situacaoFiscalSelecionada = null
      this.arquivo = {}
    },
    abrirPendencias(linha) {
      if (!this.temPendencia(linha)) {
        this.abrirArquivo(linha)
      } else if (!this.mostarArquivo && !this.mostrarPendencias) {
        this.situacaoFiscalSelecionada = linha
        this.mostrarPendencias = true
      }
    },
    fecharPendencias() {
      this.mostrarPendencias = false
      this.situacaoFiscalSelecionada = null
      this.atualizarGrid()
    },
    notificarErro(msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: msg,
          icon: 'fa-solid fa-circle-exclamation',
          variant: 'danger',
        },
      })
    },
    notficarSucesso(msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: msg,
          icon: 'fa-solid fa-circle-check',
          variant: 'success',
        },
      })
    },
    openModalEnviarSF() {
      this.$refs.modalEnvioEmail.abrirModal()
    },
    enviarSF(itens) {
      if (itens.emailsPara.length === 0 && itens.emailsCc === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Informe ao menos um e-mail',
            icon: 'fa-solid fa-circle-exclamation',
            variant: 'danger',
          },
        })
      } else {
        api.notificaSF(this.gridProps.row.razaoSocial, itens.emailsPara, itens.emailsCc, this.gridProps.row.id, itens.mensagensAdicional, itens.definirEmailPadroes)
      }
    },
    atualizarGrid() {
      this.$emit('atualizar-grid')
    },
    openModalHistorico(props) {
      this.$refs[`modalHistorico${props.row.id}`].abrirModal()
    },
    atualizarSF() {
      return api.updateSituacaoFiscal(this.gridProps.row.empresaId)
    },
  },
}
</script>
