import axios from '@axios'

const PREFIX = '/parametros-sistema'

export default {

  get() {
    return axios.get(`${PREFIX}`)
  },

  salvar(parametrosSistema) {
    return axios.post(`${PREFIX}`, parametrosSistema)
  },

  getHistorico(tipo) {
    return axios.get(`${PREFIX}/historico?tipo=${tipo}`)
  },
}
