<template>
  <b-modal
    ref="modal-empresas-vinculadas"
    size="lg"
    button-size="sm"
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-header>
      <b-row style="display:block">
        <b-col>
          <h3>{{ nomeCertificado }}</h3>
        </b-col>
        <b-col>
          <h5>Empresas vinculadas ao certificado&nbsp;
            <feather-icon
              v-b-tooltip.hover
              title="Empresas sem data Fim da Vigência estão aguardando atualização. Aguarde a próxima execução."
              icon="AlertCircleIcon"
              size="16"
              :class="'text-primary'"
            />
          </h5>
        </b-col>
      </b-row>
    </template>

    <b-row>
      <b-col>
        <b-form-group
          style="margin-bottom:0.5rem"
        >
          <busca-input
            v-model="buscaDigitada"
            :placeholder="'Busca por: Palavra chave, CNPJ/CPF e Vigência'"
            @input="buscar"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <vue-good-table
      mode="remote"
      max-height="275px"
      class="empresas-grid"
      :columns="colunas"
      :rows="empresas"
      :fixed-header="false"
      :is-loading.sync="isCarregando"
      @on-row-click="redirecionarEmpresa"
      @on-sort-change="onOrdenacaoAlterada"
    >
      <!-- on-row-click   || on-cell-click-->
      <div slot="emptystate">
        <p style="text-align:center; margin:0">
          Nenhum registro encontrado.
        </p>
      </div>
    </vue-good-table>
    <template #modal-footer>
      <b-col>
        <span>
          Total de {{ totalEmpresas }} itens
        </span>
      </b-col>

      <b-col>
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="hideModal"
        >
          OK
        </b-button>
      </b-col>
    </template>
  </b-modal>
</template>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.empresas-grid {
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(122, 122, 122, 0.4);
      border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      // background-color: $primary;
      -webkit-box-shadow: inset 0 0 15px $primary;
  }
}

</style>

<script>
import api from '@/app/certificado-digital/shared/services/api'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BuscaInput: () => import('@pilar/components/busca-input/BuscaInput.vue'),
  },
  data() {
    return {
      colunas: [
        { field: 'cadastroFederal', label: 'CNPJ/CPF', tdClass: 'col-nowrap' },
        { field: 'razaoSocial', label: 'Razão social' },
        { field: 'fimVigencia', label: 'Fim da vigência', tdClass: 'col-tamanho-data-2' },
      ],
      empresas: [],
      totalEmpresas: 0,
      nomeCertificado: '',
      idCertificado: 0,
      isCarregando: false,
      buscaDigitada: '',
      filtros: [{ colunas: ['CadastroFederalSemMascara', 'RazaoSocial', 'CadastroFederal', 'FimVigencia'], valor: null }],
      ordenacao: [],
    }
  },
  methods: {
    showModal(id, nome) {
      this.empresas = []
      this.nomeCertificado = nome
      this.idCertificado = id
      this.$refs['modal-empresas-vinculadas'].show()
      this.getEmpresas(id).then(payload => {
        this.empresas = payload.data
        this.totalEmpresas = payload.data.length
      })
    },
    hideModal() {
      this.$emit('fechou')
      this.$refs['modal-empresas-vinculadas'].hide()
    },
    redirecionarEmpresa(evt) {
      this.$router.push({ name: 'empresa-editar', params: { id: evt.row.id } })
    },
    buscar() {
      this.isCarregando = true
      this.setValorFiltro()
      this.getEmpresas(this.idCertificado).then(payload => {
        this.empresas = payload.data
        this.isCarregando = false
        this.totalEmpresas = this.empresas.length
      })
        .catch(err => {
          this.isCarregando = false
          this.totalEmpresas = this.empresas.length
          throw err
        })
    },
    setValorFiltro() {
      if (this.filtros && this.filtros.length > 0) {
        this.filtros[0].valor = this.buscaDigitada
      }
    },
    getEmpresas(id) {
      return api.getEmpresasPorCertificado(id, {
        filtro: {
          filtroBy: this.filtros,
          ordemBy: this.ordenacao,
        },
        isVinculada: true,
      })
    },
    onOrdenacaoAlterada(param) {
      const colOrdenada = param[0]
      this.ordenacao = [{
        tipo: colOrdenada.type,
        coluna: colOrdenada.field,
      }]
      this.buscar()
    },
  },
}
</script>
