<template>
  <div>
    <b-btn
        v-b-tooltip.hover
        variant="none"
        title="Histórico de alteração"
        class="btn btn-sm btn-none px-50 py-0"
        @click="show()"
      >
        <font-awesome-icon
          icon="fa-solid fa-list"
          class="text-primary"
        />
    </b-btn>
    <b-modal
      id="historicoParametroList"
      ref="historicoParametroList"
      button-size="sm"
      no-close-on-backdrop
      no-stacking
      ok-olny
      ok-title="Ok"
      size="lg"
      cancel-title=""
      :cancel-disabled="true"
      cancel-variant="false"
    >
      <div slot="modal-title">
        <h5>{{ `Histórico de alteração - ${tipoGrupo} ${tipoParametro} - ${getEmpresa()}`}}</h5>
        <fieldset class="font-weight-bold text-muted">
          Histórico de alteração de datas e parâmetros do sistema para a empresa
        </fieldset>
      </div>
      <visualizacao
        :parametros="this.form"
      />
    </b-modal>
  </div>
</template>

<style lang="scss">
.modal:nth-of-type(even) {
  z-index: 1052 !important;
}
.modal-backdrop.show:nth-of-type(even) {
  z-index: 1051 !important;
}

.modal-dialog {
  top: 12%;
  transform: translateY(-20%);
}
</style>

<script>
import { getUserInfo } from '@/auth/utils'
import api from '../services/api'

export default {
  name: 'HistoricoParametroView',
  components: {
    Visualizacao: () => import('./visualizacao/Visualizacao.vue'),
  },
  data() {
    return {
      form: {},
    }
  },
  props: {
    tipoParametro: {
      type: String,
      default: 'Nome da rotina',
      required: true,
    },
    tipoGrupo: {
      type: String,
      default: 'Nome do grupo',
    },
  },
  methods: {
    show() {
      api.getHistorico(this.tipoParametro)
        .then(payload => {
          this.form = payload.data
          this.$refs.historicoParametroList.show()
        })
        .catch(() => {
          this.$erro('Não foi possível buscar.')
        })
    },
    getEmpresa() {
      return getUserInfo().empresa
    },
  },
}

</script>
