<template>
  <div style="display: inline-flex;">
    <div
      v-b-tooltip.hover
      :title="validarStatus(gridProps.row) ? 'Gerar DAS mensal' : 'DAS em atraso, favor recalcular'"
    >
      <button
        variant="none"
        class="btn btn-none rounded-pill m-0 icone-acao p-0 btn-none"
        :disabled="gridProps.row.dasEmAtraso == null || gridProps.row.pago != false || !gridProps.row.hasFile"
        @click="abrirArquivo(gridProps.row)"
      >
        <font-awesome-icon
          icon="fa-solid fa-barcode"
          :class="validarCorIcone(gridProps.row)"
        />
      </button>
    </div>
    <div
      v-b-tooltip.hover
      title="Consolidar recalculo do DAS para outra data."
    >
      <button
        variant="none"
        class="btn btn-none rounded-pill m-0 icone-acao p-0 btn-none"
        :disabled="gridProps.row.rpa == '-' || gridProps.row.pago != false"
        @click="openCalcularDas(gridProps.row.id)"
      >
        <font-awesome-icon
          icon="fa-calculator"
          class="text-primary"
        />
      </button>
    </div>
    <div
      v-b-tooltip.hover
      title="Parcelas"
    >
      <button
        variant="none"
        title="Parcelas"
        class="btn p-0 m-0"
        :disabled="!gridProps.row.hasDebitos"
        @click="openModalGuias()"
      >
        <font-awesome-icon
          icon="fa-sharp fa-solid fa-list"
          :class="gridProps.row.hasDebitos ? 'text-danger' : 'text-primary'"
        />
      </button>
    </div>
    <div
      v-b-tooltip.hover
      title="Visualizar Última Declaração PGDAS-D 2018"
    >
      <b-btn
        variant="none"
        class="btn p-0 m-0"
        @click="abrirArquivoSN(gridProps.row)"
      >
        <font-awesome-icon
          icon="fa-sharp fa-regular fa-clipboard"
          class="text-primary"
          style="font-size:1.3rem !important;width:22px"
        />
      </b-btn>
    </div>
    <div
      v-b-tooltip.hover
      title="Observação"
    >
      <b-btn
        variant="none"
        class="btn-none rounded-pill m-0 icone-acao p-0"
        :disabled="gridProps.row.dasEmAtraso == null || gridProps.row.possuiDas == false"
        @click="openModalObservacao(gridProps.row, true)"
      >
        <font-awesome-icon
          icon="fa-regular fa-message"
          :class=" gridProps.row.temObservacaoNova ? 'text-warning': 'text-primary'"
        />
      </b-btn>
    </div>
    <div
      v-b-tooltip.hover
      title="Enviar por e-mail"
    >
      <button
        variant="none"
        class="btn btn-none rounded-pill m-0 icone-acao p-0 btn-none"
        @click="abrirEnvioEmail(gridProps.row)"
      >
        <feather-icon
          icon="SendIcon"
          class="text-primary"
          size="20"
        />
      </button>
    </div>

    <modal-observacao
      :ref="`modalObservacao`"
      :value="gridProps.row"
      :id-registro="gridProps.row.id"
      tipo="Das"
      :api="api"
      :notificar="{ sucesso: notficarSucesso, erro: notificarErro }"
      @change="receberObservacao"
      @hidden="fecharObservacao($event)"
    />

    <recalcular-das
      ref="recalcularDasModal"
      v-model="gridProps"
      :guia-id="gridProps.row.id"
      @recalcular="recalcularDas($event.id, $event.data)"
    />

    <visualizar-arquivo
      v-if="mostarArquivo && arquivo.url"
      :url="arquivo.url"
      @hidden="fecharArquivo()"
    />

    <envio-email
      ref="modalEnvioEmail"
      :title="`Enviar Guias Das ${gridProps.row.razaoSocial}`"
      @enviarEmail="enviarDas"
      @hide="docEmail = null"
    />

    <guias-das
      ref="guiasDasList"
      v-model="gridProps.row"
      @calcular-das="openCalcularDas($event.id)"
      @open-observacoes="openModalObservacao($event)"
      @abrir-arquivo="abrirArquivo($event)"
      @envio-email="abrirEnvioEmail($event)"
      @abrir-declaracao="abrirArquivoSN($event)"
    />

    <loading
      :show-cancel-button="false"
      :is-loading="isLoading"
      :descricao="'Buscando'"
    />
  </div>
</template>

<style lang="scss">
.acoes-usuario {
  margin-right: 0.3rem;
  padding: 0.4rem;
}
</style>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import api from '../../services/api'

export default {
  components: {
    FontAwesomeIcon,
    VisualizarArquivo: () => import('@pilar/components/visualizar-arquivo/VisualizarArquivo.vue'),
    EnvioEmail: () => import('@/app/shared/components/envio-email/EnvioEmail.vue'),
    GuiasDas: () => import('../guias/GuiasDas.vue'),
    RecalcularDas: () => import('../recalcular/RecalcularDas.vue'),
    ModalObservacao: () => import('@/app/shared/components/modal-observacao/ModalObservacao.vue'),
    Loading: () => import('@pilar/components/loading/Loading.vue'),
  },
  props: {
    gridProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      api,
      faExternalLinkAlt,
      mostarArquivo: false,
      arquivo: {},
      docSelecionado: null,
      enviarEmpresa: false,
      emailCc: '',
      empresaEmail: '',
      nome: '',
      value: '',
      guias: [{}],
      isLoading: false,
      observacaoGrid: false,
      docEmail: null,
    }
  },
  methods: {
    recalcularDas(id, data) {
      this.isLoading = true

      api.recalcularDas(id, data, this.gridProps.row.razaoSocial)
        .then(payload => {
          this.isLoading = false

          this.$emit('atualizar-grid')
          if (payload.data !== 'Pago') {
            this.arquivo.url = payload.data
            this.mostarArquivo = true
          } else {
            this.$sucesso('Guia mensal do DAS paga!')
          }
        })
        .catch(err => {
          this.isLoading = false

          if (err.response.data.message.includes('[AcessoNegado-ICGERENCIADOR-032]')) {
            this.$erro('Procuração eletrônica - Empresa apresenta erro com a procuração eletrônica. Verifique e corrija para evitar cobranças indesejadas e erros nas atualizações das rotinas.')
          } else {
            this.$erro('Não foi possível abrir a visualização do Das.', err)
          }
          this.fecharArquivo()
          throw err
        })
    },

    abrirArquivo(documento) {
      if (this.validarStatus(documento)) {
        this.docSelecionado = documento

        api.getURL(documento.id)
          .then(payload => {
            this.arquivo.url = payload.data
            this.mostarArquivo = true
          })
          .catch(err => {
            this.fecharArquivo()
            this.notificarErro('Não foi possível abrir a visualização de arquivo.', err)
            throw err
          })
      } else {
        this.$erro('DAS fora do prazo de pagamento. Será necessário recalcular a guia!')
        this.openCalcularDas(documento.id)
      }
    },

    abrirArquivoSN(documento) {
      this.docSelecionado = documento
      if (documento.pago) {
        this.$sucesso('Guia mensal do DAS está paga!')
      } else {
        api.getURLSimplesNacional(documento.empresaId, documento.competencia)
          .then(payload => {
            this.arquivo.url = payload.data
            this.mostarArquivo = true
          })
          .catch(err => {
            this.fecharArquivo()
            this.notificarErro('Não foi possível abrir a visualização de arquivo.', err)
            throw err
          })
      }
    },

    fecharArquivo() {
      this.mostarArquivo = false
      this.docSelecionado = null
      this.arquivo = {}
    },

    openModalGuias() {
      this.$refs.guiasDasList.show()
    },

    notficarSucesso(msg) {
      this.$sucesso(msg)
    },

    notificarErro(msg) {
      this.$erro(msg)
    },

    abrirEnvioEmail(documento) {
      this.docEmail = documento
      this.$refs.modalEnvioEmail.$refs.modalEnviarEmail.show()
    },

    openCalcularDas(id) {
      this.$refs.recalcularDasModal.show(id)
    },

    enviarDas(itens) {
      if (itens.emailsPara.length === 0 && itens.emailsCc === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Informe ao menos um e-mail',
            icon: 'fa-solid fa-circle-exclamation',
            variant: 'danger',
          },
        })
      } else {
        api.notificaDas(this.gridProps.row.razaoSocial, itens.emailsPara, itens.emailsCc, this.docEmail.id, this.docEmail.simplesId, itens.mensagensAdicional, itens.definirEmailPadroes)
        this.emailCc = ''
      }
    },

    openModalObservacao(documento, grid = false) {
      this.docSelecionado = documento
      this.mostrarObservacoes = true
      this.observacaoGrid = grid

      this.$refs.modalObservacao.value = documento
      this.$refs.modalObservacao.idRegistro = documento.id
      this.$refs.modalObservacao.show()
        .catch(err => {
          this.fecharObservacao()
          this.notificarErro('Não foi possível abrir a visualização de arquivo.', err)
          throw err
        })
    },

    fecharObservacao(observacao) {
      if (this.observacaoGrid) {
        this.gridProps.row.temObservacaoNova = observacao.hasObservacaoNova
      }
      this.mostrarObservacoes = false
      this.docSelecionado = null
      this.gridProps.row.observacao = ''
    },

    receberObservacao(valor) {
      this.gridProps.row.observacao = valor
    },

    validarStatus(documento) {
      if (!(documento.pago || documento.dasEmAtraso == null)
          && (documento.dataVencimento === undefined || moment(documento.dataVencimento, 'DD/MM/YYYY').startOf('day') < moment().startOf('day'))) {
        return false
      }
      return true
    },

    validarCorIcone(documento) {
      if (documento.pago || documento.dasEmAtraso == null) {
        return 'text-primary'
      }
      if (!this.validarStatus(documento)) {
        return 'text-danger'
      }
      return 'text-warning'
    },
  },
}
</script>
