<template>
  <cadastro-base
    :salvar="salvar"
    :cancelar="cancelar"
    :is-loading="isLoading"
  >
    <template slot="conteudo">
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Nome completo"
            label-for="nomeCompletoInput"
          >
            <validation-provider
              #default="{ errors }"
              :rules="'required|nomeCompleto'"
              name="Nome completo"
            >
              <b-form-input
                id="nomeCompletoInput"
                v-model="form.nomeCompleto"
                :state="errors.length > 0 ? false : null "
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="CPF"
            label-for="cpfInput"
          >
            <validation-provider
              #default="{ errors }"
              :rules="'required|cpfDocumento'"
              name="CPF"
            >
              <masked-input
                :id="'cpfInput'"
                ref="cpf"
                v-model="form.cpf"
                :mask="cpf"
                :class="['form-control', {'is-invalid': validacao.cpf}]"
                @blur.native="validarCpfPorApi"
              />
              <small class="text-danger">{{ errors[0] || validacao.cpf }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="CRC"
                label-for="crcInput"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="{required: form.contador}"
                  name="CRC"
                >
                  <b-input
                    :id="'crcInput'"
                    ref="crc"
                    v-model="form.crc"
                    :disabled="!form.contador"
                    :state="errors.length > 0 ? false : null "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-checkbox
                v-model="form.contador"
                size="sm"
                class="detail-check-sm"
                @change="form.crc = ''"
              >
                Considerar como contador
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <dados-acesso
            ref="dadosAcesso"
            v-model="form.dadosAcesso"
            :valida-login="validarLoginPorApi"
            :email-feedback="validacao.login"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <vinculo-empresas
            v-model="form.empresasVinculadas"
            :visualizacao-opcoes="visualizacao"
            :quantidade="30"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mt-3">
          <configurar-permissoes
            v-model="form.permissoes"
            :administrador="form.administrador"
            @administrador="form.administrador = $event"
          />
        </b-col>
      </b-row>
    </template>
  </cadastro-base>
</template>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.view {
  .vgt-inner-wrap {
    box-shadow: none !important;
  }
}

.tabela-servicos {
  .vgt-left-align {
    pointer-events: none;
  }

  .vgt-right-align {
    pointer-events: none;
  }
}

.main-menu.menu-dark .navigation > li ul li > a {
  padding: 8px 10px 8px 12px;
}

.servico-badge {
  height: 1.2rem;
  font-size: 10px;
  font-weight: 600;
  border-radius: 2px;
  margin-left: 0.5rem;
}

.disable-table {
  pointer-events: none;
}
</style>

<script>
import api from '@/app/usuario/shared/services/api'
import {
  defineComponent, shallowRef, computed, triggerRef,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
import { required } from '@validations'
import { cpfDocumento } from '@pilar/plugins/p-validation/p-validation'
import { cpf } from '@pilar/mixins/mascaras'
import EventBus from '@app/shared/mixins/event-bus/eventBus'
import { ValidationProvider } from 'vee-validate'
// eslint-disable-next-line import/no-cycle
import useUsuario from './useUsuario'
import { validaCpf, validaEmail } from '../../services/validacaoApi'
import ConfigurarPermissoes from '../configurar-permissoes/ConfigurarPermissoes.vue'

export default defineComponent({
  name: 'UsuarioInterno',
  components: {
    ValidationProvider,
    ConfigurarPermissoes,
    CadastroBase: () => import('@pilar/components/cadastro-base/CadastroBase.vue'),
    DadosAcesso: () => import('@/app/shared/components/dados-acesso/DadosAcesso.vue'),
    VinculoEmpresas: () => import('@pilar/components/vinculo-empresas/VinculoEmpresas.vue'),
  },
  props: {
    isCadastroModal: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const validacao = shallowRef({ cpf: '', login: '' })
    const cpfValido = computed({
      get: () => validacao.cpf,
    })
    const { route } = useRouter()
    const modoEdicao = route.value.path.indexOf('editar') > 0
    const {
      salvar,
      carregar,
      cancelar,
      form,
      dadosAcesso,
      carregarEmpresasParaVinculo,
    } = useUsuario(modoEdicao, validacao)

    if (modoEdicao) {
      carregar(route.value.params.id)
    } else {
      carregarEmpresasParaVinculo()
    }

    const validarCpfPorApi = () => {
      validaCpf(form.value.cpf, form.value.id)
        .then(() => {
          validacao.value.cpf = ''
          triggerRef(validacao)
        })
        .catch(err => {
          validacao.value.cpf = err.message
          triggerRef(validacao)
        })
    }
    const validarLoginPorApi = () => {
      validaEmail(form.value.dadosAcesso.login, form.value.id)
        .then(() => {
          validacao.value.login = ''
          triggerRef(validacao)
        })
        .catch(err => {
          validacao.value.login = err.message
          triggerRef(validacao)
        })
    }

    return {
      salvar,
      carregar,
      cancelar,
      validarCpfPorApi,
      validarLoginPorApi,
      form,
      validacao,
      dadosAcesso,
      cpfValido,
      cpf,
      cpfDocumento,
      required,
      visualizacao: {
        evento(id, filtros) {
          return api.getEmpresasPorUsuario(id, filtros)
        },
      },
      isLoading: true,
    }
  },
  created() {
    EventBus.$on('finalizaLoading', () => this.atualizaLoading())
  },
  methods: {
    atualizaLoading() {
      this.isLoading = false
    },
  },
})
</script>
