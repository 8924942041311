<template>
  <b-card>
    <h4>Empresa: {{ nomeEmpresa }}</h4>
    <mensagens-list
      id="mensagensList"
      ref="mensagensList"
      :botoes-opcoes="botoesOption"
      :visualizacao-opcoes="visualizacao"
      :filtros="filtros"
      :filtros-rapidos="filtrosRapidos"
      ultima-rota="caixapostal-view"
      filtro-de-para
      @carregado="atualizaNomeEmpresa"
    >
      <template
        slot="custom"
        slot-scope="{ props} "
      >
        <span
          v-if="props.column.field === 'relevante'"
          class="d-block"
          style="text-align: center;"
        >
          <font-awesome-icon
            v-if="props.row[props.column.field]== true"
            icon="fa-solid fa-exclamation"
            style="color:red;font-size: 16px;"
          />
          <span v-else> - </span>
        </span>

        <span
          v-if="props.column.field === 'lido'"
          class="d-block"
          style="text-align: center;font-size: 16px;"
        >
          <feather-icon
            v-if="props.row[props.column.field] == true"
            icon="CheckIcon"
            style="font-size: 16px;color:green"
          />
          <font-awesome-icon
            v-else
            icon="fa-solid fa-xmark"
            style="color:red;font-size: 16px;"
          />
        </span>
      </template>

      <template
        slot="acoes"
        slot-scope="{ props} "
      >
        <b-btn
          v-b-tooltip.hover
          title="Visualizar mensagens"
          variant="none"
          class="text-primary btn-none"
          style="padding: 0.3rem;"
          @click="abrirMensagens(props.row)"
        >

          <font-awesome-icon
            v-if="props.row.mensagem"
            icon="fa-solid fa-envelope-open-text"
          />
          <font-awesome-icon
            v-else
            icon="fa-solid fa-envelope"
          />
        </b-btn>
        <b-btn
          v-b-tooltip.hover
          title="Observação"
          variant="none"
          class="btn-none"
          style="padding: 0.2rem;"
          @click="openModalObservacao(props.row)"
        >
          <font-awesome-icon
            icon="fa-regular fa-message"
            :class="{ 'text-warning': props.row.temObservacoesNaoLidas, 'text-primary': !props.row.temObservacoesNaoLidas }"
          />
        </b-btn>
        <b-btn
          v-b-tooltip.hover
          title="Enviar por e-mail"
          variant="none"
          class="btn-none icone-acao m-0 p-0"
          style="padding: 0.4rem;"
          @click="openModalEnviarMensagem(props.row)"
        >
          <feather-icon
            icon="SendIcon"
            class="text-primary"
            size="17"
          />
        </b-btn>
      </template>
    </mensagens-list>

    <envio-email
      ref="modalEnvioEmail"
      :title="`Enviar mensagem Caixa Postal ${nomeEmpresa}`"
      :sugestao="emailEmpresa"
      :sugestao-email-adicional="emailAdicionalEmpresa"
      @enviarEmail="enviarMensagem"
    />

    <modal-caixa-postal
      :linha="linha"
      @enviarMensagem="openModalEnviarMensagem"
    />
    <loading
      :show-cancel-button="false"
      :is-loading="isLoading"
      :descricao="'Buscando'"
    />
    <modal-observacao
      ref="modalObservacao"
      v-model="msgSelecionada"
      :api="apiMensagens"
      :notificar="{ sucesso: notficarSucesso, erro: this.$erro }"
      @change="receberObservacao(props.row)"
      @hidden="fecharObservacao($event)"
    />
  </b-card>
</template>

<style>
.mensagem-tela {
  background-color: #80808036;
  padding: 1rem;
  border-radius: 4px;
  margin: 0.5rem;
}
</style>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '../../services/api'
import colunas from './colunas'

export default {
  name: 'MensagemView',
  components: {
    MensagensList: () => import('@pilar/components/visualizacao-base/VisualizacaoBase.vue'),
    Loading: () => import('@pilar/components/loading/Loading.vue'),
    ModalObservacao: () => import('@/app/shared/components/modal-observacao/ModalObservacao.vue'),
    EnvioEmail: () => import('@/app/shared/components/envio-email/EnvioEmail.vue'),
    ModalCaixaPostal: () => import('@/app/shared/components/modal-caixa-postal/ModalCaixaPostal.vue'),
  },
  data() {
    return {
      apiMensagens: api,
      nomeEmpresa: '',
      emailEmpresa: [],
      emailAdicionalEmpresa: [],
      idLinha: '',
      botoesOption: null,
      linha: null,
      visualizacao: {
        placeholderBusca: 'Buscar por: Remetente, Assunto, Mensagem ou Data',
        acaoClass: 'caixapostal-td-acao',
        disabledCheckbox: true,
      },
      filtros: [{ colunas: ['Remetente', 'Assunto', 'Mensagem', 'Data', 'ConsultadoEm'], valor: null }],
      filtrosRapidos: [
        {
          id: 'Pronampe',
          colunas: ['TipoMensagem'],
          label: 'Pronampe',
          valor: '2',
        },
        {
          id: 'MalhaFiscal',
          colunas: ['TipoMensagem'],
          label: 'Malha fiscal',
          valor: '1',
        },
        {
          id: 'TermoIntimacao',
          colunas: ['TipoMensagem'],
          label: 'Termo de intimação',
          valor: '3',
        },
        {
          id: 'TermoExclusao',
          colunas: ['TipoMensagem'],
          label: 'Termo de exclusão',
          valor: '4',
        },
        {
          id: 'TermoIndeferimento',
          colunas: ['TipoMensagem'],
          label: 'Termo de indeferimento',
          valor: '5',
        },
        {
          id: 'Lidas',
          colunas: ['Lidas'],
          label: 'Lidas',
          valor: true,
        },
        {
          id: 'NaoLidas',
          colunas: ['Lidas'],
          label: 'Não lidas',
          valor: false,
        },
        {
          id: 'Relevante',
          colunas: ['Relevante'],
          label: 'Relevantes',
          valor: true,
        },
        {
          id: 'RelevanteNaoLido',
          colunas: ['RelevanteNaoLido'],
          label: 'Relevantes Não Lidas',
          valor: true,
        },
        {
          id: 'EProcesso',
          colunas: ['EProcesso'],
          label: 'e-Processos',
          valor: true,
        },
        {
          id: 'EProcessoNaoLida',
          colunas: ['EProcessoNaoLida'],
          label: 'e-Processos não lidas',
          valor: true,
        }],
      empresaId: 0,
      isLoading: false,
      msgSelecionada: {},
    }
  },
  created() {
    this.visualizacao.colunas = colunas
    const col = this.visualizacao.colunas.find(f => f.field === 'assunto')
    col.evento = this.abrirMensagens
    this.visualizacao.evento = this.carregar
    this.empresaId = this.$route.params.id
    this.nomeEmpresa = this.$route.params.nome
  },
  mounted() {
    this.aplicarFiltroQuery()
  },
  updated() {
    this.aplicarFiltroQuery()
  },
  methods: {
    /* eslint-disable no-param-reassign */
    carregar(pagina, tamanhoPagina, filtros) {
      return api.getMensagemPaginado(this.empresaId, pagina, tamanhoPagina, filtros)
    },
    aplicarFiltroQuery() {
      this.$nextTick(() => {
        const params = this.$route.query
        if (params && this.$refs.mensagensList) {
          const filtroRapido = params.filtroRapido
            ? this.filtrosRapidos.find(f => f.id === params.filtroRapido)
            : null
          this.$refs.mensagensList.aplicarFiltro(params.busca, filtroRapido)
        }
      })
    },
    abrirMensagens(row) {
      this.emailEmpresa = row.emailEmpresa
      this.idLinha = row.id
      this.linha = row

      if (row.mensagem) {
        this.$bvModal.show('m-Mensagens')
      } else {
        this.$confirmar('Mensagem não lida no portal do e-CAC',
          'Você gostaria de ler a mensagem agora? Ao confirmar a leitura será consumido um crédito do seu saldo em conta.')
          .then(confirmado => {
            if (confirmado && confirmado.isConfirmed) {
              this.isLoading = true
              api.getMensagemNaoLida(row.id)
                .then(payload => {
                  this.$set(row, 'lido', true)
                  this.$set(row, 'mensagem', payload.data)
                  this.isLoading = false
                  this.$bvModal.show('m-Mensagens')
                }).catch(err => {
                  this.isLoading = false
                  if (err.response.data.message.includes('[AcessoNegado-ICGERENCIADOR-032]')) {
                    this.$erro('Procuração eletrônica - Empresa apresenta erro com a procuração eletrônica. Verifique e corrija para evitar cobranças indesejadas e erros nas atualizações das rotinas.')
                  } else
                  if (err.response.data.message.includes('não tem procuração autorizada no Portal eCAC')) {
                    this.$aviso('Instabilidade com o site de origem, tente novamente mais tarde!')
                  } else
                  if (err.response.data.message.includes('Não possui máquina disponível tempo máximo excedido')) {
                    this.$aviso('Não foi possível processar sua solicitação, tente novamente em alguns instantes!')
                  } else
                  if (err.response.data.message.includes('Requisição inválida.')) {
                    this.$aviso('Serviço indisponível no momento, por favor, tente novamente em breve!')
                  } else
                  if (err.response.data.message.includes('Não possui um certificado digital vinculado.')) {
                    this.$erro('Não possui um certificado digital vinculado.')
                  } else {
                    this.$erro(`Estamos enfrentando problemas técnicos, por favor, tente novamente mais tarde!. Erro: ${err.response.data.message}`)
                  }
                })
            }
          })
      }
    },
    atualizaNomeEmpresa(evt) {
      if (!this.nomeEmpresa) {
        this.nomeEmpresa = evt[0].empresa
      }
    },
    openModalEnviarMensagem(row) {
      if (row) {
        this.emailEmpresa = row.emailEmpresa
        this.emailAdicionalEmpresa = row.emailAdicionalEmpresa
        this.idLinha = row.id
      }
      this.$refs.modalEnvioEmail.abrirModal()
    },
    enviarMensagem(itens) {
      if (itens.emailsPara.length === 0 && itens.emailsCc === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Informe ao menos um e-mail',
            icon: 'fa-solid fa-circle-exclamation',
            variant: 'danger',
          },
        })
      } else {
        api.notificaMensagem(this.nomeEmpresa, itens.emailsPara, itens.emailsCc, this.idLinha, itens.mensagensAdicional, itens.definirEmailPadroes)
        this.emailCc = ''
      }
    },
    openModalObservacao(documento) {
      this.msgSelecionada = documento
      this.$nextTick(() => {
        this.$refs.modalObservacao.show()
      })
    },
    fecharObservacao(observacaoNova) {
      const index = this.$refs.mensagensList.gridInfo.dados.findIndex(where => where.id === this.msgSelecionada.id)
      this.$refs.mensagensList.gridInfo.dados[index].temObservacoesNaoLidas = observacaoNova.hasObservacaoNova
      this.$set(this.msgSelecionada, null)
    },
    notficarSucesso(msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: msg,
          icon: 'fa-solid fa-circle-check',
          variant: 'success',
        },
      })
    },
  },
}

</script>
